export default {
    data() {
        return {
            isSearchExpanded: false,
            queryForm: {
                ClientRem: "",
                Rem1: "",
                Rem2: "",
                Rem3: "",
                Rem4: "",
                ReceiveName: "",
                ReceivePhone: ""
            },
            advancedSearchHeight: 0,
        }
    },
    watch: {
        isSearchExpanded: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        const searchEl = this.$el.querySelector('.expanded-search')
                        if (searchEl) {
                            const height = searchEl.offsetHeight +
                                parseInt(window.getComputedStyle(searchEl).marginTop) +
                                parseInt(window.getComputedStyle(searchEl).marginBottom)
                            this.advancedSearchHeight = height
                        }
                    })
                } else {
                    this.advancedSearchHeight = 0
                }
            },
            immediate: true
        }
    },
    methods: {
        updateSearchHeight() {
            if (this.isSearchExpanded) {
                const searchEl = this.$el.querySelector('.expanded-search')
                if (searchEl) {
                    this.advancedSearchHeight = searchEl.offsetHeight
                }
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateSearchHeight)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateSearchHeight)
    }
}
