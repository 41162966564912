<template>
  <el-dialog
      title="虾皮面单上传"
      :visible.sync="show"
      center
      top="10vh"
      @close="close"
      :show-close="false"
      :close-on-click-modal="false"
      width="600px">

    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="订单号" prop="orderNo">
        <el-input v-model="form.orderNo" placeholder="请输入订单号"></el-input>
      </el-form-item>

      <el-form-item label="承运单号" prop="trackingNo">
        <el-input v-model="form.trackingNo" placeholder="请输入承运单号"></el-input>
      </el-form-item>

      <el-form-item label="承运商" prop="carrier">
        <el-input v-model="form.carrier" placeholder="请输入承运商"></el-input>
      </el-form-item>

      <el-form-item label="面单文件" prop="file">
        <el-upload
          class="upload-demo"
          action="#"
          :auto-upload="false"
          :on-change="handleFileChange"
          :file-list="fileList"
          accept="application/pdf">
          <el-button size="small" type="primary">选择PDF文件</el-button>
          <div slot="tip" class="el-upload__tip">只能上传 PDF 文件</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="提示消息" prop="message">
        <el-input
          type="textarea"
          v-model="form.message"
          placeholder="请输入提示消息">
        </el-input>
      </el-form-item>
    </el-form>

    <!-- PDF预览 -->
    <div v-if="pdfUrl" class="pdf-preview">
      <iframe :src="pdfUrl" width="100%" height="300"></iframe>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submitForm">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        orderNo: '',
        trackingNo: '',
        carrier: '',
        message: '',
        serverFilePath: ''
      },
      rules: {
        orderNo: [{ required: true, message: '请输入订单号', trigger: 'blur' }],
        trackingNo: [{ required: true, message: '请输入承运单号', trigger: 'blur' }],
        carrier: [{ required: true, message: '请输入承运商', trigger: 'blur' }],
        file: [{ required: true, message: '请上传PDF文件', trigger: 'change' }]
      },
      fileList: [],
      pdfUrl: '',
      uploadFile: null
    }
  },
  created() {
    // 初始化表单数据
    this.initFormData()
  },
  methods: {
    close() {
      this.$emit("close")
      this.resetForm()
    },
    initFormData() {
      // 使用row中的数据初始化表单
      this.form.orderNo = this.row.orderNo || ''
      this.form.trackingNo = this.row.trackingNo || ''
      this.form.carrier = this.row.carrier || ''
    },
    handleFileChange(file) {
      this.uploadFile = file.raw
      if (file.raw) {
        // 创建本地预览URL
        this.pdfUrl = URL.createObjectURL(file.raw)
      }
    },
    async submitForm() {
      try {
        await this.$refs.form.validate()

        if (!this.uploadFile) {
          this.$message.error('请选择PDF文件')
          return
        }

        // 创建FormData对象上传文件
        const formData = new FormData()
        formData.append('file', this.uploadFile)

        // 这里替换成您的上传API
        const response = await this.$http.post('/api/upload', formData)

        // 假设返回的数据中包含 serverFilePath
        this.form.serverFilePath = response.data.serverFilePath

        // 上传成功后调用提交接口
        await this.submitWaybill()

        this.$message.success('提交成功')
        this.close()
      } catch (error) {
        this.$message.error('提交失败：' + error.message)
      }
    },
    async submitWaybill() {
      // 这里替换成您的提交接口
      await this.$http.post('/api/waybill', this.form)
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.fileList = []
      this.pdfUrl = ''
      this.uploadFile = null
    }
  }
}
</script>

<style scoped lang="scss">
.pdf-preview {
  margin: 20px 0;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.el-upload__tip {
  line-height: 1.2;
  padding-top: 5px;
  color: #909399;
}
</style>
