export default {
    data() {
        return {
            PageSizes: [10, 20, 50, 100, 500],
            PageIndex: 1,
            PageRows: 10,
            total: 0
        }
    },
    methods: {
        handleSizeChange(val) {
            this.PageRows = val
            this.PageIndex = 1;
            this.getData()
        },
        handleChange(current) {
            this.PageIndex = current;
            this.getData()
        },
    }
}
