<template>
  <div class="layout">
    <div class="navigation">
      <div class="navigation-logo">
        <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
        <div class="navigation-title">{{ $t('service.title') }}</div>
      </div>
      <el-menu
          :default-active="activeMenu"
          active-text-color="#194D8F"
          router
          class="el-menu-vertical-demo">
        <el-menu-item :index="item.index" v-for="item in menuItems" :key="item.id">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="operate">
      <transition name="pop">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  // 在你的 Vue 组件中
  data() {
    return {
    };
  },
  computed: {
    menuItems() {
      return  [
        {
          id: 1,
          title: this.$t('layout.service.aboutUs'),
          index: '/service/about-Us'
        },
        {
          id: 2,
          title: this.$t('layout.service.privacyStatement'),
          index: '/service/privacy'
        },
        {
          id: 3,
          title: this.$t('layout.service.termsAndDisclaimer'),
          index: '/service/term'
        },
        {
          id: 4,
          title: this.$t('layout.service.carriageAgreement'),
          index: '/service/carriage-treaty'
        },
        {
          id: 5,
          title: this.$t('layout.service.contactUs'),
          index: '/service/contact-Us'
        },
      ]
    },
    activeMenu() {
      const route = this.$route
      const { path } = route
      return path
    },
  },
  components: {
  },
  created() {
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.layout {
  width: 80%;
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 39rem);
  box-sizing: border-box;
  gap: 2rem;

  .navigation {
    width: 25%;
    height: auto;
    
    .navigation-logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #fff;
      padding: 2rem;
      border-radius: 0.8rem;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
      margin-bottom: 1rem;

      .navigation-title {
        font-size: 2rem;
        font-weight: 600;
        color: #194D8F;
        margin-left: 1rem;
      }
    }

    :deep(.el-menu) {
      border-right: none;
      border-radius: 0.8rem;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);

      .el-menu-item {
        height: 5rem;
        line-height: 5rem;
        margin: 0.5rem 0;
        border-radius: 0.4rem;
        
        &:hover {
          background-color: #f0f5ff;
        }
        
        &.is-active {
          background-color: #e6f0ff;
          color: #194D8F;
          font-weight: 500;
        }
      }
    }
  }

  .operate {
    background-color: #fff;
    width: 73%;
    border-radius: 0.8rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
}

.pop-enter-active, .pop-leave-active {
  transition: all 0.3s ease;
}

.pop-enter, .pop-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
