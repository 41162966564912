import request from "@/utils/requests"

export function loginAPI(data) {
    return request({
        url: '/ClientLogin/SubmitLogin',
        method: 'post',
        data
    })
}

export function register(data) {
    return request({
        url: '/Client_Info/AddData',
        method: 'post',
        data
    })
}

export function getInfo() {
    return request({
        url: '/Client_Info/GetTheData',
        method: 'post'
    })
}


export function logout() {
    return request({
        url: '/ClientLogin/SubmitLogout',
        method: 'post'
    })
}


export function getPriceInfo() {
    return request({
        url: '/Parcel_Info/GetParcelCount',
        method: 'get'
    })
}

export function UpdateUser(data) {
    return request({
        url: '/Client_Info/UpdateData',
        method: 'put',
        data
    })
}

export function updateAvatar(data) {
    return request({
        url: '/Client_Info/UpdateClientPicture',
        method: 'POST',
        data
    })
}


export function UpdatePwd(data) {
    return request({
        url: '/Client_Info/ChangePwd',
        method: 'put',
        data
    })
}

export function resetPwd(data) {
    return request({
        url: '/ClientLogin/ClientForgotPassword',
        method: 'POST',
        data
    })
}

export function LogoffUser(params) {
    return request({
        url: '/Client_Info/SubmitCancellation',
        method: 'post',
        params
    })
}


export function addReviewsLog(data) {
    return request({
        url: '/Order_ReviewsLog/AddData',
        method: 'post',
        data
    })
}


export function withdrawal(data) {
    return request({
        url: '/Client_Info/AgentEarningsWithdraw',
        method: 'post',
        data
    })
}

export function flashToken(data) {
    return request({
        url: '/ClientLogin/RefreshToken',
        method: 'post',
        data
    })
}


export function exchangeCoupon(data) {
    return request({
        url: '/Base_ShopEvents/PointsRedemptionCoupon',
        method: 'post',
        data
    })
}

export function sign() {
    return request({
        url: '/Client_Info/MemberSignIn',
        method: 'post'
    })
}

export function withdrawalMoney(data) {
    return request({
        url: '/Client_Wallet/ApplicationWithdrawalMoney',
        method: 'post',
        data
    })
}


export function offlineRecharge(data) {
    return request({
        url: '/Client_Wallet/OfflineRechargeUploadCertificate',
        method: 'post',
        data
    })
}


export function getClientSubAccount(data) {
    return request({
        url: '/Client_Info/GetClientSubAccount',
        method: 'post',
        data
    })
}


export function addClientSubAccount(data) {
    return request({
        url: '/Client_Info/AddClientSubAccount',
        method: 'post',
        data
    })
}

export function delClientSubAccount(data) {
    return request({
        url: '/Client_Info/DeleteClientSubAccount',
        method: 'post',
        data
    })
}