import request from "@/utils/requests"

export function getCODProduct(data) {
    return request({
        url: '/COD_Product/GetDataPage',
        method: 'post',
        data
    })
}


export function delCODProduct(data) {
    return request({
        url: '/COD_Product/DeleteData',
        method: 'post',
        data
    })
}


export function addCODProduct(data) {
    return request({
        url: '/COD_Product/AddData',
        method: 'post',
        data
    })
}

export function updateCODProduct(data) {
    return request({
        url: '/COD_Product/UpdateData',
        method: 'put',
        data
    })
}


export function getCodSKU(data) {
    return request({
        url: '/COD_SKU/GetDataPage',
        method: 'post',
        data
    })
}

export function addCodSKU(data) {
    return request({
        url: '/COD_SKU/AddData',
        method: 'post',
        data
    })
}

export function updateCodSKU(data) {
    return request({
        url: '/COD_SKU/UpdateData',
        method: 'put',
        data
    })
}


export function delCodSKU(data) {
    return request({
        url: '/COD_SKU/DeleteData',
        method: 'post',
        data
    })
}

export function getCodSKUStockLog(data) {
    return request({
        url: '/COD_SKU/GetStockLogDataPage',
        method: 'post',
        data
    })
}


export function getCodSKUDetail(data) {
    return request({
        url: '/COD_SKU/GetStockDataPage',
        method: 'post',
        data
    })
}

export function addCodApply(data) {
    return request({
        url: '/COD_Apply/AddData',
        method: 'post',
        data
    })
}


export function getCodSKUApply(data) {
    return request({
        url: '/COD_Apply/GetDataPage',
        method: 'post',
        data
    })
}

export function getCodSKUApplyDetail(data) {
    return request({
        url: '/COD_Apply/GetDataDetailsPage',
        method: 'post',
        data
    })
}


export function delCodSKUApply(data) {
    return request({
        url: '/COD_Apply/DeleteData',
        method: 'post',
        data
    })
}


export function updateCodSKUApply(data) {
    return request({
        url: '/COD_Apply/UpData',
        method: 'put',
        data
    })
}


export function unBindStock(data) {
    return request({
        url: '/COD_SKU/OrderUnbundlingStock',
        method: 'post',
        data
    })
}

export function ShopeeOrderToSystem(data) {
    return request({
        url: '/Base_Electronic/GetShopeeOrderToSystem',
        method: 'post',
        data
    })
}


export function orderToDelivery(data) {
    return request({
        url: '/COD_SKU/OrderToDelivery',
        method: 'post',
        data
    })
}

export function cancelOrderToDelivery(data) {
    return request({
        url: '/COD_SKU/OrderCancelDelivery',
        method: 'post',
        data
    })
}


export function getOrderItemDetail(data) {
    return request({
        url: '/COD_SKU/GetOrderItemDetail',
        method: 'post',
        data
    })
}

export function importSku(data) {
    return request({
        url: '/COD_SKU/CODSKUImport',
        method: 'post',
        data
    })
}


export function placeOrder(data) {
    return request({
        url: '/COD_SKU/DedicatedAddOrder',
        method: 'POST',
        data
    })
}


export function getApplyProblem(data) {
    return request({
        url: '/COD_Apply/GetProblemDataPage',
        method: 'POST',
        data
    })
}


export function editShopee(data) {
    return request({
        url: '/Base_Electronic/EditShopee',
        method: 'post',
        data
    })
}

export function deleteShopee(data) {
    return request({
        url: '/Base_Electronic/DeleteShopee',
        method: 'post',
        data
    })
}


export function batchUpdateSKUImg(data) {
    return request({
        url: '/COD_SKU/SKUImageUploadingData',
        method: 'post',
        data
    })
}


export function ShoppSheetUp(data) {
    return request({
        url: '/Base_Electronic/ShoppSheetUp',
        method: 'post',
        data
    })
}


export function getSkuSheet(data) {
    return request({
        url: '/COD_SKU/GetSKUPrintFile',
        method: 'post',
        data
    })
}
