<template>
  <el-dialog
    modal-append-to-body
    :close-on-click-modal="false"
    :before-close="close"
    title="SKU批量导入"
    width="80%"
    :visible.sync="show"
    class="sku-batch-upload-dialog"
  >
    <div v-if="isWarehouse" class="warehouse-select-container">
      <el-alert
        title="请先选择仓库"
        description="请选择SKU存放的仓库"
        type="info"
        effect="light"
        :closable="false"
        class="warehouse-alert"
        show-icon>
      </el-alert>
      <el-select
        v-model="WareHouseId"
        placeholder="请选择仓库"
        size="medium"
        class="warehouse-select-input"
        popper-class="warehouse-select-dropdown"
        popper-append-to-body
      >
        <el-option
          v-for="item in warehouseOptions"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
          class="warehouse-option"
        ></el-option>
      </el-select>
    </div>
    <el-table
        :element-loading-text="$t('upload.loadingText')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        size="mini"
        border
        :data="sortedTableData"
        class="sku-table"
        style="width: 100%"
        height="calc(80vh - 200px)"
    >
      <el-table-column prop="ErrorMsg" show-overflow-tooltip width="150" label="异常消息">
        <template slot-scope="scope">
          <div v-if="scope.row.ErrorMsg" class="error-message">
            <i class="el-icon-warning"></i>
            <span>{{ scope.row.ErrorMsg }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="SkuImage" label="图片" width="64" v-if="requireImage">
        <template slot-scope="scope">
          <div class="image-container">
            <el-image v-if="scope.row.SkuImage" :src="scope.row.SkuImage" class="sku-img" :preview-src-list="[scope.row.SkuImage]"></el-image>
            <div v-if="scope.row.uploadStatus" :class="['status-overlay', scope.row.uploadStatus]">
              <i :class="['el-icon-' + (scope.row.uploadStatus === 'success' ? 'check' : 'close')]"></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="SKUCode" label="SKU单号" width="135"></el-table-column>
      <el-table-column prop="Name" show-overflow-tooltip label="SKU名称"></el-table-column>
      <el-table-column prop="Size" label="尺码"></el-table-column>
      <el-table-column prop="Weight" label="重量"></el-table-column>
      <el-table-column prop="Specification" label="规格"></el-table-column>
      <el-table-column prop="ModelNumber" label="型号"></el-table-column>
      <el-table-column prop="OrderCode" label="入仓单号" width="135"></el-table-column>
      <el-table-column prop="Quantity" label="申请数量"></el-table-column>
      <el-table-column prop="UnitPrice" label="单价"></el-table-column>
      <el-table-column prop="WarningsNumber" label="预警数量"></el-table-column>
      <el-table-column prop="Length" label="长"></el-table-column>
      <el-table-column prop="Width" label="宽"></el-table-column>
      <el-table-column prop="Height" label="高"></el-table-column>
      <el-table-column prop="Rem" label="备注"></el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="PageIndex"
        :page-sizes="PageSizes"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <div v-if="!isError" slot="footer" class="dialog-footer" style="text-align: left">
      <el-button
        v-loading="loading"
        type="primary"
        @click="submitForm"
        :disabled="isWarehouse && !WareHouseId"
      >
        提交
      </el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {importSku} from "@/api/cod";
import { mapGetters } from "vuex"
import {uploadImage} from "@/api/system";
import pagination from "@/utils/mixin/pagination";
export default {
  mixins: [ pagination ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    requireImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      WareHouseId: undefined,
      warehouseOptions: [],
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.WareHouseId = undefined
        this.getWareHouse()
        this.total = this.tableData.length
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    isError() {
      return this.tableData.some(item => {
        return item.ErrorMsg
      })
    },
    isWarehouse() {
      return this.tableData.some(item => {
        return item.OrderCode
      })
    },
    sortedTableData() {
      const currentPageStart = (this.PageIndex - 1) * this.PageRows;
      const currentPageEnd = currentPageStart + this.PageRows;

      const sortedData = [...this.tableData].sort((a, b) => {
        if (a.ErrorMsg && !b.ErrorMsg) return -1;
        if (!a.ErrorMsg && b.ErrorMsg) return 1;
        return 0;
      });

      return sortedData.slice(currentPageStart, currentPageEnd);
    }
  },
  components: {
  },
  methods: {
    handleSizeChange(val) {
      this.PageRows = val
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOptions = data
      })
    },
    handleCurrentChange(val) {
      this.PageIndex = val
    },
    async handleImageUpdate() {
      const startIndex = (this.PageIndex - 1) * this.PageRows;
      const endIndex = Math.min(startIndex + this.PageRows, this.tableData.length);
      const currentPageData = this.tableData.slice(startIndex, endIndex);

      const uploadPromises = currentPageData.map(async (item, i) => {
        const index = startIndex + i;
        if (item.SkuImage && !item.uploadStatus) {
          const formData = new FormData();
          const blob = new Blob([item.ImageBuffer], {type: 'image/png'});
          formData.append('file', blob);
          formData.append('FileName', `${this.userInfo.Id}${item.SKUCode}.${item.extension}`);
          formData.append('Path', 'SKUImg');

          try {
            const response = await uploadImage(formData);
            const {Code, Msg, Data} = response
            if (Code === 200) {
              this.$set(this.tableData[index], 'Image', Data.ServerPath);
              this.$set(this.tableData[index], 'uploadStatus', 'success');
            } else {
              this.$set(this.tableData[index], 'uploadStatus', 'error');
              throw new Error(Msg);
            }
          } catch (error) {
            this.$set(this.tableData[index], 'uploadStatus', 'error');
            throw error;
          }
        }
        return Promise.resolve()
      })

      // eslint-disable-next-line no-useless-catch
      try {
        await Promise.all(uploadPromises);

        // 检查是否所有当前页的图片都上传成功
        const allCurrentPageSuccess = currentPageData.every(item =>
            !item.SkuImage || item.uploadStatus === 'success'
        );

        // 如果当前页全部上传成功且还有下一页，则自动跳转
        if (allCurrentPageSuccess && this.PageIndex * this.PageRows < this.total) {
          this.handleCurrentChange(this.PageIndex + 1);
          return this.handleImageUpdate();
        }
      } catch (error) {
        throw error;
      }
    },
    close() {
      this.$emit('close')
    },
    handleSubmit() {
      if (!this.WareHouseId) {
        this.$message.warning('请选择仓库')
        return
      }
      this.submitForm()
    },
    async submitForm() {
      try {
        if (this.isWarehouse && !this.WareHouseId) {
          this.$message.warning('请选择仓库')
          return
        }
        if (this.loading) {
          return;
        }
        this.loading = true

        // 只有当requireImage为true时才处理图片上传
        if (this.requireImage) {
          this.PageIndex = 1; // 重置到第一页开始上传
          await this.handleImageUpdate()
        }

        const data = {
          CODSKUImportInfos: this.tableData,
          WareHouseId: this.WareHouseId
        }
        const response = await importSku(data)
        const { Code, Msg } = response
        if (Code === 200) {
          this.$message.success("导入成功！！")
          this.$emit('refresh')
          this.$emit('close')
        } else {
          this.$message.warning(Msg)
        }
      } catch (error) {
        this.$message.error("上传过程中发生错误：" + error)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateTableHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateTableHeight)
  }
}

</script>

<style lang="scss" scoped>
.sku-batch-upload-dialog {
  :deep(.el-dialog) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3vh !important;
    left: 50%;
    transform: translateX(-50%);
    max-height: 94vh;
    max-width: 90%;
    margin: 0 !important;
  }

  :deep(.el-dialog__body) {
    padding: 10px 15px;
    overflow: auto;
    flex: 1;
    // 添加滚动条样式
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #c0c4cc;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: #f5f7fa;
    }
  }

  :deep(.el-dialog__header) {
    padding: 12px 20px;
  }

  :deep(.el-dialog__footer) {
    padding: 10px 20px;
    border-top: 1px solid #ebeef5;
  }
}

.sku-table {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0;

  :deep(.el-table__header-wrapper) {
    th {
      background-color: #f5f7fa;
      color: #606266;
      font-weight: 500;
      height: 45px;
      padding: 8px 0;
    }
  }

  :deep(.el-table__body-wrapper) {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #c0c4cc;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: #f5f7fa;
    }

    td {
      padding: 8px 0;
    }

    .el-table__row {
      &:hover {
        td {
          background-color: #f5f7fa;
        }
      }
    }
  }
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 2px 0;

  .sku-img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ebeef5;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .status-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;

    i {
      font-size: 20px;
      color: #fff;
      transform: scale(0.9);
      transition: transform 0.2s ease;
    }

    &.success {
      i {
        color: #67C23A;
        transform: scale(1);
      }
    }

    &.error {
      i {
        color: #F56C6C;
        transform: scale(1);
      }
    }
  }
}

.warehouse-select-container {
  margin: 0 0 15px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 30px;
  background: #f8f9fb;
  border-radius: 8px;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

  .warehouse-select-input {
    min-width: 280px;
    flex-shrink: 0;

    :deep(.el-input__inner) {
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      font-size: 14px;

      &:hover, &:focus {
        border-color: #409EFF;
      }
    }
  }

  .warehouse-alert {
    width: auto;
    flex-grow: 0;
    background-color: #ecf5ff !important;
    border: 1px solid #d9ecff;

    :deep(.el-alert__title) {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.4;
      color: #2d5a9d;
    }

    :deep(.el-alert__description) {
      margin: 2px 0 0;
      font-size: 13px;
      color: #446899;
    }

    :deep(.el-alert__icon) {
      font-size: 18px;
      color: #2d5a9d;
      margin-top: 2px;
    }

    :deep(.el-alert__content) {
      padding: 0 6px;
    }
  }
}

.warehouse-select-dropdown {
  z-index: 3000 !important;

  .el-select-dropdown__item {
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    font-size: 14px;

    &.selected {
      color: #409EFF;
      font-weight: 500;
      background-color: #f0f7ff;
    }

    &:hover {
      background-color: #f0f7ff;
    }
  }

  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }

  .popper__arrow {
    display: none;
  }
}

.el-button {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

:deep(.el-pagination) {
  padding: 8px 0;
  margin-top: 8px;
  text-align: right;

  .btn-prev, .btn-next {
    border-radius: 4px;
    padding: 0 8px;

    &:hover {
      color: #409EFF;
    }
  }

  .el-pager li {
    border-radius: 4px;

    &:hover {
      color: #409EFF;
    }

    &.active {
      background-color: #409EFF;
      color: #fff;
    }
  }

  .el-pagination__sizes {
    .el-input__inner {
      border-radius: 4px;
    }
  }
}

.dialog-footer {
  padding-top: 8px;
  border-top: 1px solid #ebeef5;

  .el-button {
    padding: 8px 16px;
    font-size: 13px;
    border-radius: 4px;

    &+.el-button {
      margin-left: 12px;
    }

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    }
  }
}

.error-message {
  display: flex;
  align-items: center;
  color: #F56C6C;
  font-weight: 500;

  .el-icon-warning {
    margin-right: 5px;
    font-size: 16px;
  }

  span {
    line-height: 1.2;
  }
}

:deep(.el-table__row.has-error) {
  background-color: #fff0f0;

  &:hover > td {
    background-color: #ffe7e7 !important;
  }
}

.mt20 {
  margin-top: 10px !important;
}
</style>
