<template>
  <el-dialog top="5vh" modal-append-to-body :close-on-click-modal="false" :before-close="close" title="入仓申请表" :width="dialogWidth" :visible.sync="show" custom-class="warehouse-dialog">
    <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-position="left" label-width="100px">
      <div class="multiple-box" v-if="show">
        <div class="sku-item" v-for="(item, i) in CODApplyListData" :key="item.Id">
          <i @click="removeItem(item, i)" v-if="dialogStatus==='update'" class="absolute-icon el-icon-close"></i>
          <div class="item">
            <div class="label">SKU编码</div>
            <div class="value">{{ item.SKUCode }}</div>
          </div>
          <div class="item">
            <div class="label">名称</div>
            <div class="value">{{ item.Name }}</div>
          </div>
<!--          <div class="item">-->
<!--            <div class="label">商品品类</div>-->
<!--            <div class="value">{{ item.ProductName }}</div>-->
<!--          </div>-->
          <el-form-item label="预计数量" :prop="item.SKUCode">
            <el-input v-model="ruleForm.CODApplyInfos[i].Quantity" type="number"></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center" v-if="!expands[item.SKUCode]">
            <el-button @click="handleChangeExpand(item.SKUCode)" size="small" type="text">
              展开详细信息
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </div>
          <div v-if="expands[item.SKUCode]">
            <div class="item">
              <div class="label">尺寸</div>
              <div class="value">{{ `${item.Length}*${item.Width}*${item.Height}` }}</div>
            </div>
            <div class="item">
              <div class="label">尺码</div>
              <div class="value">{{ item.Size }}</div>
            </div>
            <div class="item">
              <div class="label">规格</div>
              <div class="value">{{ item.Specification }}</div>
            </div>
            <div class="item">
              <div class="label">型号</div>
              <div class="value">{{ item.ModelNumber }}</div>
            </div>
            <div class="item">
              <div class="label">重量</div>
              <div class="value">{{ item.Weight }}</div>
            </div>
            <div class="item" v-if="item.ImagePath">
              <div class="label">图片</div>
              <el-image class="sku-img" :preview-src-list="[item.ImagePath]" :src="item.ImagePath"></el-image>
            </div>
            <div class="item">
              <div class="label rem">备注</div>
              <div class="rem">{{ item.Rem }}</div>
            </div>
          </div>
          <div style="width: 100%; text-align: center" v-if="expands[item.SKUCode]">
            <el-button @click="handleChangeExpand(item.SKUCode)" size="small" type="text">
              收起详细信息
              <i class="el-icon-arrow-up el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <div v-if="dialogStatus==='update'" @click="showDrawer" class="sku-item add-item">
          <i class="el-icon-plus add-item-icon"></i>
        </div>
      </div>
      <el-form-item label="入仓申请号" prop="OrderCode">
        <el-input v-model="ruleForm.OrderCode" type="text" style="width: 36rem" placeholder="请填写入仓申请号"></el-input>
      </el-form-item>
      <el-form-item label="所属仓库" prop="WareHouseId">
        <el-select v-model="ruleForm.WareHouseId" placeholder="请选择仓库" style="width: 36rem">
          <el-option v-for="item in warehouseOptions" :key="item.Id" :label="item.Name" :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注说明" prop="Rem">
        <el-input v-model="ruleForm.Rem" type="textarea" style="width: 36rem" placeholder="如有其他需求或有特别需要注意的,请留下您的需求备注信息"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: left">
      <el-button v-loading="loading" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="close">取消</el-button>
    </div>
    <s-k-u-drawer @close="handleDrawerClose" @handleCheck="handleChangeSKU" :s-k-u-ids="SKUIds" :drawer="drawer"></s-k-u-drawer>
  </el-dialog>
</template>

<script>

import {addCodApply, updateCodSKUApply} from "@/api/cod";
import { mapGetters } from "vuex"
import SKUDrawer from "@/views/member/cod/SKUDrawer.vue";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    CODApplyInfos: {
      type: Array,
      default: () => []
    },
    dialogStatus: {
      type: String,
      default: "create"
    },
    temp: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        OrderCode: [
          { required: true, message: '请输入入仓申请号', trigger: 'blur' }
        ],
        WareHouseId: [
          { required: true, message: '请选择仓库', trigger: 'change' },
        ],
      },
      warehouseOptions: [],
      loading: false,
      expands: {},
      drawer: false,
      checkedSKUList: [],
      deepCODApplyInfos: [],
      dialogWidth: '1200px'
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters(["userInfo"]),
    SKUIds() {
      return this.CODApplyListData.map(item => {
        return item.Id
      })
    },
    CODApplyListData() {
      return [...this.deepCODApplyInfos, ...this.checkedSKUList]
    }
  },
  components: {
    SKUDrawer
  },
  mounted() {
    this.setDialogWidth()
    window.addEventListener('resize', this.setDialogWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDialogWidth)
  },
  methods: {
    showDrawer() {
      this.drawer = true
    },
    handleChangeSKU(checked) {
      let applyArray = []
      checked.forEach(item => {
        applyArray.push({
          SKUId: item.Id,
          Quantity: 0
        })
        this.rules[item.SKUCode] = [
          {validator: this.checkRange, trigger: ['blur']}
        ];
        this.$set(this.expands, item.SKUCode, false)
      })
      this.checkedSKUList = checked
      this.$set(this.ruleForm, 'CODApplyInfos', [...this.ruleForm.CODApplyInfos, ...applyArray]);
      this.drawer = false
    },
    handleDrawerClose() {
      this.drawer = false
    },
    checkRange(rule, value, callback) {
      // Find the index in the full CODApplyListData array instead
      const idx = this.CODApplyListData.findIndex(item => item.SKUCode === rule.field);
      if (idx === -1) {
        callback(new Error('SKU不存在'));
        return;
      }

      const realValue = this.ruleForm.CODApplyInfos[idx].Quantity
      if (!realValue) {
        callback(new Error('请输入入库数量'))
      }
      callback();
    },
    close() {
      this.$refs.ruleForm.clearValidate()
      this.$emit('close')
    },
    removeItem(row, i) {
      this.$confirm(`此操作将永久移除SKU编码为（${row.SKUCode}）的数据, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.dialogStatus === 'create') {
          this.deepCODApplyInfos.splice(i, 1)
        } else {
          if (this.validateFind(row.Id)) {
            const index = this.deepCODApplyInfos.findIndex(item => {
              return item.Id === row.Id
            })
            this.deepCODApplyInfos.splice(index, 1)
          } else {
            const index = this.checkedSKUList.findIndex(item => {
              return item.Id === row.Id
            })
            this.checkedSKUList.splice(index, 1)
          }
        }
        this.ruleForm.CODApplyInfos.splice(i, 1)
      })
    },
    validateFind(rowId) {
      if (!this.deepCODApplyInfos.length) {
        return false
      }
      return this.deepCODApplyInfos.map(item => item.Id).includes(rowId);
    },
    init() {
      this.getWareHouse()
      if (this.dialogStatus === 'create') {
        let applyArray = []
        this.CODApplyInfos.forEach(item => {
          applyArray.push({
            SKUId: item.Id,
            Quantity: 0
          })
          this.rules[item.SKUCode] = [
            {validator: this.checkRange, trigger: ['blur']}
          ];
          this.$set(this.expands, item.SKUCode, false)
        })
        this.$set(this.ruleForm, 'CODApplyInfos', applyArray);
      } else {
        this.checkedSKUList = []
        this.ruleForm = JSON.parse(JSON.stringify(this.temp))
        this.CODApplyInfos.forEach(item => {
          this.rules[item.SKUCode] = [
            {validator: this.checkRange, trigger: ['blur']}
          ];
          this.$set(this.expands, item.SKUCode, false)
        })
      }
      this.deepCODApplyInfos = JSON.parse(JSON.stringify(this.CODApplyInfos))
      this.$forceUpdate()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === 'create') {
            addCodApply({
              ...this.ruleForm,
              ClientId: this.userInfo.Id
            }).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("下单成功！！")
                this.close()
              } else {
                this.$message.warning(Msg)
              }
            })
          } else {
            updateCodSKUApply({
              ...this.ruleForm,
              ClientId: this.userInfo.Id,
              Ver: this.temp.Ver
            }).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("修改成功！！")
                this.close()
              } else {
                this.$message.warning(Msg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    handleChangeExpand(code) {
      this.$set(this.expands, code, !this.expands[code])
      this.$forceUpdate()
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOptions = data
      })
    },
    setDialogWidth() {
      const screenWidth = window.innerWidth
      if (screenWidth < 1400) {
        this.dialogWidth = '95%'
      } else if (screenWidth < 1920) {
        this.dialogWidth = '1200px'
      } else {
        this.dialogWidth = '1400px'
      }
    }
  }
}

</script>

<style lang="scss">
.warehouse-dialog {
  .el-dialog__body {
    padding: 20px 30px;
  }

  .el-dialog__header {
    padding: 15px 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;

    .el-dialog__title {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>

<style scoped lang="scss">
.queryItems {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
}
.multiple-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;

  .sku-item {
    background: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    &:hover {
      //transform: translateY(-2px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

      .absolute-icon {
        opacity: 1;
      }
    }

    .absolute-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      opacity: 0;
      transition: all 0.3s ease;
      background: rgba(255, 255, 255, 0.9);
      padding: 4px;
      border-radius: 50%;
      color: #f56c6c;

      &:hover {
        background: #f56c6c;
        color: white;
      }
    }

    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 12px;

      .label {
        min-width: 80px;
        color: #909399;
        font-size: 14px;
        line-height: 1.5;
        padding-right: 12px;
      }

      .value {
        flex: 1;
        color: #303133;
        font-size: 14px;
        line-height: 1.5;
        word-break: break-all;
      }

      .sku-img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .add-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    border: 2px dashed #dcdfe6;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: #409EFF;
      background: rgba(64, 158, 255, 0.05);

      .add-item-icon {
        color: #409EFF;
        transform: scale(1.1);
      }
    }

    .add-item-icon {
      font-size: 40px;
      color: #909399;
      transition: all 0.3s ease;
    }
  }
}

.el-form {
  .el-form-item {
    margin-bottom: 20px;

    .el-input,
    .el-select,
    .el-textarea {
      .el-input__inner,
      .el-textarea__inner {
        transition: all 0.3s ease;

        &:focus {
          box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
        }
      }
    }
  }
}

.el-button--text {
  padding: 8px 16px;
  margin: 8px 0;

  i {
    transition: transform 0.3s ease;
  }

  &:hover i {
    transform: translateY(2px);
  }
}

.dialog-footer {
  padding-top: 20px;
  border-top: 1px solid #eee;

  .el-button {
    padding: 10px 24px;

    &+.el-button {
      margin-left: 16px;
    }
  }
}
</style>
