<template>
  <div class="tooltip-container">
    <span class="text" style="color: #666; font-weight: 700; font-size: 1.8rem">
    <svg t="1734294056361" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6098"
         xmlns:xlink="http://www.w3.org/1999/xlink" width="2.5rem" height="2.5rem">
    <path d="M512 1002.666667c-66.133333 0-130.133333-12.8-192-38.4-57.6-25.6-110.933333-59.733333-155.733333-104.533333S85.333333 761.6 59.733333 704C34.133333 642.133333 21.333333 578.133333 21.333333 512c0-66.133333 12.8-130.133333 38.4-192C85.333333 262.4 119.466667 209.066667 164.266667 164.266667 209.066667 119.466667 262.4 85.333333 320 59.733333 381.866667 34.133333 445.866667 21.333333 512 21.333333c66.133333 0 130.133333 12.8 192 38.4 57.6 25.6 110.933333 59.733333 155.733333 104.533333 44.8 44.8 81.066667 98.133333 104.533333 155.733333 25.6 59.733333 38.4 123.733333 38.4 192 0 66.133333-12.8 130.133333-38.4 192-25.6 57.6-59.733333 110.933333-104.533333 155.733333-44.8 44.8-98.133333 81.066667-155.733333 104.533333C642.133333 989.866667 578.133333 1002.666667 512 1002.666667L512 1002.666667zM512 64C264.533333 64 64 264.533333 64 512c0 247.466667 200.533333 448 448 448 247.466667 0 448-200.533333 448-448C957.866667 264.533333 757.333333 64 512 64L512 64zM512 64"
        fill="#666666" p-id="6099"></path>
    <path d="M509.866667 565.333333c-108.8 0-198.4-89.6-198.4-198.4 0-108.8 89.6-198.4 198.4-198.4 108.8 0 198.4 89.6 198.4 198.4C708.266667 475.733333 620.8 565.333333 509.866667 565.333333L509.866667 565.333333zM509.866667 211.2c-85.333333 0-155.733333 70.4-155.733333 155.733333 0 85.333333 70.4 155.733333 155.733333 155.733333 85.333333 0 155.733333-70.4 155.733333-155.733333C665.6 281.6 595.2 211.2 509.866667 211.2L509.866667 211.2zM509.866667 211.2"
        fill="#666666" p-id="6100"></path>
    <path d="M221.866667 864l-42.666667-4.266667c0-4.266667 8.533333-83.2 51.2-164.266667 25.6-49.066667 59.733333-87.466667 100.266667-115.2 51.2-34.133333 110.933333-53.333333 181.333333-53.333333 68.266667 0 130.133333 17.066667 181.333333 51.2 40.533333 27.733333 74.666667 64 100.266667 110.933333 44.8 78.933333 51.2 157.866667 51.2 160l-42.666667 4.266667c0 0-6.4-72.533333-46.933333-145.066667-53.333333-91.733333-134.4-138.666667-243.2-138.666667-108.8 0-192 49.066667-243.2 142.933333C228.266667 789.333333 221.866667 861.866667 221.866667 864L221.866667 864zM221.866667 864"
        fill="#666666" p-id="6101"></path>
    </svg>
<!--    <svg-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--        fill="none"-->
<!--        viewBox="0 0 95 114"-->
<!--        class="svgIcon"-->
<!--    >-->
<!--      <rect :style="{ fill : internal ? 'white' : 'black'}" rx="28.5" height="57" width="57" x="19"></rect>-->
<!--      <path-->
<!--          :style="{ fill : internal ? 'white' : 'black'}"-->
<!--          d="M0 109.5C0 83.2665 21.2665 62 47.5 62V62C73.7335 62 95 83.2665 95 109.5V114H0V109.5Z"-->
<!--      ></path>-->
<!--    </svg>-->
    {{ userInfo.Name }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['userInfo', 'internal'])
  }
}

</script>

<style lang="scss" scoped>
.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #4ea6e5;
  
  &:hover {
    background-color: rgba(64, 158, 255, 0.1);
  }
}

.text {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  
  .svgIcon {
    width: 1.8rem;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  &:hover .svgIcon {
    transform: scale(1.1);
  }
}
</style>