<template>
  <div class="warehouse-container">
    <div class="warehouse-title">{{ title }}</div>
    <div class="query-section">
      <div class="query-content">
        <search-input
            v-model="query.OrderCode"
            :type.sync="queryType"
            :options="queryOptions"
            :placeholder="'请输入搜索内容，多个内容用逗号或换行符分隔'"
            @clear="clearQuery"
            @search="getData"
            class="search-input"
        />
        <el-badge :hidden="!badgeNum" :value="badgeNum" class="filter-badge">
          <el-button type="default" size="mini" icon="el-icon-s-operation" @click="showFilter">筛选</el-button>
        </el-badge>
        <el-button type="warning" plain size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
        <el-button
            v-if="!isProblem"
            style="margin-left: 0"
            type="danger"
            plain
            size="mini"
            :disabled="!selectedRows.length"
            @click="batchDelete">
          <i class="el-icon-delete"></i>
          <span>批量删除</span>
        </el-button>
      </div>
    </div>
    <div class="table-section">
      <table-copy ref="copyTable" table-selector=".el-table__body-wrapper" :has-selection="!isProblem">
        <el-table
            :data="listData"
            size="mini"
            border
            :height="tableHeight"
            :max-height="tableHeight"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              v-if="!isProblem"
              type="selection"
              width="55"
              :selectable="row => !row.StorageQuantity">
          </el-table-column>
          <el-table-column label="入仓单号" prop="OrderCode" align="center"></el-table-column>
          <el-table-column label="仓库" prop="WareHouseName" align="center"></el-table-column>
          <el-table-column label="申请数量" prop="Quantity" align="center"></el-table-column>
          <el-table-column label="实到数量" prop="StorageQuantity" align="center"></el-table-column>
          <el-table-column label="创建时间" prop="CreateTime" align="center" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.CreateTime | formatDate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
          <el-table-column
              label="操作"
              width="120"
              fixed="right"
              align="center">
            <template slot-scope="scope">
              <el-tooltip content="查看详情" placement="top">
                <el-button type="text" size="mini" @click="showRow(scope.row)">
                  <i class="el-icon-view operation-icon"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="编辑" placement="top" v-if="!scope.row.StorageQuantity || isProblem">
                <el-button type="text" size="mini" @click="editRow(scope.row)">
                  <i class="el-icon-edit operation-icon"></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </table-copy>
      <el-pagination
          class="pagination"
          background
          @size-change="handleSizeChange"
          @current-change="handleChange"
          :current-page="PageIndex"
          :page-sizes="PageSizes"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <warehouse-application ref="warehouseApplication" dialog-status="update" :show="showWarehouseApplication" :temp="currentTemp" :c-o-d-apply-infos="currentApplyInfo" @close="handleApplicationClose"></warehouse-application>
    <cod-apply-detail :show="showDetail" :cod-object="currentRow" @close="handleDetailClose"></cod-apply-detail>
    <filter-drawer :time-options="timeTypeOptions" :list-data="drawerListData" @handle-confirm="handleConfirm" @close="handleDrawerClose" :drawer="drawer" :initial-query="drawerQuery"></filter-drawer>
  </div>
</template>

<script>
import {delCodSKUApply, getApplyProblem, getCodSKUApply, getCodSKUApplyDetail} from "@/api/cod";
import CodApplyDetail from "@/views/member/cod/CodApplyDetail.vue";
import WarehouseApplication from "@/views/member/cod/WarehouseApplication.vue";
import FilterDrawer from "@/components/FilterDrawer/index.vue";
import SearchInput from "@/components/SearchInput/index.vue";
import TableCopy from "@/components/TableCopy/index.vue";
import pagination from "@/utils/mixin/pagination";
import FilterDrawers from "@/utils/mixin/filterDrawers";
export default {
  mixins: [ pagination, FilterDrawers ],
  props: {
    title: {
      type: String,
      default: "入仓单管理"
    },
    isProblem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDetail: false,
      showWarehouseApplication: false,
      currentRow: {},
      currentTemp: {},
      currentApplyInfo: [],
      query: {

      },
      listData: [],
      loading: false,
      selectedRows: [],
      drawerQuery: {
        dateRange: [],
        timeType: 1
      },
      timeTypeOptions: [
        { label: '创建时间', value: 1 }
      ],
      queryType: 15,
      queryOptions: [
        { label: '入仓单号', value: 15 },
      ],
    }
  },
  components: {
    TableCopy,
    SearchInput,
    CodApplyDetail,
    WarehouseApplication,
    FilterDrawer
  },
  computed: {
    tableHeight() {
      return 'calc(100vh - 25.6rem - 64px)'
    }
  },
  created() {
    this.getWareHouse()
    this.getData()
    this.$nextTick(() => {
      this.$refs.copyTable.destroySelection()
      setTimeout(() => {
        this.$refs.copyTable.initSelection()
      }, 100)
    })
  },
  methods: {
    clearQuery() {
      this.query.OrderCode = ""
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.drawerQuery = {
        dateRange: []
      }
      this.clearQuery()
    },
    handleDetailClose() {
      this.showDetail = false
    },
    handleApplicationClose() {
      this.showWarehouseApplication = false
      this.getData()
    },
    showRow(row) {
      const data = {
        Code: row.OrderCode
      }
      getCodSKUApplyDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.currentRow = Data
          this.showDetail = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getData() {
      let httpApi
      if (this.isProblem) {
        httpApi = getApplyProblem
      } else {
        httpApi = getCodSKUApply
      }
      let QueryCodes = this.query.OrderCode ?
          this.query.OrderCode.split(/[,，\s\r\n\t]+/).filter(item => item.trim()) :
          undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        StartTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[0] : undefined,
        EndTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[1] : undefined,
        QueryCodeType: this.queryType,
        QueryCodes: QueryCodes,
        QueryType: this.drawerQuery.timeType,
        WareHouseId: this.drawerQuery.WareHouseId || undefined,
      }
      httpApi(data).then(response => {
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    editRow(row) {
      const data = {
        Code: row.OrderCode
      }
      getCodSKUApplyDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.currentApplyInfo = Data.CODApplyInfos
          this.currentTemp = {
            Id: Data.Id,
            OrderCode: Data.OrderCode,
            WareHouseId: Data.WareHouseId,
            Rem: Data.Rem,
            Ver: Data.Ver,
            CODApplyInfos: this.currentApplyInfo.map(item => {
              return {
                SKUId: item.Id,
                Quantity: item.ApplyQuantity
              }
            })
          }
          this.$nextTick(() => {
            this.$refs.warehouseApplication.init()
            this.showWarehouseApplication = true
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    batchDelete() {
      if (!this.selectedRows.length) return

      const ids = this.selectedRows.map(row => row.Id)
      const codes = this.selectedRows.map(row => row.OrderCode).join('、')

      this.$confirm(`该操作会永久删除入仓单号为（${codes}）的数据，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      )
          .then(() => {
            delCodSKUApply(ids).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("删除成功！！")
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          })
          .catch(() => {
            this.$message.info("已取消删除")
          });
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.drawerListData.push({
          title: "仓库",
          field: "WareHouseId",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
    }
  }
}

</script>

<style scoped lang="scss">
.warehouse-container {
  padding: 2rem;
  background-color: #f5f7fa;

  .warehouse-title {
    padding: 1.5rem 2.5rem;
    height: 1.6rem;
    color: #225493;
    background-color: #fff;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 4px;
      height: 16px;
      background-color: #409EFF;
      margin-right: 10px;
      border-radius: 2px;
    }
  }

  .query-section {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

    .query-content {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .search-input {
        width: 400px;
      }

      .filter-badge {
        margin-left: 0.5rem;
      }

      :deep(.el-button--mini) {
        font-size: 12px;
        padding: 7px 12px;
        height: 28px;
        line-height: 1;
      }
    }
  }

  .table-section {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

    :deep(.el-table) {
      border-radius: 4px;

      th {
        background-color: #f5f7fa;
        color: #606266;
      }
    }

    .pagination {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  :deep(.operation-icon) {
    font-size: 16px;
    color: #409EFF;

    &:hover {
      color: #66b1ff;
    }
  }

  :deep(.el-button--text) {
    padding: 0 8px;
  }
}
</style>
