<template>
  <div class="container">
    <div class="ipt-box u-between-flex">
      <div class="introduce-box animated bounceInUp">
        <div class="introduce-title mb30">{{ shop_name }}</div>
        <div class="introduce-line mb30"></div>
        <div class="introduce-sub-title mt40 mb30">{{ $t('register.subTitle1') }}</div>
<!--        <div class="introduce-small-title mt20 mb10">{{ $t('register.subTitle2') }}</div>-->
        <div class="introduce-small-title mb10">{{ $t('register.subTitle3') }}</div>
        <div class="introduce-small-title">{{ $t('register.subTitle4') }}</div>
      </div>
      <div class="login-box animated flipInY">
        <div class="login-title">{{ $t('register.item', [shop_name]) }}</div>
        <div class="login-form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="" prop="username">
              <el-input v-model="ruleForm.username" style="width: 32rem;" size="small" :placeholder="$t('register.account')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input v-model="ruleForm.password" style="width: 32rem;" size="small" show-password :placeholder="$t('register.password')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="AgentId">
              <el-input v-model="ruleForm.AgentId" :disabled="!!$route.query.AgentId" style="width: 32rem;" size="small" :placeholder="$t('register.agent')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="Recommender">
              <el-input v-model="ruleForm.Recommender" :disabled="!!$route.query.Recommender" style="width: 32rem;" size="small" :placeholder="$t('register.recommender')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="Email">
              <el-input v-model="ruleForm.email" style="width: 32rem;" size="small" :placeholder="$t('register.email')"></el-input>
            </el-form-item>
            <div class="u-between-flex" style="width: 32rem;">
              <el-form-item label="" prop="tel">
                <el-select @change="handleChange" v-model="ruleForm.tel" size="small" style="width: 10rem">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="phone">
                <el-input v-model="ruleForm.phone" style="width: 20rem;" size="small" :placeholder="$t('register.phone')"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="" prop="aws" v-if="false">
              <el-input v-model="ruleForm.aws" style="width: 32rem;" size="small" :placeholder="$t('register.aws')"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-checkbox class="mb10 last-checkbox" v-model="term">{{ $t('register.term', [shop_name]) }}
          <span>
            <button class="pointer term-title" @click.stop="showVisible" style="background:none;border:none;padding:0;">
              {{ $t('register.termTitle1') }}
            </button>
          </span>
          {{ $t('register.termUse') }}
        </el-checkbox>
        <div class="pointer" style="font-size: 1.4rem; color: #333333;" @click="$router.push('/login')">{{ $t('register.hasAccount') }}</div>
        <div class="mt20 mb10">
          <el-button v-loading="loading" class="login-btn" @click="submitForm('ruleForm')">{{ $t('register.submit') }}</el-button>
        </div>
      </div>
    </div>
    <el-dialog
        :title="$t('register.prompt')"
        :visible.sync="visible"
        width="30%"
        center>
      <div v-html="content"></div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="visible = false">确 认</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { CountryInfo } from "@/api/system"
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        password: undefined,
        aws: undefined,
        AgentId: "",
        Recommender: "",
        tel: "",
        phone: undefined
      },
      visible: false,
      options: [],
      content: "",
      notice: false,
      loading: false,
      term: false
    }
  },
  computed: {
    ...mapGetters(['shop_name']),
    rules() {
      return {
        username: [
          { required: true, message: this.$t('register.placeholderAccount'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('register.placeholderPassword'), trigger: 'blur' }
        ],
        phone: [
          // { required: true, message: this.$t('register.placeholderPhone'), trigger: 'blur' },
          // { validator: this.validatePhoneNum, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.ruleForm.AgentId = this.$route.query.AgentId;
    this.ruleForm.Recommender = this.$route.query.Recommender;
    this.ruleForm.ServicePersonnel = this.$route.query.ServicePersonnel;
    this.getCountryCodes()
  },
  methods: {
    handleChange(val) {
      if (val) {
        this.ruleForm.DestinationId = this.options.filter(item => {
          return item.value === val
        })[0].id
      }
    },
    validatePhoneNum(rule, value, callback) {
      if (this.ruleForm.tel === '+86') {
        if (value.length !== 11) {
          callback(new Error(this.$t('register.mainlandPhone') || ""));
        } else {
          callback();
        }
      } else if (this.ruleForm.tel === '+852') {
        if (value.length !== 8) {
          callback(new Error(this.$t('register.twPhone') || ""));
        } else {
          callback();
        }
      } else {
        callback(); // 其他情况通过验证
      }
    },
    showVisible() {
      this.$store.dispatch('webSite/getShopMatters', 7).then(Data => {
        this.content = Data[0].Content;
        this.visible = true
      })
    },
    getCountryCodes() {
      const data = {
        tenantId: 1
      }
      CountryInfo(data).then(response => {
        const { Data } = response
        Data.forEach(item => {
          this.options.push({
            id: item.Id,
            label: item.Name,
            value: item.Code
          })
        })
        this.ruleForm.tel = this.options[0].value;
        this.ruleForm.DestinationId = this.options[0].id;
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.term) {
            this.$message({
              message: this.$t('register.placeholderTerm'),
              type: 'warning'});
            return;
          }
          this.loading = true;
          this.$store.dispatch('user/register', this.ruleForm).then(() => {
            this.$message({
              message: this.$t('register.success'),
              type: 'success'
            });
            this.$store.dispatch('user/login', {
              username: this.ruleForm.username,
              password: this.ruleForm.password
            }).then(() => {
              this.$store.commit('user/SET_SHOW_DIALOG', true)
              this.$router.push({
                path: '/member/personal'
              })
              this.loading = false
            }).catch(() => {
              this.loading = false
            })
          }).catch((error) => {
            console.log(error)
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}

</script>

<style scoped lang="scss">
.last-checkbox {
  ::v-deep .el-checkbox__input {
    cursor: pointer;
    outline: 0;
    line-height: normal;
    vertical-align: top;
  }
}
::v-deep .el-form-item {
  margin-bottom: 2rem;
}
::v-deep .el-input__suffix {
  display: none;
}
.term-title {
  color: #264CAF !important;
}
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-image: url("../../static/images/bgc.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .ipt-box {
    position: absolute;
    top: 12%;
    left: 15%;
    right: 20%;
    .introduce-box {
      .introduce-title {
        font-size: 6rem;
        color: #FFFFFF;
      }
      .introduce-line {
        width: 60rem;
        height: 0.125rem;
        background-color: #fff;
      }
      .introduce-sub-title {
        font-size: 4.8rem;
        color: #FFFFFF;
      }
      .introduce-small-title {
        font-size: 1.6rem;
        color: #FFFFFF;
      }
    }
    .login-box {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0.8rem;
      padding: 2.5rem;
      width: 32rem;
      .login-title {
        font-size: 2rem;
        color: #333333;
        border-bottom: 0.1rem solid #999999;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
      }
      .login-btn {
        width: 32rem;
        height: 4.8rem;
        background: linear-gradient(to bottom right, #093267, #3F63EC);
        border-radius: 0.8rem;
        color: #fff;
        font-size: 1.6rem;
        vertical-align: center;
      }
    }
  }
}
</style>
