<template>
  <div>
    <el-select
        v-model="printTemplate"
        size="mini"
        class="mr10"
        style="width: 120px">
      <el-option
          v-for="item in printTemplateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <el-button
        style="margin-left: 0"
        type="success"
        plain
        size="mini"
        icon="el-icon-printer"
        :loading="printLoading"
        @click="exportOrder">
      {{ printLoading ? '打印中' : '批量打印' }}
    </el-button>
  </div>
</template>

<script>
import { getSheetByte } from "@/api/member";
import * as pdfLib from "pdf-lib";
import printJS from "print-js";

export default {
  props: {
    multipleSelection: {
      type: Array,
      default: () => []
    },
    templateName: {
      type: String,
      default: "拣货单"
    }
  },
  data() {
    return {
      printLoading: false,
      printTemplate: 1,
      printTemplateOptions: [
        { label: '简易面单', value: 1 },
        { label: '承运商面单', value: 2 }
      ],
    }
  },
  methods: {
    base64ToArrayBuffer(base64) {
      let binaryString = ''
      if (base64.indexOf('data:') === 0) {
        const arr = base64.split(',')
        binaryString = window.atob(arr[1])
      } else {
        binaryString = window.atob(base64)
      }

      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    },

// 二进制转base64
    arrayBufferToBase64(buffer) {
      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      // 对二进制字符串进行Base64编码
      return window.btoa(binary)
    },
    exportOrder() {
      if (!this.multipleSelection.length) {
        this.$message.warning("请选择数据");
        return
      }
      this.printLoading = true;
      const data = {
        BillCodes: this.multipleSelection.map(item => {
          return item.MainBillCode
        }),
        TemplateName: this.printTemplate === 1 ? this.templateName : undefined
      }
      getSheetByte(data).then(async response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          const pdfDocument = await pdfLib.PDFDocument.create()
          for (const data of Data.map(item => { return item.File })) {
            const arrayBuffer = await this.base64ToArrayBuffer(data)
            const pdfDoc = await pdfLib.PDFDocument.load(arrayBuffer)
            const copyPage = await pdfDocument.copyPages(pdfDoc, pdfDoc.getPageIndices())
            copyPage.forEach(item => {
              pdfDocument.addPage(item)
            })
          }
          const pdf = await pdfDocument.save()
          const pdfFile = this.arrayBufferToBase64(pdf)
          printJS({
            printable: pdfFile,
            type: 'pdf',
            base64: true
          })
        } else {
          this.$message.warning(Msg)
        }
      }).finally(() => {
        this.printLoading = false;
      })
    },
  }
}

</script>

<style scoped lang="scss">

</style>
