<template>
  <div class="sku-container">
    <div class="sku-title">商品SKU管理</div>
    <div class="queryItems mb20">
      <search-input
          v-model="query.SKU"
          :type.sync="queryType"
          :options="queryOptions"
          :placeholder="'请输入搜索内容，多个内容用逗号或换行符分隔'"
          @clear="clearQuery"
          @search="getData"
      />
      <el-button type="primary" class="" size="small" icon="el-icon-plus" @click="addRow">添加</el-button>
      <el-button
          type="success"
          plain
          size="mini"
          icon="el-icon-folder-opened"
          @click="exportExcelWithImages"
      >
          导出
      </el-button>
      <el-upload
          action="#"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleFileSelect"
          accept=".xls,.xlsx"
      >
        <el-button
            style="margin-right: 10px; margin-left: 10px"
            type="info"
            size="mini"
        >
          批量添加SKU<i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </el-upload>
      <el-button @click="exportExcel" type="primary" size="mini">
        下载模板文件<i class="el-icon-download el-icon--right"></i>
      </el-button>
      <el-button @click="batchImageVisible = true" type="primary" size="mini">
        批量修改图片<i class="el-icon-upload2 el-icon--right"></i>
      </el-button>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-printer"
        :loading="loadingPrint"
        @click="printSelectedSKUs"
        :disabled="!multipleSelection.length"
      >
        打印SKU
      </el-button>
      <el-button
        type="danger"
        size="mini"
        icon="el-icon-delete"
        @click="handleBatchDelete"
        :disabled="!multipleSelection.length"
      >
        删除
      </el-button>
    </div>
    <table-copy ref="copyTable" table-selector=".el-table__body-wrapper">
      <el-table
          :data="listData"
          border
          ref="multipleTable"
          size="mini"
          :height="tableHeight"
          :max-height="tableHeight"
          style="width: 100%"
          v-loading="loading"
          element-loading-text="获取数据中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="SKU编码" prop="SKUCode" align="center" width="180" />
        <el-table-column label="图片" prop="ImagePath" align="center" width="60px">
          <template slot-scope="scope">
            <el-image class="sku-img" :preview-src-list="[scope.row.ImagePath]" :src="scope.row.ImagePath" v-if="scope.row.ImagePath"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="Name" align="center"></el-table-column>
        <el-table-column label="尺寸" prop="MainBillCode" align="center" width="160">
          <template slot-scope="scope">
            <span>{{ `${scope.row.Length}cm*${scope.row.Width}cm*${scope.row.Height}cm` }}</span>
          </template>
        </el-table-column>
        <el-table-column label="尺码" prop="Size" align="center"></el-table-column>
        <el-table-column label="规格" prop="Specification" align="center"></el-table-column>
        <el-table-column label="型号" prop="ModelNumber" align="center"></el-table-column>
        <el-table-column label="库存" prop="Quantity" align="center"></el-table-column>
        <el-table-column label="重量" prop="Weight" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.Weight }}kg</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="150"
            align="center">
          <template slot-scope="scope">
            <div class="operation-group">
              <el-tooltip content="编辑" placement="top">
                <el-button type="text" icon="el-icon-edit" @click="editRow(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip content="库存记录" placement="top">
                <el-button type="text" icon="el-icon-notebook-2" @click="showInventory(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip content="库存明细" placement="top">
                <el-button type="text" icon="el-icon-document" @click="showSKUDetail(scope.row)"></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </table-copy>
    <div class="u-between-flex mt20" >
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleChange"
          :current-page="PageIndex"
          :page-sizes="PageSizes"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <div>
        <el-button @click="orderVisible=true" type="primary" size="medium">创建订单</el-button>
        <el-button @click="toWarehouseApplication" type="primary" size="medium">添加入仓单</el-button>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :title="textMap[dialogStatus]" width="90rem" :visible.sync="visible">
      <el-form size="small" :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="80px">
        <el-form-item label="SKU编码" prop="SKUCode">
          <el-input v-model="ruleForm.SKUCode" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="Name">
          <el-input v-model="ruleForm.Name" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="尺码" prop="Size">
          <el-input v-model="ruleForm.Size" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="重量" prop="Weight">
          <el-input v-model="ruleForm.Weight" type="text" size="small" autocomplete="off" style="width: 30rem">
            <template slot="append">KG</template>
          </el-input>
        </el-form-item>
        <el-form-item label="规格" prop="Specification">
          <el-input v-model="ruleForm.Specification" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="ModelNumber">
          <el-input v-model="ruleForm.ModelNumber" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="尺寸">
          <div class="u-between-flex">
            <el-input class="mr10" v-model="ruleForm.Length" placeholder="长" type="text" size="small" autocomplete="off" style="width: 20rem">
              <template slot="append">CM</template>
            </el-input>
            <el-input class="mr10" v-model="ruleForm.Width" placeholder="宽" type="text" size="small" autocomplete="off" style="width: 20rem">
              <template slot="append">CM</template>
            </el-input>
            <el-input v-model="ruleForm.Height" placeholder="高" type="text" size="small" autocomplete="off" style="width: 20rem">
              <template slot="append">CM</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="progressAvatar"
              :before-upload="beforeAvatarUpload">
            <img v-if="skuGoodsUrl || ruleForm.ImagePath" :src="skuGoodsUrl || ruleForm.ImagePath" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="Rem">
          <el-input v-model="ruleForm.Rem" type="textarea" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-collapse class="warehouse-collapse" v-if="dialogStatus === 'create'">
          <el-collapse-item>
            <template slot="title">
              <div class="collapse-title">
                <i class="el-icon-box"></i>
                <span>入仓申请信息</span>
              </div>
            </template>

            <div class="warehouse-form">
              <el-form-item label-width="100px" label="仓库" prop="WarehouseId"
                :rules="[{ required: hasAnyWarehouseField, message: '请选择仓库', trigger: 'change' }]">
                <el-select
                  v-model="ruleForm.WarehouseId"
                  placeholder="请选择仓库"
                  style="width: 30rem"
                  @change="handleWarehouseFieldChange"
                  clearable>
                  <el-option
                    v-for="item in warehouseOptions"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label-width="100px" label="入仓申请号" prop="OrderCode"
                :rules="[{ required: hasAnyWarehouseField, message: '请输入入仓申请号', trigger: 'blur' }]">
                <el-input
                  v-model="ruleForm.OrderCode"
                  style="width: 30rem"
                  @input="handleWarehouseFieldChange"
                  placeholder="请输入入仓申请号"
                  clearable>
                </el-input>
              </el-form-item>

              <el-form-item
                label="申请数量"
                prop="Quantity"
                label-width="100px"
                :rules="[{ required: hasAnyWarehouseField, message: '请输入申请数量', trigger: 'blur' }]">
                <el-input-number
                  v-model="ruleForm.Quantity"
                  :min="0"
                  style="width: 30rem"
                  @change="handleWarehouseFieldChange"
                  placeholder="请输入申请数量">
                </el-input-number>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button size="small" v-loading="loading" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
    <sku-record :show="showRecode" @close="handleRecordClose" :s-k-u-id="currentSKUId"></sku-record>
    <warehouse-application ref="warehouseApplication" @close="handleWareHouseApplicationClose" :show="showWarehouseApplication" :c-o-d-apply-infos="multipleSelection"></warehouse-application>
    <sku-detail :show="showSkuRecode" @close="handleSkuRecordClose" :s-k-u-id="currentSKUId"></sku-detail>
    <sku-batch-upload
      :table-data="uploadData"
      :show="batchVisible"
      :require-image="requireImage"
      @refresh="handleRefresh"
      @close="handleBatchClose">
    </sku-batch-upload>
    <place-order @close="handleOrderClose" :show="orderVisible" :multiple-data="multipleSelection"></place-order>
    <batch-upload-image @refresh="handleRefreshList" :show="batchImageVisible" @close="handleBatchUploadClose"></batch-upload-image>
  </div>
</template>

<script>
import {addCodSKU, delCodSKU, getCodSKU, getSkuSheet, importSku, updateCodSKU} from "@/api/cod";
import {mapGetters} from "vuex"
import {downloadSKUTemplate, uploadImage, validateSKUTemplate} from "@/api/system";
import SkuRecord from "@/views/member/cod/SkuRecord.vue";
import WarehouseApplication from "@/views/member/cod/WarehouseApplication.vue";
import SkuDetail from "@/views/member/cod/SkuDetail.vue";
import SkuBatchUpload from "@/views/member/cod/SkuBatchUpload.vue";
import placeOrder from "@/views/member/cod/placeOrder.vue";
import ExcelJS from 'exceljs';
import * as xlsx from "xlsx";
import SearchInput from "@/components/SearchInput/index.vue";
import TableCopy from "@/components/TableCopy/index.vue";
import pagination from "@/utils/mixin/pagination";
import batchUploadImage from "@/views/member/cod/components/batchUploadImage.vue";
import { exportOrder } from "@/utils/print";

export default {
  mixins: [ pagination ],
  data() {
    return {
      requireImage: false,
      showRecode: false,
      showSkuRecode: false,
      showWarehouseApplication: false,
      batchImageVisible: false,
      currentSKUId: 0,
      multipleSelection: [],
      visible: false,
      batchVisible: false,
      orderVisible: false,
      ruleForm: {
        WarehouseId: undefined,
        OrderCode: '',
        Quantity: undefined
      },
      options: {},
      file: undefined,
      query: {
        SKU: "",
      },
      skuGoodsUrl: "",
      rules: {
        SKUCode: [
          { required: true, message: '请输入SKU编号', trigger: 'blur' },
        ],
        Name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ]
      },
      textMap: {
        create: "添加商品SKU",
        update: "编辑商品SKU"
      },
      loadingPrint: false,
      dialogStatus: "",
      listData: [],
      xlsxToJson: [],
      uploadData: [],
      xlsxMedias: [],
      loading: false,
      warehouseOptions: [],
      queryType: 14,
      queryOptions: [
        { label: 'SKU编码', value: 14 }
      ],
    }
  },
  created() {
    this.getData()
    this.getWarehouseOptions()
    this.$nextTick(() => {
      this.$refs.copyTable.destroySelection()
      setTimeout(() => {
        this.$refs.copyTable.initSelection()
      }, 100)
    })
  },
  components: {
    TableCopy,
    SearchInput,
    SkuRecord,
    WarehouseApplication,
    SkuDetail,
    SkuBatchUpload,
    placeOrder,
    batchUploadImage
  },
  computed: {
    ...mapGetters(["userInfo"]),
    tableHeight() {
      return 'calc(100vh - 25.6rem - 60px)'
    },
    timestampName() {
      const timestamp = Date.now()
      return `SKU信息-${timestamp}.xls`
    },
    fields() {
      const tableFields = {}
      tableFields['SKU单号'] = 'SKUCode'
      tableFields['SKU名称'] = 'Name'
      tableFields['规格'] = 'Specification'
      tableFields['型号'] = 'ModelNumber'
      tableFields['尺码'] = 'Size'
      tableFields['单价'] = 'UnitPrice'
      tableFields['重量'] = 'Weight'
      tableFields['长'] = 'Length'
      tableFields['宽'] = 'Width'
      tableFields['高'] = 'Height'
      tableFields['图片'] = 'ImagePath'
      tableFields['说明'] = 'Rem'
      return tableFields
    },
    hasAnyWarehouseField() {
      return !!(this.ruleForm.WarehouseId || this.ruleForm.OrderCode || this.ruleForm.Quantity)
    }
  },
  methods: {
    clearQuery() {
      this.query.SKU = ""
      this.PageIndex = 1
      this.getData()
    },
    handleBatchUploadClose() {
      this.batchImageVisible = false
    },
    handleRefreshList() {
      this.getData()
      this.handleBatchUploadClose()
    },
    handleBatchClose() {
      this.batchVisible = false
    },
    handleOrderClose() {
      this.orderVisible = false
    },
    handleRefresh() {
      this.batchVisible = false
      this.PageIndex = 1
      this.getData()
    },
    handleXlsxToJson(event) {
      this.xlsxToJson = [];
      const file = event.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
        let headers = jsonData[0];

        jsonData.slice(1).forEach(row => {
          const rowData = row.reduce((accumulator, item, index) => {
            let column = headers[index];
            accumulator[column] = item;
            return accumulator;
          }, {});

          if (Object.values(rowData).some(value => value !== '')) {
            this.xlsxToJson.push(rowData);
          }
        });

        this.afterProcessData();
      };
      reader.readAsArrayBuffer(file);
    },
    async handleFileSelect(event) {
      try {
        const file = event.raw;

        // 使用组件内部的 requireImage
        if (this.requireImage) {
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(file);
          this.xlsxMedias = workbook.media;
        }

        this.handleXlsxToJson(event);
      } catch (error) {
        console.error('Excel 处理错误:', error);
        this.$message.error('Excel 文件处理失败，请检查文件格式是否正确');
      }
    },
    excelToJson() {
      return this.xlsxToJson.map(item => {
        return {
          SKUCode: item['SKU单号'],
          Name: item['SKU名称'],
          Specification: item['规格'],
          ModelNumber: item['型号'],
          Size: item['尺码'],
          UnitPrice	: item['单价'],
          Weight: item['重量'],
          Length: item['长'],
          Width: item['宽'],
          Height: item['高'],
          OrderCode: item['入仓单号'],
          Quantity: item['申请数量'] || 0,
          WarningsNumber: item['预警数量'],
          Rem: item['说明']
        }
      })
    },
    afterProcessData() {
      const data = this.excelToJson();
      validateSKUTemplate(data).then(response => {
        const { Code, Data, Msg } = response;
        if (Code === 200) {
          this.uploadData = Data;

          // 使用组件内部的 requireImage
          if (this.requireImage) {
            // 创建图片ID到图片数据的映射
            const imageMap = new Map();

            // 先遍历所有数据，收集所有不同的图片ID
            const uniqueImageIds = new Set();
            this.xlsxToJson.forEach(item => {
              if (item['图片']) {
                const match = item['图片'].match(/ID_[A-F0-9]+/);
                if (match) {
                  uniqueImageIds.add(match[0]);
                }
              }
            });

            // 将提取的图片按顺序与ID对应
            let imageIndex = 0;
            uniqueImageIds.forEach(imageId => {
              if (imageIndex < this.xlsxMedias.length) {
                imageMap.set(imageId, {
                  buffer: this.xlsxMedias[imageIndex].buffer,
                  extension: this.xlsxMedias[imageIndex].extension
                });
                imageIndex++;
              }
            });

            // 遍历数据，根据图片ID设置对应的图片数据
            this.xlsxToJson.forEach((item, index) => {
              if (item['图片']) {
                const match = item['图片'].match(/ID_[A-F0-9]+/);
                if (match) {
                  const imageId = match[0];
                  const imageData = imageMap.get(imageId);
                  if (imageData) {
                    this.uploadData[index].SkuImage = `data:image/jpeg;base64,${imageData.buffer.toString('base64')}`;
                    this.uploadData[index].ImageBuffer = imageData.buffer;
                    this.uploadData[index].extension = imageData.extension;
                  }
                }
              }
            });
          }

          this.batchVisible = true;
        } else {
          this.$message.warning(Msg);
        }
      });
    },
    async exportExcelWithImages() {
      try {
        this.loading = true;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('sheet1');

        // 设置列
        worksheet.columns = [
          { header: 'SKU单号', key: 'SKUCode', width: 15 },
          { header: 'SKU名称', key: 'Name', width: 20 },
          { header: '规格', key: 'Specification', width: 15 },
          { header: '型号', key: 'ModelNumber', width: 15 },
          { header: '尺码', key: 'Size', width: 10 },
          { header: '单价', key: 'UnitPrice', width: 10 },
          { header: '重量', key: 'Weight', width: 10 },
          { header: '长', key: 'Length', width: 10 },
          { header: '宽', key: 'Width', width: 10 },
          { header: '高', key: 'Height', width: 10 },
          { header: '图片', key: 'ImagePath', width: 15 },
          { header: '说明', key: 'Rem', width: 20 }
        ];

        // 设置表头样式
        worksheet.getRow(1).font = { bold: true };

        // 添加数据和图片
        for (let i = 0; i < this.listData.length; i++) {
          const row = this.listData[i];
          worksheet.addRow(row);

          // 如果有图片，加图片到单元格
          if (row.ImagePath) {
            try {
              const response = await fetch(row.ImagePath);
              const buffer = await response.arrayBuffer();

              const imageId = workbook.addImage({
                buffer,
                extension: 'png',
              });

              // 在图片列添加图片，设置图片大小
              worksheet.addImage(imageId, {
                tl: { col: 10, row: i + 1 }, // 图片列索引为10
                ext: { width: 50, height: 50 } // 设置图片大小
              });

              // 调整行以适应图片
              worksheet.getRow(i + 2).height = 40;
            } catch (error) {
              console.error('图片处理失败:', error);
            }
          }
        }

        // 导出文件
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `SKU信息-${Date.now()}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);

        this.$message.success('导出成功');
      } catch (error) {
        console.error('导出失败:', error);
        this.$message.error('导出失败');
      } finally {
        this.loading = false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showSKUDetail(row) {
      this.currentSKUId = row.Id
      this.showSkuRecode = true
    },
    handleRecordClose() {
      this.showRecode = false
    },
    handleSkuRecordClose() {
      this.showSkuRecode = false
    },
    handleWareHouseApplicationClose() {
      this.showWarehouseApplication = false
    },
    editRow(row) {
      this.ruleForm = Object.assign({}, row)
      this.skuGoodsUrl = undefined
      this.dialogStatus = "update"
      this.visible = true
    },
    handleBatchDelete() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择要删除的SKU');
        return;
      }

      this.$confirm(
        `确认删除选中的 ${this.multipleSelection.length} 个SKU吗？`,
        "提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: 'warning'
        }
      )
      .then(() => {
        const ids = this.multipleSelection.map(item => item.Id);
        delCodSKU(ids).then(response => {
          const { Code, Msg, Success } = response;
          if (Code === 200 && Success) {
            this.$message.success("删除成功！");
            this.getData();
          } else {
            this.$message.warning(Msg);
          }
        });
      })
      .catch(() => {
        this.$message.info("已取消删除");
      });
    },
    addRow() {
      this.dialogStatus = "create"
      this.resetTemp()
      this.visible = true
    },
    uploadFile() {
      return new Promise((resolve, reject) => {
        if (!this.file) {
          resolve(undefined)
        } else {
          const extension =  this.file.name.slice(this.file.name.lastIndexOf('.') + 1);
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('FileName', `${this.userInfo.Id}${this.ruleForm.SKUCode}.${extension}`);
          formData.append('Path', 'SKUImg');
          uploadImage(formData).then(response => {
            const { Code, Msg, Data } = response
            if (Code === 200) {
              resolve(Data.ServerPath)
            } else {
              reject(Msg)
            }
          })
        }
      })
    },
    resetTemp() {
      this.ruleForm = {
        WarehouseId: undefined,
        OrderCode: '',
        Quantity: undefined
      }
      this.file = ""
      this.skuGoodsUrl = ""
    },
    toWarehouseApplication() {
      if (!this.multipleSelection.length) {
        this.$message.warning("请选择SKU")
        return;
      }
      this.$refs.warehouseApplication.init()
      this.showWarehouseApplication = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const ServerPath = await this.uploadFile()
          if (this.dialogStatus === "create") {
            // 基础数据
            const baseData = {
              Name: this.ruleForm.Name,
              SKUCode: this.ruleForm.SKUCode,
              ModelNumber: this.ruleForm.ModelNumber,
              Specification: this.ruleForm.Specification,
              Size: this.ruleForm.Size,
              Weight: this.ruleForm.Weight,
              Length: this.ruleForm.Length,
              Width: this.ruleForm.Width,
              Height: this.ruleForm.Height,
              Image: ServerPath,
              Rem: this.ruleForm.Rem
            }
            if (this.hasAnyWarehouseField) {
              const data = {
                WarehouseId: this.ruleForm.WarehouseId,
                CODSKUImportInfos: [
                  {
                    ...baseData,
                    OrderCode: this.ruleForm.OrderCode,
                    Quantity: this.ruleForm.Quantity,
                  }
                ]
              }
              importSku(data).then(response => {
                const { Code, Msg } = response
                if (Code === 200) {
                  this.$message.success("添加成功！！！")
                  this.visible = false
                  this.getData()
                } else {
                  this.$message.warning(Msg)
                }
              })
            } else {
              // 原有的创建逻辑
              addCodSKU(baseData).then(response => {
                const { Code, Msg, Success } = response
                if (Code === 200 && Success) {
                  this.$message.success("添加成功！！！")
                  this.visible = false
                  this.getData()
                } else {
                  this.$message.warning(Msg)
                }
              })
            }
          } else {
            // 编辑逻辑保持不变
            const data = {
              Name: this.ruleForm.Name,
              SKUCode: this.ruleForm.SKUCode,
              ModelNumber: this.ruleForm.ModelNumber,
              Specification: this.ruleForm.Specification,
              Size: this.ruleForm.Size,
              Weight: this.ruleForm.Weight,
              Length: this.ruleForm.Length,
              Width: this.ruleForm.Width,
              Height: this.ruleForm.Height,
              Image: ServerPath || this.ruleForm.Image,
              Rem: this.ruleForm.Rem,
              Id: this.ruleForm.Id
            }
            updateCodSKU(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("修改成功！！！")
                this.visible = false
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getData() {
      let QueryCodes = this.query.SKU ? this.query.SKU.split(/[，,\s\r\n\t]+/) : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        QueryCodeType: 14,
        QueryCodes: QueryCodes,
      }
      this.loading = true
      getCodSKU(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    printSelectedSKUs() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择要打印的SKU');
        return;
      }
      const SKUIds = this.multipleSelection.map(item => {
        return item.Id
      });
      this.loadingPrint = true
      const data = {
        SKUIds: SKUIds
      }
      getSkuSheet(data).then(response => {
        this.loadingPrint = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          exportOrder(Data)
        } else {
          this.$message.warning(Msg)
        }
      }).catch(() => {
        this.loadingPrint = false
      })
    },
    showInventory(row) {
      this.currentSKUId = row.Id
      this.showRecode = true
    },
    progressAvatar() {
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("图片超过大小！！");
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.skuGoodsUrl = e.target.result;
      };
      this.file = file
      return isLt2M;
    },
    exportExcel() {
      try {
        downloadSKUTemplate().then(response => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a');
          link.href = url
          link.setAttribute('download', `SKU模板-${Date.now()}.xls`)
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          this.$message.success("下载成功！")
        })
      } catch (e) {
        this.$message.warning("下载失败！")
      }
    },
    getWarehouseOptions() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOptions = data
      })
    },
    handleWarehouseFieldChange() {
      // 触发表单验证
      this.$refs.ruleForm.validateField(['WarehouseId', 'OrderCode', 'Quantity'])
    }
  }
}

</script>

<style scoped lang="scss">
.el-table {
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

  th {
    background-color: #f5f7fa !important;
    color: #606266;
    font-weight: 600;
    font-size: 14px;
  }

  .el-button--text {
    padding: 0 8px;

    &:hover {
      color: #409EFF;
      background-color: #ecf5ff;
      border-radius: 4px;
    }
  }

  .operation-group {
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    gap: 3px;
  }
}

.sku-container {
  padding: 2rem;
  background-color: #f5f7fa;

  .sku-title {
    padding: 1.5rem 2.5rem;
    height: 1.6rem;
    color: #225493;
    background-color: #fff;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 4px;
      height: 16px;
      background-color: #409EFF;
      margin-right: 10px;
      border-radius: 2px;
    }
  }

  .queryItems {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;

    .u-start-flex {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .default-font {
        margin-right: 8px;
      }
    }

    .el-button {
      margin-left: 5px;
      margin-right: 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .el-upload {
      margin: 0 5px;
    }
  }
}

.sku-img {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.sku-code {
  color: #409EFF;
  cursor: pointer;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.3s;

  &:hover {
    background-color: #ecf5ff;
  }
}

.sku-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .el-button {
    width: 100%;
    text-align: left;
    margin: 0;

    i {
      margin-right: 5px;
    }
  }
}

.sku-popover {
  min-width: 120px;
}

// 修改上传头像组件样式
::v-deep .avatar-uploader {
  .el-upload {
    border: 2px dashed #dcdfe6;
    width: 120px;
    height: 120px;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      border-color: #409EFF;
      //transform: translateY(-2px);
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #909399;
    width: 120px;
    height: 120px;
    line-height: 120px;
  }

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.el-pagination {
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.el-button {
  &--primary {
    background: linear-gradient(135deg, #409EFF 0%, #3a8ee6 100%);
    border: none;

    &:hover {
      background: linear-gradient(135deg, #66b1ff 0%, #409EFF 100%);
      transform: translateY(-1px);
    }
  }
}

.warehouse-collapse {
  width: 100%;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;

  ::v-deep .el-collapse-item__header {
    background-color: #f5f7fa;
    border: none;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;

    &:hover {
      background-color: #ebeef5;
    }
  }

  .collapse-title {
    display: flex;
    align-items: center;
    color: #606266;
    font-weight: 500;

    i {
      margin-right: 8px;
      font-size: 18px;
      color: #409EFF;
    }
  }

  ::v-deep .el-collapse-item__content {
    padding: 20px;
    background-color: #fff;
  }
}

.warehouse-form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  background-color: #f8f9fb;
  border-radius: 8px;

  .el-form-item {
    margin-bottom: 0;

    ::v-deep .el-form-item__label {
      color: #606266;
      font-weight: 500;
    }

    ::v-deep .el-input__inner,
    ::v-deep .el-textarea__inner {
      border-color: #dcdfe6;

      &:focus {
        border-color: #409EFF;
      }
    }
  }
}

.el-button--text {
  font-size: 16px;
  margin: 0 3px;
  padding: 0;

  &:hover {
    color: #409EFF;
    background-color: transparent;
  }
}

@media print {
  /* 隐藏所有其他内容 */
  body * {
    visibility: hidden;
  }

  /* 只显示打印容器的内容 */
  #print-container,
  #print-container * {
    visibility: visible;
  }

  /* 设置打印容器位置 */
  #print-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

/* 条形码项样式 */
.barcode-item {
  text-align: center;
  padding: 20px;
  page-break-after: always;
}

.barcode-image {
  max-width: 90%;
  margin: 0 auto;
}


.barcode-item {
  break-after: page;
  padding: 20px;
  text-align: center;
}

.barcode-item img {
  max-width: 90%;
  height: auto;
}
</style>
