<template>
  <div class="export-excel">
    <!-- 修改这里，不再使用 download-excel 包裹按钮 -->
    <el-button
      type="success"
      :disabled="!selectedData.length"
      plain
      size="mini"
      icon="el-icon-folder-opened"
      @click="handleExport"
    >
      导出
    </el-button>

    <!-- 导出配置对话框 -->
    <el-dialog
      title="导出配置"
      :visible.sync="dialogVisible"
      width="800px"
      custom-class="export-dialog"
    >
      <el-transfer
        v-model="selectedColumns"
        :data="transferData"
        :titles="['可选字段', '已选字段']"
        :props="{
          key: 'key',
          label: 'label'
        }"
        filterable
        class="export-transfer"
      />

      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportToExcel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import ExcelJS from 'exceljs'

export default {
  name: 'ExportExcel',

  props: {
    // 选中的数据
    selectedData: {
      type: Array,
      required: true
    },
    // 默认显示的列
    defaultColumns: {
      type: Array,
      required: true
    },
    // 所有可选的列
    allColumns: {
      type: Array,
      required: true
    },
    // 存储key，用于保存用户的导出配置
    storageKey: {
      type: String,
      required: true
    },
    // 文件名前缀
    fileNamePrefix: {
      type: String,
      default: '导出数据'
    }
  },

  data() {
    return {
      selectedColumns: [],
      dialogVisible: false,
    }
  },

  computed: {
    fileName() {
      return `${this.fileNamePrefix}-${moment().format('YYYYMMDDHHmmss')}.xlsx`
    },

    transferData() {
      // 返回完整的数据结构，包含所有必需的属性
      return this.allColumns.map(col => ({
        key: col.prop,        // 用作唯一标识
        label: col.label,     // 显示的标签
        disabled: false       // 是否禁用
      }))
    },

    exportFields() {
      const fields = {}
      this.selectedColumns.forEach(prop => {
        const column = this.allColumns.find(col => col.prop === prop)
        if (column) {
          if (column.prop === 'CarrierBillCode') {
            fields[column.label] = {
              field: column.prop,
              callback: value => {
                if (value === null || value === undefined || value === '') {
                  return value
                }
                return `=@${value}`  // 使用 =@ 前缀强制文本格式
              }
            }
          } else {
            fields[column.label] = column.prop
          }
        }
      })
      return fields
    },

    processedData() {
      return this.selectedData.map(row => {
        const processedRow = { ...row }

        // 处理订单状态
        processedRow.OrderStatu = (() => {
          switch(row.OrderStatu) {
            case 1: return '未发货'
            case 2: return '待发货'
            case 3: return '已发货'
            default: return '未知状态'
          }
        })()

        // 处理是否取消
        processedRow.IsToPay = row.IsToPay ? '是' : '否'

        processedRow.IsCustomsClearance = row.IsCustomsClearance ? '是' : '否'

        processedRow.IsComplete = row.IsComplete ? '是' : '否'

        processedRow.IsCancel = row.IsCancel ? '是' : '否'

        // 处理付款状态
        processedRow.PayState = row.PayState ? '已付款' : '未付款'


        processedRow.PrintState = row.PrintState ? '已打印' : '未打印'
        // 处理库存状态
        processedRow.StockState = (() => {
          switch(row.StockState) {
            case 1: return '库存充足'
            case 2: return '缺货'
            case 3: return '部分缺货'
            case 4: return '未绑定库存'
            default: return '未知状态'
          }
        })()

        // 处理发货单号
        processedRow.CheckCodes = row.CheckCodes && row.CheckCodes.length ? row.CheckCodes.join(',') : ''

        // 处理下单时间
        processedRow.CreateTime = row.CreateTime ? moment(row.CreateTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.AuditTime = row.AuditTime ? moment(row.AuditTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.NewTasckNodeTime = row.NewTasckNodeTime ? moment(row.NewTasckNodeTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.LastGetTasckTime = row.LastGetTasckTime ? moment(row.LastGetTasckTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.PrintTime = row.PrintTime ? moment(row.PrintTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.PayTime = row.PayTime ? moment(row.PayTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.PickingTime = row.PickingTime ? moment(row.PickingTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.ReceiveGoodsTime = row.ReceiveGoodsTime ? moment(row.ReceiveGoodsTime).format('YYYY-MM-DD HH:mm:ss') : ''

        // 处理订单过期时间
        processedRow.OrderExpiredTime = row.OrderExpiredTime ? moment(row.OrderExpiredTime).format('YYYY-MM-DD HH:mm:ss') : ''

        processedRow.CheckTime = row.CheckTime ? moment(row.CheckTime).format('YYYY-MM-DD HH:mm:ss') : ''

        return processedRow
      })
    }
  },

  methods: {
    handleExport() {
      if (!this.selectedData.length) {
        this.$message.warning('请选择要导出的数据')
        return
      }
      // 打开对话框时初始化列配置
      this.initSelectedColumns()
      this.dialogVisible = true
    },

    initSelectedColumns() {
      try {
        const savedColumns = localStorage.getItem(`export_config_${this.storageKey}`)

        if (savedColumns) {
          const parsedColumns = JSON.parse(savedColumns)

          if (Array.isArray(parsedColumns) && parsedColumns.length > 0) {
            const validColumns = parsedColumns.filter(prop =>
              this.allColumns.some(col => col.prop === prop)
            )

            if (validColumns.length > 0) {
              this.selectedColumns = validColumns
              return
            }
          }
        }

        // 如果没有有效的保存配置，使用默认列
        if (this.defaultColumns && this.defaultColumns.length) {
          this.selectedColumns = this.defaultColumns.map(col => col.prop)
        }
      } catch (error) {
        if (this.defaultColumns && this.defaultColumns.length) {
          this.selectedColumns = this.defaultColumns.map(col => col.prop)
        }
      }
    },

    async exportToExcel() {
      try {
        localStorage.setItem(`export_config_${this.storageKey}`, JSON.stringify(this.selectedColumns))
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet1')

        // 设置表头
        const headers = this.selectedColumns.map(prop => {
          const column = this.allColumns.find(col => col.prop === prop)
          return column ? column.label : ''
        })
        worksheet.addRow(headers)

        // 添加数据
        const processedData = this.processedData
        processedData.forEach(row => {
          const rowData = this.selectedColumns.map(prop => row[prop] || '')
          worksheet.addRow(rowData)
        })

        // 设置列格式
        this.selectedColumns.forEach((prop, index) => {
          const col = worksheet.getColumn(index + 1)
          // 对特定列设置文本格式
          if (['CarrierBillCode'].includes(prop)) {
            col.numFmt = '@'  // 设置为文本格式
          }
        })

        // 生成二进制数据
        const buffer = await workbook.xlsx.writeBuffer()

        // 创建 Blob
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        // 创建下载链接并触发下载
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.fileName
        link.click()
        window.URL.revokeObjectURL(link.href)

        this.dialogVisible = false
        this.$message.success('导出成功')
      } catch (error) {
        console.error('导出失败:', error)
        this.$message.error('导出失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.export-excel {
  display: inline-block;

  .hidden-excel {
    display: none;
  }
}

:deep(.export-dialog) {
  .el-dialog__body {
    padding: 20px;
  }
}
::v-deep .el-transfer-panel {
  width: 280px;
}

:deep(.export-transfer) {
  .el-transfer {
    display: flex;
    align-items: center;

    // 面板样式
    .el-transfer-panel {
      flex: 1;
      width: calc(50% - 60px) !important;

      .el-transfer-panel__header {
        display: flex;
        align-items: center;
        height: 40px;
        background: #f5f7fa;
        margin: 0;
        padding: 0 15px;
        border-bottom: 1px solid #EBEEF5;

        .el-checkbox {
          display: flex;
          align-items: center;
        }
      }

      .el-transfer-panel__body {
        height: 500px;

        .el-transfer-panel__filter {
          margin: 15px;

          .el-input__inner {
            height: 32px;
          }
        }

        .el-transfer-panel__list {
          padding: 0;
          height: calc(100% - 80px);
        }
      }
    }

    // 中间按钮组样式
    .el-transfer__buttons {
      display: flex;
      flex-direction: column;
      padding: 0 30px;

      button {
        margin: 5px 0;
      }
    }
  }
}
</style>
