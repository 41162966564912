<template>
  <div class="container" :class="{ 'sticky': $route.name === 'index' }" :style="setBgColor()">
    <div class="logo-item" @click="backHome">
      <img :src="logoUrl" class="logo-image" alt="">
<!--      <div style="font-size: 32px; color: #4EA6E5; font-weight: 600">{{ shop_name }}</div>-->
    </div>
    <div class="right-panel">
      <transition name="fade-transform" mode="out-in">
        <el-menu
          :default-active="activeIndex"
          :text-color="'#666'"
          :active-text-color="'#4ea6e5'"
          router
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="/index">{{ $t('headers.index') }}</el-menu-item>
          <el-menu-item index="/message">{{ $t('headers.message') }}</el-menu-item>
          <el-menu-item index="/logistics-query">{{ $t('headers.query') }}</el-menu-item>
        </el-menu>
      </transition>
      <el-popover
        placement="bottom"
        width="200"
        trigger="click"
        v-model="showToolsBox"
      >
        <locale-and-currency
          @currency-changed="handleCurrencyChange"
          @close="showToolsBox = false"
        />
        <div slot="reference" class="tools-trigger">
          <div class="locale-icon">
            <img :src="require('@/static/images/layout/locale.png')" alt="locale" />
          </div>
          <span v-if="menuModal.exchange" class="currency-value">{{ currency.length ? `CNY 1 ≈ ${formatCurrency(showCurrency)}` : "" }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </el-popover>
      <div class="user-item" :style="setColor()">
        <div class="u-start-flex" v-if="isAuthenticated">
          <el-dropdown trigger="click" class="international">
            <personal class="mr8"></personal>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="$router.push({path: '/member'})" style="padding: 0 2rem">{{ $t('headers.member') }}</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="$router.push({path: '/member/account'})" style="padding: 0 2rem">{{ $t('layout.member.accountSettings') }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

<!--          <span @click="$router.push({path: '/member'})" class="pointer">{{ userInfo.Name }}</span>-->
<!--          <span style="margin: 0 1rem">|</span>-->
<!--          <span @click="$router.push({path: '/member'})" class="pointer">{{ $t('headers.member') }}</span>-->
<!--          <span style="margin: 0 1rem">|</span>-->
          <logout></logout>
<!--          <span class="pointer" @click="logout">{{ $t('headers.logout') }}</span>-->
        </div>
        <div class="auth-buttons" v-else>
          <el-button class="login-btn" type="text" @click="$router.push({path: '/login'})">
            {{ $t('headers.login') }}
          </el-button>
          <el-button class="register-btn" type="primary" round @click="$router.push({path: '/register'})">
            {{ $t('headers.register') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Personal from "@/components/icss/personal.vue";
import Logout from "@/components/icss/logout.vue";
import LocaleAndCurrency from '@/components/LocaleAndCurrency/index.vue'

export default {
  data() {
    return {
      activeIndex: "",
      selectedCurrency: null,
      showToolsBox: false
    }
  },
  components: {Personal, Logout, LocaleAndCurrency },
  computed: {
    textColor() {
      if (this.internal) {
        return '#FFFFFF'
      } else {
        return '#303133'
      }
    },
    activeTextColor() {
      if (this.internal) {
        return '#FFFFFF'
      } else {
        return ''
      }
    },
    logoPath() {
      if (this.internal) {
        return 'bg_logo.png'
      } else {
        return 'logo.png'
      }
    },
    backgroundColor() {
      if (this.internal) {
        return '#194D8F'
      } else {
        return ''
      }
    },
    ...mapGetters(['path', 'internal', 'isAuthenticated', 'userInfo', 'logoUrl', 'shop_name', 'currency', 'menuModal']),
    showCurrency() {
      if (this.selectedCurrency) {
        return this.selectedCurrency;
      }
      return this.currency.length ? this.currency[0] : {};
    }
  },
  methods: {
    handleSelect() {},
    backHome() {
      this.$router.push({ path: '/index' });
    },
    logout() {
      this.$store.dispatch('user/logout')
    },
    setBgColor() {
      return {
        backgroundColor: '#fff',
        height: this.internal ? '8rem' : '10rem',
        padding: this.internal ? '0 1%' : '0 10%'
      }
    },
    setColor() {
      return {
        color: this.internal ? '#ffffff' : '#303133'
      }
    },
    formatCurrency(currency) {
      if (!currency) return '';
      return `${currency.Code} ${currency.ExchangeRate}`;
    },
    selectCurrency(currency) {
      this.selectedCurrency = currency;
    },
    handleCurrencyChange(currency) {
      this.selectedCurrency = currency
    }
  },
  mounted() {
    this.activeIndex = this.path;
  },
  watch: {
    $route() {
      this.activeIndex = this.$route.path
    }
  }
}

</script>

<style lang="scss" scoped>
.el-menu-item:hover {
  background-color: transparent !important;
}
::v-deep .el-dropdown-menu__item, .el-menu-item {
  font-size: 1.8rem;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-menu--horizontal>.el-menu-item {
  margin: 0 3rem !important;
}
::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background .3s;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }
}
.container {
  padding: 0 10%;
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  transition: all 0.3s ease;
  background: #fff;

  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .logo-item {
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.85;
    }

    .logo-image {
      height: 4.5rem;
      object-fit: cover;
      margin-right: 1.5rem;
    }
  }

  .right-panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;

    .user-item {
      font-size: 1.6rem;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(64, 158, 255, 0.1);
      }
    }
  }
}

::v-deep .el-menu--horizontal {
  background: transparent;

  > .el-menu-item {
    height: 8rem;
    line-height: 8rem;
    margin: 0 2.5rem !important;
    font-size: 1.8rem;
    font-weight: 700;
    padding: 0;
    position: relative;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      color: rgba(78, 166, 229, 0.8) !important;
      background-color: transparent;
    }

    &.is-active {
      color: #4ea6e5;
      font-weight: 700;
    }
  }
}

.fade-transform-enter-active,
.fade-transform-leave-active {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-transform-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

::v-deep .el-dropdown-menu {
  padding: 6px 0;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

  .el-dropdown-menu__item {
    padding: 12px 24px;
    font-size: 1.4rem;
    line-height: 1.4;
    color: #606266;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f5f7fa;
      color: #409EFF;
    }

    &.is-disabled {
      color: #c0c4cc;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
        color: #c0c4cc;
      }
    }
  }
}

.currency-dropdown {
  .currency-trigger {
    padding: 0.8rem 1.2rem;
    border-radius: 6px;
    background: rgba(64, 158, 255, 0.05);
    transition: all 0.3s ease;

    &:hover {
      background: rgba(64, 158, 255, 0.1);
    }

    .currency-value {
      font-weight: 500;
    }
  }
}

::v-deep .el-dropdown.is-active .el-icon-arrow-down {
  transform: rotate(180deg);
}

.tools-trigger {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 20px;
  background: rgba(78, 166, 229, 0.1);
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1.8rem;

  &:hover {
    background: rgba(78, 166, 229, 0.2);
  }

  .locale-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #4ea6e5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }

  .currency-value {
    margin-right: 8px;
    font-weight: 500;
    color: #4ea6e5;
  }

  .el-icon--right {
    color: #4ea6e5;
  }
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 8px;

  .login-btn {
    font-size: 1.6rem;
    color: #666;
    padding: 8px 16px;

    &:hover {
      color: #4ea6e5;
    }
  }

  .register-btn {
    font-size: 1.6rem;
    background: #4ea6e5;
    border-color: #4ea6e5;
    padding: 8px 20px;

    &:hover {
      background: rgba(78, 166, 229, 0.8);
      border-color: rgba(78, 166, 229, 0.8);
    }
  }
}
</style>
