<template>
  <div class="layout">
    <div class="navigation">
      <div class="navigation-logo">
        <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
        <div class="navigation-title">{{ $t('rich.title') }}</div>
      </div>
      
      <div v-if="loading" class="menu-skeleton">
        <div class="skeleton-item" v-for="i in 6" :key="i">
          <el-skeleton-item variant="text" style="width: 80%"/>
        </div>
      </div>
      
      <el-menu
          v-else
          :default-active="activeMenu"
          active-text-color="#194D8F"
          router
          class="el-menu-vertical-demo">
        <el-menu-item :index="item.index" v-for="item in menuItems" :key="item.id">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    
    <div v-if="loading" class="operate content-skeleton">
      <div class="skeleton-header">
        <el-skeleton-item variant="text" style="width: 40%"/>
      </div>
      <div class="skeleton-content">
        <el-skeleton-item v-for="i in 4" :key="i" variant="text" style="width: 100%; margin-bottom: 1rem"/>
        <el-skeleton-item variant="text" style="width: 60%"/>
      </div>
    </div>
    
    <div v-else-if="menuItems.length" class="operate">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['help']),
    activeMenu() {
      const route = this.$route
      const { path, query } = route
      return query.id ? `${path}?id=${query.id}` : path
    },
    menuItems() {
      return this.help.map(item => {
        return {
          id: item.Id,
          title: item.Title,
          index: `/help/rich?id=${item.Id}`
        }
      })
    }
  },
  components: {
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('webSite/getShopMatters', 2)
        .then(response => {
          this.$store.commit('webSite/SET_HELP_DATA', response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}

</script>

<style scoped lang="scss">
.layout {
  width: 90%;
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  gap: 2rem;
  
  .navigation {
    width: 25%;
    min-width: 240px;
    
    .navigation-logo {
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: #fff;
      padding: 2rem;
      border-radius: 0.8rem;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
      margin-bottom: 1rem;
      
      .navigation-title {
        font-size: 2rem;
        font-weight: 600;
        color: #194D8F;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    :deep(.el-menu) {
      border-radius: 0.8rem;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
      
      .el-menu-item {
        height: 5rem;
        line-height: 5rem;
        
        &:hover {
          background-color: #f0f5ff;
        }
        
        &.is-active {
          background-color: #e6f0ff;
          border-right: none;
          border-left: 3px solid #194D8F;
          padding-left: 17px;
        }
      }
    }
  }
  
  .operate {
    flex: 1;
    background-color: #fff;
    min-height: calc(100vh - 39rem);
    border-radius: 0.8rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  }
  
  .menu-skeleton {
    background: #fff;
    border-radius: 0.8rem;
    padding: 1rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    
    .skeleton-item {
      padding: 1.4rem 1rem;
      border-radius: 0.4rem;
      
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      
      :deep(.el-skeleton__text) {
        height: 2rem;
      }
    }
  }
  
  .content-skeleton {
    padding: 2.5rem 2rem;
    
    .skeleton-header {
      padding-bottom: 1.5rem;
      margin-bottom: 2rem;
      border-bottom: 2px solid #e6e6e6;
      
      :deep(.el-skeleton__text) {
        height: 2.4rem;
      }
    }
    
    .skeleton-content {
      padding: 2rem;
      background: #F7F8FA;
      border-radius: 0.8rem;
      
      :deep(.el-skeleton__text) {
        height: 1.6rem;
      }
    }
  }
  
  :deep(.el-skeleton__item) {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media screen and (max-width: 768px) {
  .layout {
    width: 95%;
    flex-direction: column;
    
    .navigation {
      width: 100%;
      margin-bottom: 2rem;
    }
    
    .operate {
      width: 100%;
    }
  }
}
</style>
