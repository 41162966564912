<template>
  <div>
    <div class="back" @click="back">
      <el-icon class="el-icon-arrow-left"></el-icon>
    </div>
    <div v-if="methods === 'offline'" class="offline-pay-container">
      <!-- 银行账户信息和收款码区域 -->
      <div class="payment-info-section">
        <!-- 银行账户信息 -->
        <div class="left-section">
          <template v-if="hasAccountInfo">
            <h3 class="section-title">{{ $t('QrCodePay.accountInfo') }}</h3>
            <div class="info-list">
              <div class="info-item" v-if="payTypeData.BankDeposit">
                <div class="label">{{ $t('QrCodePay.bankDeposit') }}</div>
                <div class="value-wrapper">
                  <span class="value">{{ payTypeData.BankDeposit }}</span>
                  <i class="el-icon-document-copy" @click="copyText(payTypeData.BankDeposit)"></i>
                </div>
              </div>
              <div class="info-item" v-if="payTypeData.OpeningName">
                <div class="label">{{ $t('QrCodePay.openingName') }}</div>
                <div class="value-wrapper">
                  <span class="value">{{ payTypeData.OpeningName }}</span>
                  <i class="el-icon-document-copy" @click="copyText(payTypeData.OpeningName)"></i>
                </div>
              </div>
              <div class="info-item" v-if="payTypeData.Account">
                <div class="label">{{ $t('QrCodePay.account') }}</div>
                <div class="value-wrapper">
                  <span class="value">{{ payTypeData.Account }}</span>
                  <i class="el-icon-document-copy" @click="copyText(payTypeData.Account)"></i>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="no-data">{{ $t('QrCodePay.noAccountInfo') }}</div>
        </div>
        
        <!-- 收款码 -->
        <div class="right-section">
          <template v-if="payTypeData.ImageHostURL">
            <h3 class="section-title">{{ $t('QrCodePay.scanToPay') }}</h3>
            <div class="qrcode-container">
              <el-image 
                :src="payTypeData.ImageHostURL" 
                class="qrcode-img"
                :preview-src-list="[payTypeData.ImageHostURL]">
              </el-image>
            </div>
          </template>
          <div v-else class="no-data">{{ $t('QrCodePay.noQrCode') }}</div>
        </div>
      </div>

      <!-- 支付凭证上传区 -->
      <div class="payment-proof-section">
        <h3 class="section-title">{{ $t('QrCodePay.uploadProof') }}</h3>
        <div class="proof-form">
          <el-form :model="form" label-position="top" class="form-container">
            <el-form-item :label="$t('QrCodePay.transactionNo')" required>
              <el-input 
                v-model="form.TransactionNo"
                :placeholder="$t('QrCodePay.placeholderTransactionNo')"
                size="large">
              </el-input>
            </el-form-item>
            
            <el-form-item :label="$t('QrCodePay.voucherTitle')" required>
              <el-upload
                class="voucher-uploader"
                :show-file-list="false"
                :http-request="progressAvatar"
                :before-upload="beforeAvatarUpload"
                action="#">
                <div class="upload-area" v-if="!voucherUrl">
                  <i class="el-icon-plus"></i>
                  <div class="upload-text">{{ $t('QrCodePay.uploadVoucherTip') }}</div>
                  <div class="upload-hint">{{ $t('QrCodePay.imageSizeLimit') }}</div>
                </div>
                <img v-else :src="voucherUrl" class="voucher-preview">
              </el-upload>
            </el-form-item>

            <el-form-item :label="$t('QrCodePay.rem')">
              <el-input
                v-model="form.Rem"
                type="textarea"
                :rows="3"
                :placeholder="$t('QrCodePay.remarkPlaceholder')">
              </el-input>
            </el-form-item>
          </el-form>

          <el-button 
            type="primary" 
            class="submit-btn" 
            :loading="loading"
            @click="onSubmit">
            {{ $t('QrCodePay.onSubmit') }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- 在线支付部分保持不变 -->
    <div v-else class="pay-box">
      <div class="describe" v-if="methods === 'onLine'">
        <p>{{ $t('QrCodePay.paymentAmount') }}：{{ order.Receivables }}</p>
        <p>{{ $t('QrCodePay.orderNumber') }}：{{ order.MainBillCode }}</p>
        <p>{{ $t('QrCodePay.orderDescription') }}：{{ describe }}</p>
      </div>
      <div class="qrcode" v-if="methods === 'onLine'">
        <canvas id="qrcodePayCanvas" ref="qrcodePayCanvas"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import {QrCodePayState, uploadImage} from "@/api/system";
import QRCode from "qrcode";
import { offlineRecharge } from "@/api/user";

export default {
  props: {
    order: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    methods: {
      type: String,
      default: "onLine"
    },
    payTypeData: {
      type: Object,
      default: () => {}
    },
    describe: {
      type: String,
      default: ""
    },
    orderType: {
      type: String,
      default: "goods"
    },
    qrCodeUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      payState: false,
      interval: undefined,
      voucherUrl: "",
      file: "",
      loading: false,
      form: {
        TransactionNo: ""
      }
    }
  },
  computed: {
    hasAccountInfo() {
      return this.payTypeData.BankDeposit ||
          this.payTypeData.OpeningName ||
          this.payTypeData.Account;
    }
  },
  watch: {
    qrCodeUrl(newVal) {
      if (newVal && this.methods === 'onLine') {
        this.generate()
      }
    }
  },
  created() {
    if(this.qrCodeUrl && this.methods === 'onLine') {
      this.generate()
    }
  },
  methods: {
    clearIntervalToParent() {
      clearInterval(this.interval);
    },
    onSubmit() {
      if (!this.form.TransactionNo) {
        this.$message.warning(this.$t('QrCodePay.placeholderTransactionNo'))
        return;
      }
      if (!this.file) {
        this.$message.warning(this.$t('QrCodePay.placeholderOffline'))
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('Path', 'WithdrawalImg');
      this.loading = true
      uploadImage(formData).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          const data = {
            VoucherImage: Data.ServerPath,
            Money: this.payTypeData.Money,
            TransactionNo: this.form.TransactionNo,
            Rem: this.form.Rem,
            OrderNo: this.order.MainBillCode,
            Type: 1,
            SourceType: this.orderType === 'recharge' ? 1 : 6,
            PayTypeId: this.payTypeData.payTypeId
          }
          offlineRecharge(data).then(response => {
            this.loading = false
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message.success(this.$t('QrCodePay.successMsg'))
              this.$emit('close')
            } else {
              this.$message.warning(Msg)
            }
          }).catch(() => { this.loading = false })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    progressAvatar() {
      return false;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t('common.imageValidate'));
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.voucherUrl = e.target.result;
      };
      this.file = file
      return isLt2M;
    },
    generate() {
      setTimeout(() => {
        this.generateQRCode()
        this.interval =  setInterval(this.checkPaymentStatus, 3000);
      }, 500)
    },
    back() {
      clearInterval(this.interval);
      this.$emit('back')
    },
    checkPaymentStatus() {
      const data = {
        OrderNo: this.order.MainBillCode
      }
      QrCodePayState(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200 && Data) {
          this.payState = true
          if (this.orderType === 'order') {
            this.$emit('close', 'refresh')
          } else {
            this.$emit('close')
          }
        } else {
          console.log(Msg)
        }
      })
    },
    generateQRCode() {
      const canvas = this.$refs.qrcodePayCanvas
      QRCode.toCanvas(canvas, this.qrCodeUrl, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log('QR code generated successfully')
        }
      })
    },
    payStateHandle() {
      if (this.orderType === 'goods') {
        setTimeout(() => {
          this.$router.push({
            path: '/member/order-domestic'
          })
        }, 500)
      }
      if (this.payState) {
        this.$message.success(this.$t('mergerCard.paySuccess') || "")
      } else {
        if (this.orderType === 'recharge') return;
        this.$message.info(this.$t('mergerCard.payInfo') || "")
      }
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success(this.$t('common.copySuccess'))
      }).catch(() => {
        this.$message.error(this.$t('common.copyFailed'))
      })
    },
  }
}

</script>

<style scoped lang="scss">
.back {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  border: 1px solid #EAEDF5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem 2rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #F8F9FA;
    border-color: #1182FB;
    color: #1182FB;
    transform: translateX(-2px);
  }
}

.pay-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  .describe {
    width: 100%;
    max-width: 480px;
    padding: 1.6rem;
    background: #F8F9FA;
    border-radius: 0.8rem;

    p {
      margin: 0.8rem 0;
      color: #454C5B;
      line-height: 1.6;
    }
  }

  .qrcode {
    padding: 2.4rem;
    background: #FFFFFF;
    border: 1px solid #EAEDF5;
    border-radius: 1.2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  }

  .feedback {
    width: 100%;
    max-width: 360px;

    .upload-hint {
      text-align: center;
      color: #8C8C8C;
      margin-bottom: 1.6rem;
    }
  }
}

.avatar-uploader {
  .avatar-uploader-icon {
    width: 200px;
    height: 200px;
    line-height: 200px;
    font-size: 2.4rem;
    color: #8C8C8C;
    border: 2px dashed #EAEDF5;
    border-radius: 0.8rem;
    transition: all 0.3s ease;

    &:hover {
      border-color: #1182FB;
      color: #1182FB;
    }
  }

  .avatar {
    width: 200px;
    height: 200px;
    border-radius: 0.8rem;
    object-fit: cover;
  }
}

.offline-pay-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.payment-info-section {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  
  .left-section {
    flex: 1;
    min-width: 0;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);

    .section-title {
      font-size: 15px;
      color: #333;
      margin-bottom: 12px;
      font-weight: 500;
    }

    .info-list {
      .info-item {
        padding: 10px 0;
        border-bottom: 1px solid #EAEDF5;

        &:last-child {
          border-bottom: none;
        }

        & + .info-item {
          margin-top: 4px;
        }

        .label {
          color: #909399;
          font-size: 13px;
          margin-bottom: 4px;
          line-height: 1.3;
        }

        .value-wrapper {
          display: flex;
          align-items: center;
          gap: 4px;

          .value {
            flex: 1;
            color: #303133;
            font-size: 14px;
            line-height: 1.4;
          }

          .el-icon-document-copy {
            color: #909399;
            cursor: pointer;
            font-size: 15px;
            padding: 3px;
            transition: all 0.2s ease;
            
            &:hover {
              color: #409EFF;
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .no-data {
      color: #909399;
      text-align: center;
      padding: 30px 0;
    }
  }
  
  .right-section {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-title {
      align-self: flex-start;
      margin-bottom: 16px;
    }

    .qrcode-container {
      width: 160px;
      height: 160px;
      padding: 20px;
      background: #F8F9FA;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .qrcode-img {
        width: 120px;
        height: 120px;
        cursor: pointer;
        object-fit: contain;
        
        &:hover {
          transform: scale(1.02);
          transition: transform 0.3s ease;
        }
      }
    }

    .no-data {
      height: 196px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #909399;
      font-size: 14px;
      width: 100%;
    }
  }
}

.qrcode-section {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);

  .section-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .qrcode-wrapper {
    padding: 20px;
    display: inline-block;
    background: #F8F9FA;
    border-radius: 8px;

    .qrcode-img {
      max-width: 240px;
      max-height: 240px;
      cursor: pointer;
      
      &:hover {
        transform: scale(1.02);
        transition: transform 0.3s ease;
      }
    }
  }
}

.payment-proof-section {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);

  .section-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .proof-form {
    max-width: 500px;
    margin: 0 auto;

    .form-container {
      :deep(.el-form-item) {
        margin-bottom: 24px;
        
        .el-input,
        .el-textarea {
          width: 100%;
        }
        
        .voucher-uploader {
          width: 100%;
          display: flex;
          justify-content: center;

          .upload-area {
            width: 240px;
            height: 240px;
            border: 2px dashed #DCDFE6;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all 0.3s;

            &:hover {
              border-color: #409EFF;
              color: #409EFF;
            }

            i {
              font-size: 32px;
              color: #8C8C8C;
              margin-bottom: 8px;
            }

            .upload-text {
              font-size: 14px;
              color: #606266;
              margin-bottom: 4px;
            }

            .upload-hint {
              font-size: 12px;
              color: #909399;
            }
          }

          .voucher-preview {
            width: 240px;
            height: 240px;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .submit-btn {
    width: 100%;
    margin-top: 24px;
    height: 44px;
    font-size: 16px;
  }
}

.empty-block {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .payment-info-section {
    flex-direction: column;
    
    .right-section {
      width: 100%;
      
      .qrcode-container {
        margin: 0 auto;
      }
    }
  }
}
</style>
