<template>
  <div ref="searchMore" class="search-more">
    <div class="search-more-content">
      <el-form :inline="true" size="mini">
        <el-form-item
          v-for="item in searchOptions"
          :key="item.field"
          :label-width="item.labelWidth || '80px'"
          :label="item.label"
        >
          <el-input
            v-model="searchForm[item.field]"
            :placeholder="item.placeholder"
            clearable
            @clear="handleSearch"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvancedSearch',
  props: {
    queryForm: {
      type: Object,
      default: () => ({})
    },
    searchOptions: {
      type: Array,
      default: () => [
        {
          field: 'ReceiveName',
          label: '收件人',
          labelWidth: '60px',
          placeholder: '请输入收件人姓名'
        },
        {
          field: 'ReceivePhone',
          label: '收件人手机',
          labelWidth: '80px',
          placeholder: '请输入收件人手机号'
        },
        {
          field: 'ClientRem',
          label: '客户备注',
          labelWidth: '50px',
          placeholder: '请输入备注信息'
        },
        {
          field: 'Rem1',
          label: '备注1',
          labelWidth: '50px',
          placeholder: '请输入备注信息'
        },
        {
          field: 'Rem2',
          label: '备注2',
          labelWidth: '50px',
          placeholder: '请输入备注信息'
        },
        {
          field: 'Rem3',
          label: '备注3',
          labelWidth: '50px',
          placeholder: '请输入备注信息'
        },
        {
          field: 'Rem4',
          label: '备注4',
          labelWidth: '50px',
          placeholder: '请输入备注信息'
        }
      ]
    }
  },
  computed: {
    searchForm: {
      get() {
        if (!this.queryForm) return {}
        return this.queryForm
      },
      set(val) {
        Object.assign(this.queryForm, val)
        this.$emit('update:queryForm', this.queryForm)
      }
    }
  },
  mounted() {
    this.initSearchForm()
  },
  methods: {
    initSearchForm() {
      const initialForm = this.searchOptions.reduce((acc, option) => {
        acc[option.field] = ''
        return acc
      }, {})
      this.$emit('update:queryForm', initialForm)
    },
    handleSearch() {
      this.$emit('search', this.queryForm)
    },
    reset() {
      this.initSearchForm()
      this.handleSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-more {
  width: 100%;
  position: relative;

  .search-more-content {
    padding: 0;

    .el-form {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
      padding: 0;

      .el-form-item {
        margin: 0;
        display: flex;
        align-items: center;
        width: calc((100% - 36px) / 4);
        min-width: 260px;

        &:last-child {
          margin-bottom: 0;
        }

        ::v-deep .el-form-item__label {
          font-size: 13px;
          color: #606266;
          padding-right: 8px;
          width: 75px !important;
          line-height: 28px;
          text-align: right;
        }

        .el-input {
          flex: 1;
          max-width: 180px;

          ::v-deep .el-input__inner {
            height: 28px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>
