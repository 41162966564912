<template>
  <div class="inventory-container">
    <div class="inventory-title">商品库存管理</div>
    <div class="query-section">
      <div class="query-content">
        <search-input
            v-model="query.SKUCode"
            :type.sync="queryType"
            :options="queryOptions"
            :placeholder="'请输入搜索内容，多个内容用逗号或换行符分隔'"
            @clear="clearQuery"
            @search="getData"
            class="search-input"
        />
        <el-badge :hidden="!badgeNum" :value="badgeNum" class="filter-badge">
          <el-button type="default" size="mini" icon="el-icon-s-operation" @click="showFilter">筛选</el-button>
        </el-badge>
        <el-button type="warning" plain size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
      </div>
    </div>
    <div class="table-section">
      <table-copy ref="copyTable" table-selector=".el-table__body-wrapper" :has-selection="false">
        <el-table
            :data="listData"
            :height="tableHeight"
            :max-height="tableHeight"
            size="mini"
            border
            style="width: 100%"
            v-loading="loading"
            element-loading-text="获取数据中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <el-table-column label="仓库" prop="WareHouseName" align="center"></el-table-column>
          <el-table-column label="SKU编码" prop="SKUCode" align="center"></el-table-column>
          <el-table-column label="图片" prop="ImagePath" align="center" width="60px">
            <template slot-scope="scope">
              <el-image class="sku-img" :preview-src-list="[scope.row.ImagePath]" :src="scope.row.ImagePath" v-if="scope.row.ImagePath"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="名称" prop="SKUName" align="center"></el-table-column>
          <el-table-column label="尺码" prop="Size" align="center"></el-table-column>
          <el-table-column label="型号" prop="ModelNumber" align="center"></el-table-column>
          <el-table-column label="规格" prop="Specification" align="center"></el-table-column>
          <el-table-column label="库存" prop="Quantity" align="center"></el-table-column>
        </el-table>
      </table-copy>
      <el-pagination
          class="mt20"
          background
          @size-change="handleSizeChange"
          @current-change="handleChange"
          :current-page="PageIndex"
          :page-sizes="PageSizes"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <filter-drawer :list-data="drawerListData" @handle-confirm="handleConfirm" @close="handleDrawerClose" :drawer="drawer" :initial-query="drawerQuery"></filter-drawer>
  </div>
</template>

<script>
import { getCodSKUDetail } from "@/api/cod";
import SearchInput from "@/components/SearchInput/index.vue";
import FilterDrawer from "@/components/FilterDrawer/index.vue";
import TableCopy from "@/components/TableCopy/index.vue";
import pagination from "@/utils/mixin/pagination";
import FilterDrawers from "@/utils/mixin/filterDrawers";

export default {
  mixins: [ pagination, FilterDrawers ],
  components: {TableCopy, FilterDrawer, SearchInput},
  data() {
    return {
      wareHouseOption: [],
      query: {
      },
      listData: [],
      timeTypeOptions: [],
      loading: false,
      queryType: 14,
      queryOptions: [
        { label: 'SKU编号', value: 14 },
      ],
    }
  },
  computed: {
    tableHeight() {
      return 'calc(100vh - 25.6rem - 61px)'
    }
  },
  created() {
    this.getWareHouse()
    this.getData()
  },
  methods: {
    resetForm() {
      this.drawerQuery = {
        dateRange: []
      }
      this.clearQuery()
    },
    clearQuery() {
      this.query.SKUCode = ""
      this.PageIndex = 1
      this.getData()
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.drawerListData.push({
          title: "仓库",
          field: "WareHouseId",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
    },
    getData() {
      let QueryCodes = this.query.SKUCode ?
          this.query.SKUCode.split(/[,，\s\r\n\t]+/).filter(item => item.trim()) :
          undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        QueryCodeType: this.queryType,
        QueryCodes: QueryCodes,
        WareHouseId: this.drawerQuery.WareHouseId || undefined
      }
      this.loading = true
      getCodSKUDetail(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.sku-img {
  width: 36px;
  height: 36px;
  border-radius: 5px;
}
.inventory-container {
  padding: 2rem;
  background-color: #f5f7fa;
  .inventory-title {
    padding: 1.5rem 2.5rem;
    height: 1.6rem;
    color: #225493;
    background-color: #fff;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 4px;
      height: 16px;
      background-color: #409EFF;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
  .query-section {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

    .query-content {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .search-input {
        width: 400px;
      }

      .filter-badge {
        margin-left: 0.5rem;
      }

      :deep(.el-button--mini) {
        font-size: 12px;
        padding: 7px 12px;
        height: 28px;
        line-height: 1;
      }
    }
  }
  .table-section {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

    :deep(.el-table) {
      border-radius: 4px;

      th {
        background-color: #f5f7fa;
        color: #606266;
      }
    }

    .pagination {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  :deep(.operation-icon) {
    font-size: 16px;
    color: #409EFF;

    &:hover {
      color: #66b1ff;
    }
  }

  :deep(.el-button--text) {
    padding: 0 8px;
  }
}
</style>
