<template>
  <div class="order-container">
    <div class="queryItems mb10">
      <search-input
          v-model="queryCode"
          :type.sync="queryType"
          :options="queryOptions"
          :placeholder="'请输入搜索内容，多个内容用逗号或换行符分隔'"
          @clear="clearQuery"
          @search="getData"
      />
      <el-button v-if="false" type="primary" size="mini" icon="el-icon-search" @click="getData">{{ $t('domestic.search') }}</el-button>
      <el-badge :hidden="!badgeNum" :value="badgeNum" class="filter-badge">
        <el-button type="default" size="mini" icon="el-icon-s-operation" @click="showFilter">筛选</el-button>
      </el-badge>
      <el-button type="warning" plain size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
      <el-button 
        type="primary" 
        plain 
        size="mini" 
        icon="el-icon-upload2" 
        @click="exportToExcel"
      >{{ $t('order.export') }}</el-button>
    </div>
    <table-copy ref="copyTable" table-selector=".el-table__body-wrapper" :has-selection="false">
      <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          :max-height="tableHeight"
          v-loading="loading"
      >
        <el-table-column label="编号" prop="Id"></el-table-column>
        <el-table-column label="订单编号" prop="OrderId"></el-table-column>
        <el-table-column width="160" label="退货订单号" prop="MainBillCode"></el-table-column>
        <el-table-column width="160" label="重出订单号" prop="NewMainBillCode"></el-table-column>
        <el-table-column width="160" label="退货单号" prop="ReturnCode"></el-table-column>
        <el-table-column width="160" label="退货承运单号" prop="CarrierBillCode"></el-table-column>
        <el-table-column width="160" label="重出承运单号" prop="NewCarrierBillCode"></el-table-column>
        <el-table-column label="目的地" prop="DestinationName"></el-table-column>
        <el-table-column label="货物类型" prop="GoodsTypeName"></el-table-column>
        <el-table-column label="时效" prop="TimerName"></el-table-column>
        <el-table-column label="承运商" prop="CarrierName"></el-table-column>
        <el-table-column label="品名" prop="GoodsName"></el-table-column>
        <el-table-column label="备注" prop="ClientRem"></el-table-column>
        <el-table-column width="150" label="下单时间" prop="CreateTime">
          <template slot-scope="scope">{{ scope.row.CreateTime | formatDate }}</template>
        </el-table-column>
        <el-table-column label="入库人" prop="StorageUser"></el-table-column>
        <el-table-column width="150" label="入库时间" prop="StorageTime">
          <template slot-scope="scope">{{ scope.row.StorageTime | formatDate }}</template>
        </el-table-column>
        <el-table-column width="150" label="上架时间" prop="ShelvesTime">
          <template slot-scope="scope">{{ scope.row.ShelvesTime | formatDate }}</template>
        </el-table-column>
        <el-table-column width="150" label="下架时间" prop="DownShelfTime">
          <template slot-scope="scope">{{ scope.row.DownShelfTime | formatDate }}</template>
        </el-table-column>
        <el-table-column width="120" label="重出订单编号" prop="NewOrderId"></el-table-column>
        <el-table-column width="150" label="重出时间" prop="ReappearTime">
          <template slot-scope="scope">{{ scope.row.ReappearTime | formatDate }}</template>
        </el-table-column>
        <el-table-column width="150" label="销毁时间" prop="DestructionTime">
          <template slot-scope="scope">{{ scope.row.DestructionTime | formatDate }}</template>
        </el-table-column>
        <el-table-column label="在仓天数" prop="DaysStorage"></el-table-column>
        <el-table-column label="仓库备注" prop="WarehouseRem"></el-table-column>
      </el-table>
      <el-pagination
          class="mt20"
          background
          @size-change="handleSizeChange"
          @current-change="handleChange"
          :current-page="PageIndex"
          :page-sizes="PageSizes"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </table-copy>
    <filter-drawer
      :list-data="drawerListData"
      :time-options="timeTypeOptions"
      @handle-confirm="handleConfirm"
      @close="handleDrawerClose"
      :drawer="drawer"
      :initial-query="drawerQuery">
    </filter-drawer>
  </div>
</template>

<script>
import {getReturnOrderList} from "@/api/member";
import FilterDrawer from "@/components/FilterDrawer/index.vue";
import TableCopy from '@/components/TableCopy'
import SearchInput from '@/components/SearchInput'
import pagination from "@/utils/mixin/pagination";
import FilterDrawers from "@/utils/mixin/filterDrawers";
import moment from 'moment'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  mixins: [ pagination, FilterDrawers ],
  components: { FilterDrawer, TableCopy, SearchInput },
  data() {
    return {
      queryCode: '',
      queryType: 3,
      queryOptions: [
        { label: '订单编号', value: 2 },
        { label: '退货订单号', value: 3 },
        { label: '退货承运单号', value: 4 },
        { label: '重出订单编号', value: 20 },
        { label: '重出承运单号', value: 21 },
      ],
      drawerQuery: {
        dateRange: [],
        timeType: 18
      },
      loading: false,
      tableData: [],
      timeTypeOptions: [
        { label: '入库时间', value: 18 },
        { label: '上架时间', value: 19 },
        { label: '下架时间', value: 21 },
        { label: '销毁时间', value: 23 },
        { label: '重出时间', value: 28 },
      ]
    }
  },
  created() {
    this.initDrawerListData()
    this.getData().then(() => {
      this.$nextTick(() => {
        this.$refs.copyTable.destroySelection()
        setTimeout(() => {
          this.$refs.copyTable.initSelection()
        }, 100)
      })
    })
  },
  computed: {
    fields() {
      return {
        '编号': 'Id',
        '订单编号': 'OrderId',
        '退货订单号': 'MainBillCode',
        '重出订单号': 'NewMainBillCode',
        '退货单号': 'ReturnCode',
        '退货承运单号': 'CarrierBillCode',
        '重出承运单号': 'NewCarrierBillCode',
        '目的地': 'DestinationName',
        '货物类型': 'GoodsTypeName',
        '时效': 'TimerName',
        '承运商': 'CarrierName',
        '品名': 'GoodsName',
        '备注': 'ClientRem',
        '下单时间': {
          field: 'CreateTime',
          callback: (value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        '入库人': 'StorageUser',
        '入库时间': {
          field: 'StorageTime',
          callback: (value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        '上架时间': {
          field: 'ShelvesTime',
          callback: (value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        '下架时间': {
          field: 'DownShelfTime',
          callback: (value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        '重出订单编号': 'NewOrderId',
        '重出时间': {
          field: 'ReappearTime',
          callback: (value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        '销毁时间': {
          field: 'DestructionTime',
          callback: (value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        '在仓天数': 'DaysStorage',
        '仓库备注': 'WarehouseRem'
      }
    },
    timestampName() {
      const timestamp = Date.now()
      return `${this.$t('order.exportOrder')}${timestamp}.xls`
    },
    tableHeight() {
      return 'calc(100vh - 17.2rem - 58px)'
    },
  },
  methods: {
    initDrawerListData() {
      this.drawerListData.push({
        title: "是否上架",
        field: "IsShelves",
        options: [
          { label: "不限", value: 0 },
          { label: "是", value: 1 },
          { label: "否", value: 2 }
        ]
      })
      this.drawerListData.push({
        title: "是否下架",
        field: "IsDownShelf",
        options: [
          { label: "不限", value: 0 },
          { label: "是", value: 1 },
          { label: "否", value: 2 }
        ]
      })
      this.drawerListData.push({
        title: "是否销毁",
        field: "IsDestruction",
        options: [
          { label: "不限", value: 0 },
          { label: "是", value: 1 },
          { label: "否", value: 2 }
        ]
      })
      this.drawerListData.push({
        title: "是否重出",
        field: "IsReappear",
        options: [
          { label: "不限", value: 0 },
          { label: "是", value: 1 },
          { label: "否", value: 2 }
        ]
      })
    },
    clearQuery() {
      this.queryCode = ""
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.queryCode = ""
      this.drawerQuery = {
        dateRange: []
      }
      this.clearQuery()
    },
    getData() {
      // 使用正则表达式分割输入内容为数组
      let QueryCodes = this.queryCode ?
          this.queryCode.split(/[,，\s\r\n\t]+/).filter(item => item.trim()) :
          undefined

      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        QueryCodeType: this.queryType,
        QueryCodes: QueryCodes,
        StartTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[0] : undefined,
        EndTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[1] : undefined,
        WareHouseId: this.drawerQuery.warehouse || undefined,
        QueryType: this.drawerQuery.timeType,
        IsShelves: this.drawerQuery.IsShelves || undefined,
        IsDownShelf: this.drawerQuery.IsDownShelf || undefined,
        IsDestruction: this.drawerQuery.IsDestruction || undefined,
        IsReappear: this.drawerQuery.IsReappear || undefined,
      }
      this.loading = true
      return getReturnOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.tableData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    async exportToExcel() {
      try {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet1')

        // 设置表头
        const headers = Object.keys(this.fields)
        worksheet.addRow(headers)

        // 设置所有列为文本格式
        headers.forEach((header, index) => {
          const column = worksheet.getColumn(index + 1)
          column.width = 15
          // 设置列格式为文本
          column.numFmt = '@'
          // 设置对齐方式
          column.alignment = { vertical: 'middle', horizontal: 'left' }
        })

        // 添加数据
        this.tableData.forEach(row => {
          const rowData = headers.map(header => {
            const field = this.fields[header]
            let value
            if (typeof field === 'object') {
              // 处理需要格式化的字段
              value = row[field.field]
              value = field.callback(value)
            } else {
              // 直接返回字段值
              value = row[field] || ''
            }
            // 确保所有值都转换为字符串
            return String(value).trim()
          })
          
          const excelRow = worksheet.addRow(rowData)
          // 为每个单元格设置文本格式
          excelRow.eachCell((cell) => {
            cell.numFmt = '@'
            cell.alignment = { vertical: 'middle', horizontal: 'left' }
          })
        })

        // 设置表头样式
        const headerRow = worksheet.getRow(1)
        headerRow.font = { bold: true }
        headerRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE9ECEF' }
        }
        headerRow.eachCell((cell) => {
          cell.numFmt = '@'
          cell.alignment = { vertical: 'middle', horizontal: 'left' }
        })

        // 生成二进制数据
        const buffer = await workbook.xlsx.writeBuffer()
        
        // 使用 file-saver 保存文件
        const timestamp = moment().format('YYYY-MM-DD_HHmmss')  // 格式化时间戳
        const fileName = `退货订单_${timestamp}.xlsx`  // 修改文件名前缀
        const blob = new Blob([buffer], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        })
        saveAs(blob, fileName)
        
        // 添加导出成功提示
        this.$message.success('导出成功')
      } catch (error) {
        console.error('Export failed:', error)
        this.$message.error('导出失败，请重试')
      }
    }
  },
}

</script>

<style scoped lang="scss">
.order-container {
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  position: relative;

  .queryItems {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ebeef5;

    .el-input {
      width: 280px;
      .el-input__inner {
        border-radius: 4px;
        height: 28px;
        line-height: 28px;
        &:focus {
          border-color: #409EFF;
          box-shadow: 0 0 0 2px rgba(64,158,255,.2);
        }
      }
    }

    // 统一按钮基础样式
    .el-button {
      height: 28px;
      line-height: 1;
      padding: 0 12px;
      font-size: 12px;
      border-radius: 4px;
      transition: all 0.2s;

      [class^="el-icon-"] {
        margin-right: 4px;
        font-size: 12px;
      }

      // 默认按钮样式
      &--default {
        background: #f5f7fa;
        border-color: #dcdfe6;
        color: #606266;

        &:hover {
          background: #e9ecf2;
          border-color: #c6e2ff;
          color: #409EFF;
        }
      }

      // 主要按钮样式
      &--primary {
        &:hover {
          opacity: 0.9;
        }
      }

      // 危险按钮样式
      &--danger {
        &:hover {
          opacity: 0.9;
        }
      }

      // 警告按钮样式
      &--warning {

        &:hover {
          background: #fdf6ec;
          color: #e6a23c;
        }
      }
    }

    // 筛选按钮
    .filter-badge {
      .el-button {
        background: #f5f7fa;
        border-color: #dcdfe6;
        color: #606266;

        &:hover {
          background: #e9ecf2;
          border-color: #c6e2ff;
          color: #409EFF;
        }
      }
    }

    // 导出按钮
    .export-excel-wrapper {
      .el-button {
        background: #fff;
        border-color: #409EFF;
        color: #409EFF;

        &:hover {
          background: #ecf5ff;
        }
      }
    }

    // 添加选择框样式
    .el-select {
      .el-input__inner {
        height: 28px;
        line-height: 28px;
        border-radius: 4px;

        &:focus {
          border-color: #409EFF;
          box-shadow: 0 0 0 2px rgba(64,158,255,.2);
        }
      }
    }
  }

  // 表格样式优化
  ::v-deep .el-table {
    border-radius: 8px;
    overflow: hidden;
    font-size: 12px;

    th {
      background-color: #f5f7fa;
      color: #606266;
      font-weight: 500;
      padding: 8px 0;
      font-size: 12px;
    }

    td {
      padding: 6px 0;
    }

    .cell {
      font-size: 12px;
      line-height: 1.4;

      // 只对需要居中的列用flex布局
      &.is-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // 运单号列特殊处理
    .carrier-bill-codes {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .bill-code-link {
        font-size: 12px;
        color: #409EFF;
        cursor: pointer;
        padding: 2px 0;
        transition: all 0.2s;

        &:hover {
          color: #66b1ff;
          text-decoration: underline;
        }
      }
    }

    // 调整选择框大小
    .el-checkbox__inner {
      width: 14px;
      height: 14px;
      &::after {
        height: 7px;
        left: 4px;
      }
    }

    // 禁用默认文本选择
    td, th {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    // 选中单元格样式
    .selected-cell {
      background-color: rgba(64, 158, 255, 0.1) !important;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid rgba(64, 158, 255, 0.2);
        pointer-events: none;
      }
    }

    // 鼠标样式
    td {
      cursor: cell;

      &:hover {
        background-color: rgba(64, 158, 255, 0.05);
      }
    }
  }

  // 分页器样式调整
  ::v-deep .el-pagination {
    margin-top: 1.6rem;
    padding: 0.8rem 0;
    font-size: 13px;

    .btn-prev, .btn-next, .el-pager li {
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 13px;
    }
  }
}
</style>
