export function getDefaultColumns(OrderSource) {
    if (OrderSource === 4) {
        return [
            {
                label: "订单号",
                prop: "MainBillCode",
                disabled: false,
                sort: 1,
                width: 180
            },
            {
                label: "订单状态",
                prop: "OrderStatu",
                disabled: false,
                sort: 2,
                width: "auto",
                type: "format"
            },
            {
                label: "店铺名称",
                prop: "ShopeeName",
                disabled: false,
                sort: 3,
                width: '150'
            },
            {
                label: "仓库",
                prop: "WareHouseName",
                disabled: false,
                sort: 3,
                width: 'auto'
            },
            {
                label: "承运商",
                prop: "CarrierName",
                disabled: false,
                sort: 4,
                width: 'auto'
            },
            {
                label: "承运单号",
                prop: "CarrierBillCode",
                disabled: false,
                sort: 5,
                width: '160'
            },
            {
                label: "时效",
                prop: "TimerName",
                disabled: false,
                sort: 6,
                width: 'auto'
            },
            {
                label: "货物类型",
                prop: "GoodsTypeName",
                disabled: false,
                sort: 7,
                width: 'auto'
            },
            {
                label: "派件人",
                prop: "DeliveryName",
                disabled: false,
                sort: 8,
                width: 'auto'
            },
            {
                label: "物流状态",
                prop: "TrackStateName",
                disabled: false,
                sort: 8,
                width: 'auto'
            },
            {
                label: "物流描述",
                prop: "TrackContent",
                disabled: false,
                sort: 8,
                width: 'auto'
            },
            {
                label: "重量(KG)",
                prop: "Weight",
                disabled: false,
                sort: 9,
                width: 'auto'
            },
            {
                label: "下单时间",
                prop: "CreateTime",
                disabled: false,
                sort: 10,
                width: 180,
                type: "formatTime"
            },
            {
                label: "门店名称",
                prop: "StoreName",
                disabled: false,
                sort: 10,
                width: 'auto'
            },
            {
                label: "门店号",
                prop: "StoreNumber",
                disabled: false,
                sort: 10,
                width: 'auto'
            },
            {
                label: "订单过期时间",
                prop: "OrderExpiredTime",
                disabled: false,
                sort: 10,
                width: 180,
                type: "formatTime"
            }
        ]
    } else {
        return [
            {
                label: "订单号",
                prop: "MainBillCode",
                disabled: false,
                sort: 1,
                width: 180
            },
            {
                label: "订单状态",
                prop: "OrderStatu",
                disabled: false,
                sort: 2,
                width: "auto",
                type: "format"
            },
            {
                label: "仓库",
                prop: "WareHouseName",
                disabled: false,
                sort: 3,
                width: 'auto'
            },
            {
                label: "承运商",
                prop: "CarrierName",
                disabled: false,
                sort: 4,
                width: 'auto'
            },
            {
                label: "承运单号",
                prop: "CarrierBillCode",
                disabled: false,
                sort: 5,
                width: '160'
            },
            {
                label: "时效",
                prop: "TimerName",
                disabled: false,
                sort: 6,
                width: 'auto'
            },
            {
                label: "货物类型",
                prop: "GoodsTypeName",
                disabled: false,
                sort: 7,
                width: 'auto'
            },
            {
                label: "派件人",
                prop: "DeliveryName",
                disabled: false,
                sort: 8,
                width: 'auto'
            },
            {
                label: "物流状态",
                prop: "TrackStateName",
                disabled: false,
                sort: 8,
                width: 'auto'
            },
            {
                label: "物流描述",
                prop: "TrackContent",
                disabled: false,
                sort: 8,
                width: 'auto'
            },
            {
                label: "重量(KG)",
                prop: "Weight",
                disabled: false,
                sort: 9,
                width: 'auto'
            },
            {
                label: "门店名称",
                prop: "StoreName",
                disabled: false,
                sort: 10,
                width: 'auto'
            },
            {
                label: "门店号",
                prop: "StoreNumber",
                disabled: false,
                sort: 10,
                width: 'auto'
            },
            {
                label: "下单时间",
                prop: "CreateTime",
                disabled: false,
                sort: 10,
                width: 180
            }
        ]
    }
}

export function getAllColumns() {
    return [
        {
            label: "订单号",
            prop: "MainBillCode",
            disabled: false,
            sort: 1,
            width: 180
        },
        {
            label: "承运单号",
            prop: "CarrierBillCode",
            disabled: false,
            sort: 2,
            width: '160'
        },
        {
            label: "店铺编号",
            prop: "ShopId",
            disabled: false,
            sort: 3,
            width: '120'
        },
        {
            label: "电商店铺ID",
            prop: "ElectronicShopId",
            disabled: false,
            sort: 3,
            width: '130'
        },
        {
            label: "店铺名称",
            prop: "ShopName",
            disabled: false,
            sort: 3,
            width: '120'
        },
        {
            label: "虾皮店铺名称",
            prop: "ShopeeName",
            disabled: false,
            sort: 4,
            width: '180'
        },
        {
            label: "仓库名称",
            prop: "WareHouseName",
            disabled: false,
            sort: 4,
            width: '120'
        },
        {
            label: "客户名称",
            prop: "ClientName",
            disabled: false,
            sort: 5,
            width: '120'
        },
        {
            label: "订单状态",
            prop: "OrderStatu",
            disabled: false,
            sort: 6,
            width: '120'
        },
        {
            label: "客户等级",
            prop: "LevelName",
            disabled: false,
            sort: 7,
            width: '120'
        },
        {
            label: "客户分组",
            prop: "GroupName",
            disabled: false,
            sort: 8,
            width: '120'
        },
        {
            label: "货物类型",
            prop: "GoodsTypeName",
            disabled: false,
            sort: 9,
            width: '120'
        },
        {
            label: "时效名称",
            prop: "TimerName",
            disabled: false,
            sort: 10,
            width: '120'
        },
        {
            label: "承运商",
            prop: "CarrierName",
            disabled: false,
            sort: 11,
            width: '120'
        },
        {
            label: "目的地",
            prop: "DestinationName",
            disabled: false,
            sort: 12,
            width: '100'
        },
        {
            label: "省份",
            prop: "ProvinceName",
            disabled: false,
            sort: 13,
            width: '100'
        },
        {
            label: "城市",
            prop: "CityName",
            disabled: false,
            sort: 14,
            width: '100'
        },
        {
            label: "区县",
            prop: "AreaName",
            disabled: false,
            sort: 15,
            width: '100'
        },
        {
            label: "派件人",
            prop: "DeliveryName",
            disabled: false,
            sort: 16,
            width: '120'
        },
        {
            label: "支付方式",
            prop: "PayTypeName",
            disabled: false,
            sort: 17,
            width: '120'
        },
        {
            label: "付款状态",
            prop: "PayState",
            disabled: false,
            sort: 18,
            width: '120'
        },
        {
            label: "支付时间",
            prop: "PayTime",
            disabled: false,
            sort: 19,
            width: '180'
        },
        {
            label: "收件人",
            prop: "ReceiveName",
            disabled: false,
            sort: 20,
            width: '120'
        },
        {
            label: "收件电话",
            prop: "ReceivePhone",
            disabled: false,
            sort: 21,
            width: '120'
        },
        {
            label: "收件地址",
            prop: "ReceiveAddress",
            disabled: false,
            sort: 22,
            width: '180'
        },
        {
            label: "邮箱",
            prop: "ReceiveEmail",
            disabled: false,
            sort: 22,
            width: '120'
        },
        {
            label: "邮政编码",
            prop: "ReceivePostCode",
            disabled: false,
            sort: 22,
            width: '120'
        },
        {
            label: "统编",
            prop: "ReceiveIdCard",
            disabled: false,
            sort: 22,
            width: '120'
        },
        {
            label: "电话号码",
            prop: "ReceiveTelephone",
            disabled: false,
            sort: 22,
            width: '120'
        },
        {
            label: "品名",
            prop: "GoodsName",
            disabled: false,
            sort: 23,
            width: '120'
        },
        {
            label: "英文品名",
            prop: "GoodsName_En",
            disabled: false,
            sort: 23,
            width: '120'
        },
        {
            label: "申报人名",
            prop: "DeclareName",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "申报人手机",
            prop: "DeclarePhone",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "申报人电话号码",
            prop: "DeclareTelephone",
            disabled: false,
            sort: 24,
            width: '160'
        },
        {
            label: "申报人地址",
            prop: "DeclareAddress",
            disabled: false,
            sort: 24,
            width: '140'
        },
        {
            label: "申报人统编",
            prop: "DeclareIdCard",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "申报人邮政编码",
            prop: "DeclarePostCode",
            disabled: false,
            sort: 24,
            width: '160'
        },
        {
            label: "申报人邮箱",
            prop: "DeclareEmail",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "申报金额",
            prop: "DeclareMoney",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "申报单价",
            prop: "DeclarePrice",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "申报数量",
            prop: "DeclareAccount",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "订单来源",
            prop: "OrderSourceName",
            disabled: false,
            sort: 24,
            width: '120'
        },
        {
            label: "重量(KG)",
            prop: "Weight",
            disabled: false,
            sort: 25,
            width: '120'
        },
        {
            label: "体积重量",
            prop: "VolumeWeight",
            disabled: false,
            sort: 26,
            width: '120'
        },
        {
            label: "计费重量",
            prop: "FareWeight",
            disabled: false,
            sort: 27,
            width: '120'
        },
        {
            label: "复核重量",
            prop: "CheckWeight",
            disabled: false,
            sort: 28,
            width: '120'
        },
        {
            label: "运费",
            prop: "Freight",
            disabled: false,
            sort: 29,
            width: '120'
        },
        {
            label: "应收费用",
            prop: "Receivables",
            disabled: false,
            sort: 30,
            width: '120'
        },
        {
            label: "是否包税",
            prop: "IsTaxName",
            disabled: false,
            sort: 30,
            width: '120'
        },
        {
            label: "代收货款",
            prop: "CollectionAmount",
            disabled: false,
            sort: 30,
            width: '120'
        },
        {
            label: "代收货款手续费",
            prop: "CollectionCommission",
            disabled: false,
            sort: 30,
            width: '160'
        },
        {
            label: "物流状态",
            prop: "TrackStateName",
            disabled: false,
            sort: 31,
            width: '120'
        },
        {
            label: "物流描述",
            prop: "TrackContent",
            disabled: false,
            sort: 31,
            width: '140'
        },
        {
            label: "审核状态",
            prop: "AuditStateName",
            disabled: false,
            sort: 32,
            width: '120'
        },
        {
            label: "拣货状态",
            prop: "PickingStateName",
            disabled: false,
            sort: 33,
            width: '120'
        },
        {
            label: "订单备注",
            prop: "Rem",
            disabled: false,
            sort: 34,
            width: '120'
        },
        {
            label: "客户备注",
            prop: "ClientRem",
            disabled: false,
            sort: 35,
            width: '120'
        },
        {
            label: "库存状态",
            prop: "StockStateTxt",
            disabled: false,
            sort: 35,
            width: '120'
        },
        {
            label: "门店名称",
            prop: "StoreName",
            disabled: false,
            sort: 35,
            width: '120'
        },
        {
            label: "门店号",
            prop: "StoreNumber",
            disabled: false,
            sort: 35,
            width: '120'
        },
        {
            label: "创建时间",
            prop: "CreateTime",
            disabled: false,
            sort: 36,
            width: 180
        },
        {
            label: "订单过期时间",
            prop: "OrderExpiredTime",
            disabled: false,
            sort: 36,
            width: 180
        },
        {
            label: "核重时间",
            prop: "CheckTime",
            disabled: false,
            sort: 37,
            width: '180'
        },
        {
            label: "备注1",
            prop: "Rem1",
            disabled: false,
            sort: 38,
            width: '200'
        },
        {
            label: "备注2",
            prop: "Rem2",
            disabled: false,
            sort: 38,
            width: '200'
        },
        {
            label: "备注3",
            prop: "Rem3",
            disabled: false,
            sort: 38,
            width: '200'
        },
        {
            label: "备注4",
            prop: "Rem4",
            disabled: false,
            sort: 38,
            width: '200'
        }
    ]
}
