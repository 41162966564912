<template>
  <order-upload></order-upload>
</template>

<script>
import OrderUpload from "@/components/OrderUpload/index.vue";
export default {
  components: { OrderUpload }
}

</script>

<style scoped lang="scss">

</style>
