<template>
  <integrated-order :order-source="5" :order-statu="3"></integrated-order>
</template>

<script>
import IntegratedOrder from "@/components/IntegratedOrder.vue";
export default {
  components: { IntegratedOrder },
}

</script>

<style></style>