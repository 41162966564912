<template>
  <div class="tools-box">
    <div class="title">语言</div>
    <div class="lang-list">
      <div
        v-for="lang in languages"
        :key="lang.value"
        class="lang-item"
        :class="{ active: lang.value === selectedLang }"
        @click="changeLang(lang.value)"
      >
        {{ lang.label }}
      </div>
    </div>

    <div class="title" v-if="menuModal.exchange">币种</div>
    <div class="currency-list">
      <div
        v-for="item in currency"
        :key="item.Code"
        class="currency-item"
        :class="{ active: item.Code === selectedCurrency?.Code }"
        @click="selectCurrency(item)"
      >
        {{ item.Code }}
      </div>
    </div>

    <el-button round class="confirm-btn" type="primary" @click="handleConfirm">确认</el-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LocaleAndCurrency',
  data() {
    return {
      selectedLang: this.$store.getters.language,
      selectedCurrency: null,
      languages: [
        { label: '中文简体', value: 'zhHans' },
        { label: 'English', value: 'en' },
        { label: '中文繁体', value: 'zhHanT' },
        { label: 'Tiếng Việt', value: 'vi' }
      ]
    }
  },
  computed: {
    ...mapGetters(['currency', 'menuModal']),
    language() {
      return this.$store.getters.language
    }
  },
  methods: {
    changeLang(lang) {
      this.selectedLang = lang
    },
    selectCurrency(currency) {
      this.selectedCurrency = currency
    },
    handleConfirm() {
      // 更新语言
      this.$i18n.locale = this.selectedLang
      this.$store.dispatch('webSite/setLanguage', this.selectedLang)
      // 触发货币更新
      this.$emit('currency-changed', this.selectedCurrency)
      // 关闭弹窗
      this.$emit('close')
    }
  },
  created() {
    this.selectedCurrency = this.currency[0]
  }
}
</script>

<style lang="scss" scoped>
.tools-box {
  padding: 0;
  min-width: 200px;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin: 10px 0;
  }

  .lang-list,
  .currency-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;

    .lang-item,
    .currency-item {
      padding: 6px 12px;
      border-radius: 16px;
      border: 1px solid #dcdfe6;
      cursor: pointer;
      transition: all 0.3s;
      text-align: center;
      width: calc(50% - 4px);
      box-sizing: border-box;

      &:hover {
        border-color: #4ea6e5;
        color: #4ea6e5;
      }

      &.active {
        background: #4ea6e5;
        color: white;
        border-color: #4ea6e5;
      }
    }
  }

  .confirm-btn {
    width: 100%;
    margin-top: 10px;
    background-color: #4ea6e5;
    border-color: #4ea6e5;

    &:hover {
      background-color: #3993d9;
      border-color: #3993d9;
    }
  }
}
</style>
