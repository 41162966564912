<template>
  <integrated-order :order-source="5" :order-statu="0"></integrated-order>
</template>

<script>
import IntegratedOrder from "@/components/IntegratedOrder.vue";
export default {
  components: { IntegratedOrder },
}

</script>

<style scoped lang="scss">
</style>