<template>
  <ware-house-components></ware-house-components>
</template>

<script>
import WareHouseComponents from "@/views/member/cod/components/WareHouseComponents.vue";
export default {
  components: {
    WareHouseComponents
  }
}

</script>

<style scoped lang="scss">
</style>
