<template>
  <el-drawer
      title="列设置"
      :visible.sync="show"
      :direction="direction"
      append-to-body
      :before-close="handleClose">

    <!-- 全选区域 -->
    <div class="select-all">
      <div class="select-all-content">
        <el-checkbox
          v-model="isAllSelected"
          @change="handleSelectAll"
          :indeterminate="isIndeterminate" />
        <span class="column-count">
          {{ checkedCount }}/{{ totalSelectableCount }}
        </span>
        <span class="fixed-text">排序/修改</span>
      </div>
    </div>

    <!-- 拖拽列表区域 -->
    <draggable
      v-model="selectedColumns"
      class="column-list"
      :options="{
        disabled: false,
        filter: '.disabled-item'
      }">
      <div v-for="item in selectedColumns"
           :key="item.prop"
           :class="['column-item', { 'disabled-item': item.disabled }]">
        <div class="item-left">
          <el-checkbox
            :value="item.checked"
            @input="handleCheckboxChange(item, $event)"
            :disabled="item.disabled">
            <template v-if="item.isEditing">
              <el-input
                v-model="item.editingLabel"
                size="mini"
                @blur="saveLabel(item)"
                @keyup.enter.native="saveLabel(item)"
                ref="labelInput"
                v-focus
              ></el-input>
            </template>
            <template v-else>
              {{ item.label }}
            </template>
          </el-checkbox>
        </div>
        <div class="item-right">
          <el-button-group>
            <el-button
              size="mini"
              icon="el-icon-arrow-left"
              :disabled="isFirstItem(item)"
              @click="moveItem(item, 'up')">
            </el-button>
            <el-button size="mini" icon="el-icon-close"></el-button>
            <el-button
              size="mini"
              icon="el-icon-arrow-right"
              :disabled="isLastItem(item)"
              @click="moveItem(item, 'down')">
            </el-button>
          </el-button-group>
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click="startEdit(item)">
          </el-button>
        </div>
      </div>
    </draggable>

    <!-- 底部操作按钮 -->
    <div class="drawer-footer">
      <el-button
        round
        class="reset-btn footer-btn"
        @click="handleReset">
        重置
      </el-button>
      <el-button
        round
        type="primary"
        class="confirm-btn footer-btn"
        @click="handleSave">
        确认
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },

  props: {
    // 表格所有列配置
    allColumns: {
      type: Array,
      required: true
    },
    // 本地存储的key
    storageKey: {
      type: String,
      required: true
    },
    // 默认显示的列
    defaultColumns: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      direction: 'rtl',
      selectedColumns: [], // 当前选中的列
      isAllSelected: false,
      isIndeterminate: false
    }
  },

  watch: {
    // 监听选中状态变化，更新全选状态
    selectedColumns: {
      handler: 'updateSelectAllStatus',
      deep: true
    }
  },

  created() {
    this.initColumns()
  },

  computed: {
    // 计算选中的列数（不包禁用的）
    checkedCount() {
      return this.selectedColumns.filter(col => !col.disabled && col.checked).length
    },
    // 计算可选的总数（不用的）
    totalSelectableCount() {
      return this.selectedColumns.filter(col => !col.disabled).length
    }
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector('input').focus()
      }
    }
  },

  methods: {
    // 初始化列数据
    initColumns() {
      console.log("初始化")
      // 从localStorage获取保存的列设置
      const savedColumns = localStorage.getItem(this.storageKey)

      // 始终基于最新的 allColumns 创建选中状态
      this.selectedColumns = this.allColumns.map(col => {
        // 如果有保存的配置，检查该列是否在保存的配置中
        const savedColumn = savedColumns
          ? JSON.parse(savedColumns).find(saved => saved.prop === col.prop)
          : null

        return {
          ...col,
          // 如果在localStorage中找到了对应的列配置，使用保存的label
          label: savedColumn ? savedColumn.label : col.label,
          checked: savedColumns
            ? !!savedColumn  // 如果在保存的配置中存在，则为选中状态
            : this.defaultColumns.some(defaultCol => defaultCol.prop === col.prop)
        }
      })

      // 初始化后更新全选状态
      this.$nextTick(() => {
        this.updateSelectAllStatus()
      })
    },

    // 保存设置
    handleSave() {
      // 检查必选项是否被取消
      const hasUncheckedRequired = this.selectedColumns.some(col =>
        col.disabled && !col.checked
      )

      if (hasUncheckedRequired) {
        this.$message.error('必选列不能取消选中')
        return
      }

      // 只保存选中的列到 localStorage
      const columnsToSave = this.selectedColumns
        .filter(col => col.checked)
        .map(col => ({
          ...col,
          checked: true
        }))

      localStorage.setItem(this.storageKey, JSON.stringify(columnsToSave))

      // 触发保存事件，传递选中的列给父组件
      this.$emit('save', columnsToSave)

      this.handleClose()
    },

    handleClose() {
      this.$emit("close")
    },

    // 更新全选状态
    updateSelectAllStatus() {
      // 过滤出可选的列（非禁用的）
      const selectableColumns = this.selectedColumns.filter(col => !col.disabled)
      const checkedCount = selectableColumns.filter(col => col.checked).length

      // 使用 $set 更新状态，确保响应性
      this.$set(this, 'isAllSelected', checkedCount === selectableColumns.length)
      this.$set(this, 'isIndeterminate', checkedCount > 0 && checkedCount < selectableColumns.length)
    },

    // 处理全选操作
    handleSelectAll(val) {
      // 只更新非禁用的列的选中状态
      this.selectedColumns.forEach(col => {
        if (!col.disabled) {
          this.$set(col, 'checked', val)  // 使用 $set 确保响应性
        }
      })
      this.isIndeterminate = false
    },

    // 判断是否为第一项
    isFirstItem(item) {
      return this.selectedColumns.indexOf(item) === 0
    },

    // 判断是否为最后一项
    isLastItem(item) {
      return this.selectedColumns.indexOf(item) === this.selectedColumns.length - 1
    },

    // 移动项目
    moveItem(item, direction) {
      const currentIndex = this.selectedColumns.indexOf(item)

      if (direction === 'up' && currentIndex > 0) {
        // 上移：与前一项交换位置
        const temp = [...this.selectedColumns]
        const swappedItem = temp[currentIndex - 1]
        this.$set(this.selectedColumns, currentIndex - 1, temp[currentIndex])
        this.$set(this.selectedColumns, currentIndex, swappedItem)
      } else if (direction === 'down' && currentIndex < this.selectedColumns.length - 1) {
        // 下移：与后一项交换位置
        const temp = [...this.selectedColumns]
        const swappedItem = temp[currentIndex + 1]
        this.$set(this.selectedColumns, currentIndex + 1, temp[currentIndex])
        this.$set(this.selectedColumns, currentIndex, swappedItem)
      }
    },

    // 开始编辑标签
    startEdit(item) {
      // 先结束其他项的编辑状态
      this.selectedColumns.forEach(col => {
        if (col !== item && col.isEditing) {
          this.saveLabel(col)
        }
      })

      // 设置当前项的编辑状态
      this.$set(item, 'isEditing', true)
      this.$set(item, 'editingLabel', item.label)
    },

    // 保存标签
    saveLabel(item) {
      if (!item.isEditing) return

      const newLabel = item.editingLabel.trim()
      if (newLabel && newLabel !== item.label) {
        item.label = newLabel
      }

      this.$set(item, 'isEditing', false)
      delete item.editingLabel
    },

    // 重置配置
    handleReset() {
      // 弹出确认框
      this.$confirm('确定要重置配置吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 清除本地存储
        localStorage.removeItem(this.storageKey)
        // 重置为默认配置
        this.selectedColumns = this.allColumns.map(col => ({
          ...col,
          checked: this.defaultColumns.some(defaultCol => defaultCol.prop === col.prop)
        }))
        this.$message.success('已重置为默认配置')
        this.handleSave()
      }).catch(() => {
        // 取消重置
      })
    },

    handleCheckboxChange(item, value) {
      this.$set(item, 'checked', value)
      this.updateSelectAllStatus()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
::v-deep .el-drawer__header {
  margin-bottom: 1.8rem;
}
.select-all {
  padding: 16px 20px;
  border-bottom: 1px solid #DCDFE6;

  .select-all-content {
    display: flex;
    align-items: center;

    ::v-deep .el-checkbox {
      margin-right: 8px;
      .el-checkbox__input {
        vertical-align: middle;
      }
    }

    .column-count {
      color: #606266;
      font-size: 14px;
    }

    .fixed-text {
      margin-left: auto;
      color: #909399;
      font-size: 14px;
    }
  }
}

.column-list {
  padding: 0 20px;
  height: calc(100vh - 180px);
  overflow-y: auto;
  margin-bottom: 70px;
}

.column-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #EBEEF5;

  .item-left {
    display: flex;
    align-items: center;

    ::v-deep .el-checkbox {
      .el-checkbox__input {
        vertical-align: middle;
      }
      .el-checkbox__label {
        font-size: 14px;
      }
    }
  }

  .item-right {
    display: flex;
    align-items: center;
    gap: 8px;

    .el-button-group {
      margin-right: 8px;
    }

    ::v-deep .el-button--mini {
      padding: 5px 8px;
      [class^="el-icon-"] {
        font-size: 12px;
      }
    }
  }

  &.disabled-item {
    background-color: #F5F7FA;
  }
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 20px;
  background: #fff;
  border-top: 1px solid #DCDFE6;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  .footer-btn {
    width: 45%;
  }

  .reset-btn {
    background: linear-gradient(to right, #FF9A9E, #FAD0C4);
    border: none;
    color: white;

    &:hover {
      background: linear-gradient(to right, #FF8A8E, #FAC0B4);
    }
  }

  .confirm-btn {
    background: linear-gradient(to right, #4facfe, #00f2fe);
    border: none;

    &:hover {
      background: linear-gradient(to right, #3f9cfe, #00e2fe);
    }
  }

  ::v-deep .el-button {
    padding: 12px 0;

    &:focus,
    &:active {
      opacity: 0.9;
    }
  }
}

.item-left {
  .el-input {
    width: 150px;  // 或者其他合适的宽度
  }
}

::v-deep .el-checkbox__label {
  // 确保输入框在checkbox label中正确对齐
  display: inline-flex;
  align-items: center;
}
</style>
