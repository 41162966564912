<template>
  <div class="container">
    <div class="ipt-box u-between-flex">
      <div class="introduce-box animated bounceInDown">
        <div class="introduce-title mb30">{{ shop_name }}</div>
        <div class="introduce-line mb30"></div>
        <div class="introduce-sub-title mt40 mb30">{{ $t('login.subTitle1') }}</div>
<!--        <div class="introduce-small-title mt20 mb10">{{ $t('login.subTitle2') }}</div>-->
        <div class="introduce-small-title mb10">{{ $t('login.subTitle3') }}</div>
        <div class="introduce-small-title">{{ $t('login.subTitle4') }}</div>
      </div>
      <div class="login-box animated flipInY">
        <!-- 右上角切换按钮 -->
        <div class="switch-login-type" @click="switchLoginMode">
          <div class="icon-wrapper">
            <i :class="isQrcodeMode ? 'el-icon-monitor' : 'el-icon-mobile'"></i>
            <span>{{ $t(isQrcodeMode ? 'login.switchToPassword' : 'login.switchToQrcode') }}</span>
          </div>
        </div>

        <!-- 账号登录模式 -->
        <div v-show="!isQrcodeMode" class="account-login-mode">
          <div class="login-tabs">
            <div 
              :class="['tab-item', { active: loginType === 'account' }]" 
              @click="loginType = 'account'; clearValidate()"
            >
              {{ $t('login.accountLogin') }}
            </div>
            <div 
              :class="['tab-item', { active: loginType === 'phone' }]" 
              @click="loginType = 'phone'; clearValidate()"
            >
              {{ $t('login.phoneLogin') }}
            </div>
          </div>

          <!-- 账号密码登录表单 -->
          <div class="login-form" v-show="loginType === 'account'">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item label="" prop="username">
                <el-input v-model="ruleForm.username" size="small" :placeholder="$t('login.account')"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input v-model="ruleForm.password" size="small" show-password :placeholder="$t('login.password')"></el-input>
              </el-form-item>
            </el-form>
          </div>

          <!-- 手机验证码登录表单 -->
          <div class="login-form" v-show="loginType === 'phone'">
            <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm">
              <el-form-item label="" prop="phone">
                <el-input 
                  v-model="phoneForm.phone" 
                  size="small" 
                  :placeholder="$t('login.placeholderPhone')"
                >
                  <template #prepend>
                    <div class="area-code" @click="showAreaCodePicker">
                      <span>+{{ phoneForm.areaCode }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="code">
                <el-input 
                  v-model="phoneForm.code" 
                  size="small" 
                  :placeholder="$t('login.placeholderCode')"
                >
                  <template #suffix>
                    <div class="verify-code-btn" @click="!isCountDown && getVerifyCode()">
                      <div class="divider"></div>
                      <span :class="{ 'is-disabled': isCountDown }">
                        {{ isCountDown ? $t('login.codeCountdown', [countdown]) : $t('login.getCode') }}
                      </span>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <div class="login-ability u-between-flex">
            <div @click="$router.push({path: '/register'})" class="pointer" style="font-size: 1.4rem; color: #333333;">{{ $t('login.register') }}</div>
            <div class="pointer" style="font-size: 1.4rem; color: #999999" @click="handleOpenForgetPassword">{{ $t('login.forgetPassword') }}</div>
          </div>
          <div class="mt15">
            <el-button :loading="loading" class="login-btn" @click="handleLogin">{{ $t('login.submit') }}</el-button>
          </div>
        </div>

        <!-- 扫码登录模式 -->
        <div v-show="isQrcodeMode" class="qrcode-login-mode">
          <div class="qrcode-header">
            <img src="../../static/images/safe-icon.png" class="safe-icon" alt="">
            <span>{{ $t('login.qrcodeTitle') }}</span>
          </div>
          <div class="qrcode-wrapper">
            <img :src="qrcodeUrl" alt="二维码" class="qrcode-img">
            <div v-if="qrcodeExpired" class="qrcode-mask">
              <div class="expired-text">二维码已失效</div>
              <el-button size="small" @click="refreshQrcode">点击刷新</el-button>
            </div>
          </div>
          <div class="qrcode-tip">
            <img src="../../static/images/scan-icon.png" class="scan-icon" alt="">
            <div class="tip-text">
              <div>{{ $t('login.qrcodeTip1') }}</div>
              <div>{{ $t('login.qrcodeTip2') }}</div>
            </div>
          </div>
          <div class="qrcode-register">
            <span @click="$router.push({path: '/register'})" class="pointer">
              {{ $t('login.registerNow') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('login.resetPassword')"
      :visible.sync="forgetPasswordVisible"
      width="300px"
      modal-append-to-body
      :close-on-click-modal="false"
    >
      <el-form :model="forgetPasswordForm" :rules="forgetPasswordRules" ref="forgetPasswordForm" label-width="0">
        <el-form-item prop="phone">
          <el-input
              v-model="forgetPasswordForm.phone"
              size="small"
              :placeholder="$t('login.placeholderPhone')"
          >
            <template #prepend>
              <div class="area-code" @click="showAreaCodePicker">
                <span>+{{ forgetPasswordForm.areaCode }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input 
            v-model="forgetPasswordForm.code" 
            placeholder="请输入验证码"
            size="small"
          >
            <template #suffix>
              <div class="verify-code-btn" @click="!isForgetPasswordCountDown && getForgetPasswordCode()">
                <div class="divider"></div>
                <span :class="{ 'is-disabled': isForgetPasswordCountDown }">
                  {{ forgetPasswordCountDownText }}
                </span>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input 
            v-model="forgetPasswordForm.password" 
            type="password"
            :placeholder="$t('login.placeholderNewPassword')"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="forgetPasswordVisible = false">{{ $t('login.cancel') }}</el-button>
        <el-button type="primary" :loading="forgetPasswordLoading" @click="submitForgetPassword">
          {{ $t('login.confirm') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择区号"
      :visible.sync="areaCodeVisible"
      width="300px"
      custom-class="area-code-dialog"
    >
      <div class="area-code-list">
        <div
          v-for="item in options"
          :key="item.value"
          class="area-code-item"
          :class="{ active: phoneForm.areaCode === item.value }"
          @click="selectAreaCode(item)"
        >
          <span class="country">{{ item.label }}</span>
          <span class="code">+{{ item.value }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {CountryInfo, getSMSCode} from "@/api/system";
import {resetPwd} from "@/api/user";
export default {
  data() {
    return {
      loginType: 'account', // 登录方式：account-账号密码，phone-手机验证码
      ruleForm: {
        username: undefined,
        password: undefined
      },
      phoneForm: {
        phone: '',
        code: '',
        areaCode: ''
      },
      options: [],
      loading: false,
      countdown: 60,
      isCountDown: false,
      isQrcodeMode: false,
      qrcodeUrl: '',
      qrcodeExpired: false,
      qrcodeTimer: null,
      forgetPasswordVisible: false,
      forgetPasswordForm: {
        phone: '',
        code: '',
        password: ''
      },
      forgetPasswordRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 6, max: 15, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '验证码格式不正确', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      forgetPasswordLoading: false,
      forgetPasswordCountdown: 60,
      isForgetPasswordCountDown: false,
      areaCodeVisible: false, // 添加区号选择弹窗显示状态
    }
  },
  created() {
    this.getCountryCodes()
    const t = this;
    document.onkeydown = function(e){
      let key;
      if(window.event == undefined){
        key = e.keyCode;
      }else{
        key = window.event.keyCode;
      }
      if(key === 13){
        t.submitForm('ruleForm');
      }
    }
  },
  destroyed() {
    this.clearQrcodeTimer()
  },
  computed: {
    ...mapGetters(['shop_name']),
    rules() {
      return {
        username: [
          { required: true, message: this.$t('login.placeholderAccount'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('login.placeholderPassword'), trigger: 'blur' }
        ],
      }
    },
    phoneRules() {
      return {
        phone: [
          { required: true, message: this.$t('login.placeholderPhone'), trigger: 'blur' },
          // { pattern: /^1[3-9]\d{9}$/, message: this.$t('login.phoneValidateMsg'), trigger: 'blur' }
        ],
        code: [
          { required: true, message: this.$t('login.placeholderCode'), trigger: 'blur' },
          { pattern: /^\d{6}$/, message: this.$t('login.codeValidateMsg'), trigger: 'blur' }
        ]
      }
    },
    countDownText() {
      return this.isCountDown ? `${this.countdown}s后重新获取` : '获取验证码'
    },
    forgetPasswordCountDownText() {
      return this.isForgetPasswordCountDown ? `${this.forgetPasswordCountdown}s后重新获取` : '获取验证码'
    }
  },
  methods: {
    getCountryCodes() {
      const data = {
        tenantId: 1
      }
      CountryInfo(data).then(response => {
        const { Data } = response
        this.options = Data.map(item => ({
          Id: item.Id,
          label: item.Name,
          value: item.Code
        }))
        // 设置默认区号
        if (this.options.length > 0) {
          this.phoneForm.areaCode = this.options[0].value
          this.phoneForm.DestinationId = this.options[0].Id
        }
      })
    },
    // 处理登录
    handleLogin() {
      if (this.loginType === 'account') {
        this.submitForm('ruleForm')
      } else {
        this.submitPhoneLogin('phoneForm')
      }
    },
    // 账号密码登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch('user/login', this.ruleForm).then(() => {
            this.$store.commit('user/SET_SHOW_DIALOG', true)
            this.$router.push({
              path: '/member/personal'
            })
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 手机验证码登录
    submitPhoneLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.phoneLogin()
        }
      });
    },
    // 获取验证码
    getVerifyCode() {
      this.$refs.phoneForm.validateField('phone', (valid) => {
        if (!valid) {
          this.sendVerifyCode()
          this.startCountDown()
        }
      })
    },
    // 开始倒计时
    startCountDown() {
      this.isCountDown = true
      this.countdown = 60
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          this.isCountDown = false
          clearInterval(timer)
        }
      }, 1000)
    },
    // 发送验证码方法（待实现）
    sendVerifyCode() {
      const formData = {
        PhoneNumber: this.phoneForm.phone,
        DestinationId: this.phoneForm.DestinationId,
      }
      getSMSCode(formData).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.phoneForm.ClientId = Data.ClientId
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    phoneLogin() {
      this.loading = true;
      const loginParams = {
        phone: this.phoneForm.phone,
        code: this.phoneForm.code,
        ClientId: this.phoneForm.ClientId,
        DestinationId: this.phoneForm.DestinationId
      }
      this.$store.dispatch('user/login', loginParams)
        .then(() => {
          this.$store.commit('user/SET_SHOW_DIALOG', true)
          this.$router.push({
            path: '/member/personal'
          })
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    // 切换登录模式
    switchLoginMode() {
      this.isQrcodeMode = !this.isQrcodeMode
      if (this.isQrcodeMode) {
        this.getQrcode()
      } else {
        this.clearQrcodeTimer()
      }
    },
    // 获取二维码
    getQrcode() {
      // TODO: 调用后端接口获取二维码
      this.qrcodeUrl = 'qrcode_url'
      this.qrcodeExpired = false
      this.startQrcodeTimer()
    },
    // 刷新二维码
    refreshQrcode() {
      this.getQrcode()
    },
    // 开始二维码计时器
    startQrcodeTimer() {
      this.clearQrcodeTimer()
      this.qrcodeTimer = setTimeout(() => {
        this.qrcodeExpired = true
      }, 5 * 60 * 1000) // 5分钟后过期
    },
    // 清除二维码计时器
    clearQrcodeTimer() {
      if (this.qrcodeTimer) {
        clearTimeout(this.qrcodeTimer)
        this.qrcodeTimer = null
      }
    },
    handleOpenForgetPassword() {
      this.resetForgetPasswordForm()
      this.forgetPasswordVisible = true
    },
    // 重置密码
    resetForgetPasswordForm() {
      this.$refs.forgetPasswordForm?.resetFields()
      this.forgetPasswordForm = {
        phone: '',
        code: '',
        password: '',
        DestinationId: this.options[0]?.Id,
        areaCode: this.options[0]?.value
      }
      this.isForgetPasswordCountDown = false
      this.forgetPasswordCountdown = 60
    },
    // 获取忘记密码验证码
    getForgetPasswordCode() {
      this.$refs.forgetPasswordForm.validateField('phone', (valid) => {
        if (!valid) {
          this.sendForgetPasswordCode(this.forgetPasswordForm.phone)
          this.startForgetPasswordCountDown()
        }
      })
    },
    // 开始忘记密码倒计时
    startForgetPasswordCountDown() {
      this.isForgetPasswordCountDown = true
      this.forgetPasswordCountdown = 60
      const timer = setInterval(() => {
        if (this.forgetPasswordCountdown > 0) {
          this.forgetPasswordCountdown--
        } else {
          this.isForgetPasswordCountDown = false
          clearInterval(timer)
        }
      }, 1000)
    },
    // 发送忘记密码验证码（待实现）
    sendForgetPasswordCode() {
      const formData = {
        PhoneNumber: this.forgetPasswordForm.phone,
        DestinationId: this.forgetPasswordForm.DestinationId,
      }
      getSMSCode(formData).then(response => {
        const { Code, Msg } = response
        if (Code === 200) {
          this.$message.success("发送验证码成功！！")
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    // 提交重置密码
    submitForgetPassword() {
      this.$refs.forgetPasswordForm.validate((valid) => {
        if (valid) {
          this.forgetPasswordLoading = true
          const data =  {
            Code: this.forgetPasswordForm.code,
            PhoneNumber: this.forgetPasswordForm.phone,
            NewPassword: this.forgetPasswordForm.password
          }
          resetPwd(data).then(response => {
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.forgetPasswordLoading = false
              this.forgetPasswordVisible = false
              this.$message.success('密码重置成功')
            } else {
              this.$message.warning(Msg)
            }
          })
        }
      })
    },
    // 清空表单验证
    clearValidate() {
      if(this.loginType === 'account') {
        this.$refs.phoneForm?.clearValidate()
      } else {
        this.$refs.ruleForm?.clearValidate()
      }
    },
    // 显示区号选择器
    showAreaCodePicker() {
      this.areaCodeVisible = true
    },
    
    // 选择区号
    selectAreaCode(item) {
      if (this.forgetPasswordVisible) {
        this.forgetPasswordForm.areaCode = item.value
        this.forgetPasswordForm.DestinationId = item.Id
      } else {
        this.phoneForm.areaCode = item.value
        this.phoneForm.DestinationId = item.Id
      }
      this.areaCodeVisible = false
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url("../../static/images/bgc.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  .ipt-box {
    position: absolute;
    top: 20%;
    left: 15%;
    right: 20%;
    
    .introduce-box {
      flex: 1;
      .introduce-title {
        font-size: 5.6rem;
        font-weight: 600;
        color: #FFFFFF;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      }
      .introduce-line {
        width: 100%;
        height: 0.2rem;
        background: linear-gradient(90deg, rgba(255,255,255,0.9), rgba(255,255,255,0.1));
      }
      .introduce-sub-title {
        font-size: 4.2rem;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      }
      .introduce-small-title {
        font-size: 1.8rem;
        color: #FFFFFF;
        opacity: 0.9;
        letter-spacing: 0.05em;
      }
    }
    
    .login-box {
      position: relative;
      background-color: rgba(255, 255, 255, 0.95);
      border-radius: 1.2rem;
      padding: 3rem;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      width: 36rem;
      min-height: 34rem;
      
      .login-tabs {
        display: flex;
        margin-bottom: 3rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        
        .tab-item {
          flex: 1;
          text-align: center;
          padding: 1.5rem 0;
          font-size: 1.8rem;
          color: #999;
          cursor: pointer;
          position: relative;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          font-weight: 500;
          
          &:hover {
            color: #3F63EC;
          }
          
          &.active {
            color: #3F63EC;
            
            &::after {
              content: '';
              position: absolute;
              bottom: -1px;
              left: 50%;
              transform: translateX(-50%);
              width: 4rem;
              height: 0.3rem;
              background-color: #3F63EC;
              border-radius: 0.2rem;
              transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            }
          }
          
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0.3rem;
            background-color: #3F63EC;
            border-radius: 0.2rem;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          }
        }
      }
      
      .login-form {
        animation: fadeIn 0.3s ease-in-out;
        
        :deep(.el-form-item) {
          margin-bottom: 2rem;
          
          &:last-child {
            margin-bottom: 1.5rem;
          }
        }
        
        :deep(.el-input) {
          .el-input__inner {
            width: 100%;
            height: 4.8rem;
            border-radius: 0.8rem;
            border: 1px solid #E4E7ED;
            background: #F8FAFC;
            color: #333;
            font-size: 1.4rem;
            padding: 0 1.5rem;
            transition: all 0.3s ease;
            
            &::placeholder {
              color: #909399;
            }
            
            &:hover {
              border-color: #C0C4CC;
            }
            
            &:focus {
              background: #fff;
              border-color: #3F63EC;
              box-shadow: 0 0 0 2px rgba(63, 99, 236, 0.1);
            }
          }
          
          // 验证码输入框特殊处理
          &.el-input--suffix {
            .el-input__inner {
              padding-right: 11rem;
            }
            
            .el-input__suffix {
              right: 0.5rem;
              height: 100%;
              display: flex;
              align-items: center;
              
              .verify-code-btn {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 1.2rem;
                cursor: pointer;
                position: relative;
                
                .divider {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 2rem;
                  background-color: #E4E7ED;
                }
                
                span {
                  margin-left: 1.2rem;
                  font-size: 1.4rem;
                  color: #3F63EC;
                  white-space: nowrap;
                  font-weight: 500;
                  
                  &.is-disabled {
                    color: #909399;
                    cursor: not-allowed;
                  }
                  
                  &:not(.is-disabled):hover {
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
      }
      
      .login-ability {
        margin: 1.5rem 0;
        margin-top: 2.5rem;
        
        div {
          font-size: 1.4rem;
          transition: all 0.3s ease;
          
          &:first-child {
            color: #3F63EC;
            font-weight: 500;
            
            &:hover {
              opacity: 0.8;
            }
          }
          
          &:last-child {
            color: #909399;
            
            &:hover {
              color: #606266;
            }
          }
        }
      }
      
      .login-btn {
        width: 100%;
        height: 4.8rem;
        background: linear-gradient(135deg, #3F63EC, #093267);
        border-radius: 0.8rem;
        color: #fff;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        border: none;
        transition: all 0.3s ease;
        
        &:hover {
          opacity: 0.9;
          box-shadow: 0 4px 12px rgba(63, 99, 236, 0.2);
        }
        
        &.is-loading {
          opacity: 0.8;
          pointer-events: none;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 1.2rem;
  padding: 3rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 36rem;
  min-height: 34rem;
  
  .switch-login-type {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    z-index: 1;
    cursor: pointer;

    .icon-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.8rem;
      border-radius: 0.4rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      i {
        font-size: 1.6rem;
        color: #666;
      }

      span {
        font-size: 1.2rem;
        color: #666;
      }
    }
  }

  .qrcode-login-mode {
    padding: 1.5rem 1rem;
    text-align: center;
    min-height: 26rem;

    .qrcode-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      margin-bottom: 2rem;

      .safe-icon {
        width: 2rem;
        height: 2rem;
      }

      span {
        font-size: 1.6rem;
        color: #333;
        font-weight: 500;
      }
    }

    .qrcode-wrapper {
      width: 16rem;
      height: 16rem;
      margin: 0 auto;
      padding: 1.2rem;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 0.8rem;

      .qrcode-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .qrcode-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.98);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;
        border-radius: 0.8rem;

        .expired-text {
          color: #333;
          font-size: 1.4rem;
        }

        .el-button {
          padding: 0.8rem 2rem;
          font-size: 1.4rem;
          color: #ff6a00;
          border-color: #ff6a00;

          &:hover {
            background-color: rgba(255, 106, 0, 0.04);
          }
        }
      }
    }

    .qrcode-tip {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .scan-icon {
        width: 2.4rem;
        height: 2.4rem;
      }

      .tip-text {
        text-align: left;
        color: #666;
        font-size: 1.3rem;
        line-height: 1.6;
      }
    }

    .qrcode-register {
      margin-top: 1.5rem;
      text-align: center;
      
      span {
        font-size: 1.4rem;
        color: #3F63EC;
        cursor: pointer;
        
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .account-login-mode {
    min-height: 24rem;
  }
}

:deep(.el-dialog) {
  border-radius: 1.2rem;
  overflow: hidden;
  
  .el-dialog__header {
    padding: 2rem 3rem;
    border-bottom: 1px solid #f0f0f0;
    margin-right: 0;
    
    .el-dialog__title {
      font-size: 1.8rem;
      font-weight: 500;
      color: #333;
    }
    
    .el-dialog__headerbtn {
      top: 2rem;
      right: 2rem;
      
      .el-dialog__close {
        font-size: 1.8rem;
        font-weight: 600;
        
        &:hover {
          color: #3F63EC;
        }
      }
    }
  }
  
  .el-dialog__body {
    padding: 3rem;
    
    .el-form-item {
      margin-bottom: 2.4rem;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .el-input__inner {
        height: 4.4rem;
        line-height: 4.4rem;
        border-radius: 0.8rem;
        border: 1px solid #E4E7ED;
        background: #F8FAFC;
        color: #333;
        font-size: 1.4rem;
        padding: 0 1.5rem;
        transition: all 0.3s ease;
        
        &::placeholder {
          color: #909399;
        }
        
        &:hover {
          border-color: #C0C4CC;
        }
        
        &:focus {
          background: #fff;
          border-color: #3F63EC;
          box-shadow: 0 0 0 2px rgba(63, 99, 236, 0.1);
        }
      }
      
      // 验证码输入框特殊处理
      .el-input--suffix {
        .el-input__inner {
          padding-right: 11rem;
        }
        
        .el-input__suffix {
          right: 0.5rem;
          height: 100%;
          display: flex;
          align-items: center;
          
          .verify-code-btn {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 1.2rem;
            cursor: pointer;
            position: relative;
            
            .divider {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 2rem;
              background-color: #E4E7ED;
            }
            
            span {
              margin-left: 1.2rem;
              font-size: 1.4rem;
              color: #3F63EC;
              white-space: nowrap;
              font-weight: 500;
              
              &.is-disabled {
                color: #909399;
                cursor: not-allowed;
              }
              
              &:not(.is-disabled):hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
  
  .el-dialog__footer {
    padding: 2rem 3rem;
    border-top: 1px solid #f0f0f0;
    
    .el-button {
      padding: 1rem 2.4rem;
      font-size: 1.4rem;
      border-radius: 0.6rem;
      font-weight: 500;
      
      &:first-child {
        margin-right: 1.2rem;
      }
      
      &--default {
        border-color: #E4E7ED;
        color: #606266;
        
        &:hover {
          color: #3F63EC;
          border-color: #3F63EC;
          background-color: rgba(63, 99, 236, 0.04);
        }
      }
      
      &--primary {
        background: linear-gradient(135deg, #3F63EC, #093267);
        border: none;
        
        &:hover {
          opacity: 0.9;
        }
        
        &.is-loading {
          opacity: 0.8;
          pointer-events: none;
        }
      }
    }
  }
}

.mt15 {
  margin-top: 2rem;
}

:deep(.el-input-group__prepend) {
  padding: 0;
  border-right: none;
  background-color: transparent;
}

:deep(.el-select) {
  width: 12rem;
  
  .el-input {
    width: 100%;
    
    .el-input__inner {
      border: none;
      background: transparent;
      padding-right: 2rem;
      color: #333;
      font-weight: 500;
      
      &:hover {
        background: transparent;
      }
    }
    
    .el-input__suffix {
      right: 0.5rem;
    }
  }
}

:deep(.area-code-select) {
  .el-select-dropdown__item {
    padding: 0 1.2rem;
    height: 4rem;
    line-height: 4rem;
    
    &.selected {
      color: #3F63EC;
      font-weight: 500;
    }
  }
}

.area-code-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  .country-name {
    color: #333;
    font-size: 1.4rem;
  }
  
  .area-code {
    color: #666;
    font-size: 1.3rem;
  }
}

:deep(.el-input) {
  &.el-input--prefix {
    .el-input__inner {
      padding-left: 12rem;
    }
  }
}

.phone-input-wrapper {
  display: flex;
  align-items: center;
  background: #F8FAFC;
  border: 1px solid #E4E7ED;
  border-radius: 0.8rem;
  transition: all 0.3s ease;
  
  &:hover {
    border-color: #C0C4CC;
  }
  
  &:focus-within {
    border-color: #3F63EC;
    background: #fff;
    box-shadow: 0 0 0 2px rgba(63, 99, 236, 0.1);
  }

  :deep(.area-code-select) {
    width: 11rem;
    margin-right: -1px;
    
    .el-input {
      .el-input__inner {
        border: none;
        background: transparent;
        color: #333;
        font-weight: 500;
        padding-left: 1.5rem;
        border-radius: 0.8rem 0 0 0.8rem;
        
        &:hover, &:focus {
          background: transparent;
          box-shadow: none;
        }
      }
      
      .el-input__suffix {
        right: 0.5rem;
      }
    }
  }
  
  :deep(.phone-input) {
    flex: 1;
    
    .el-input__inner {
      border: none;
      background: transparent;
      border-radius: 0 0.8rem 0.8rem 0;
      
      &:hover, &:focus {
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

:deep(.area-code-dropdown) {
  .el-select-dropdown__item {
    padding: 0 1.2rem;
    height: 4rem;
    line-height: 4rem;
    
    &.selected {
      color: #3F63EC;
      font-weight: 500;
    }
  }
}

.area-code-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  .country-name {
    color: #333;
    font-size: 1.4rem;
  }
  
  .area-code {
    color: #666;
    font-size: 1.3rem;
  }
}

:deep(.el-input-group__prepend) {
  padding: 0;
  background: #F8FAFC;
  border-color: #E4E7ED;
  
  .area-code {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    user-select: none;
    
    span {
      font-size: 1.4rem;
      color: #333;
      font-weight: 500;
    }
    
    i {
      font-size: 1.2rem;
      color: #909399;
    }
    
    &:hover {
      i {
        color: #333;
      }
    }
  }
}

.area-code-dialog {
  .area-code-list {
    max-height: 400px;
    overflow-y: auto;
    
    .area-code-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem 1.6rem;
      cursor: pointer;
      transition: all 0.2s ease;
      
      &:hover {
        background-color: #F5F7FA;
      }
      
      &.active {
        color: #3F63EC;
        background-color: #ECF5FF;
      }
      
      .country {
        font-size: 1.4rem;
        color: #333;
      }
      
      .code {
        font-size: 1.4rem;
        color: #666;
      }
    }
  }
}
</style>
