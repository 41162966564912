<template>
  <div class="table-copy-wrapper" :data-drag-disabled="!checkDragSelectEnabled()">
    <copy-tip />
    <slot></slot>
  </div>
</template>

<script>
import CopyTip from './components/CopyTip.vue'
import { throttle } from 'lodash'

export default {
  name: 'TableCopy',
  components: {
    CopyTip
  },
  props: {
    // 修改选择器，选择没有 display:none 样式的 tab-pane 中的表格
    tableSelector: {
      type: String,
      default: '.el-tab-pane:not([style*="display: none"]) .el-table__body-wrapper'
    },
    // 是否排除固定列
    excludeFixed: {
      type: Boolean,
      default: true
    },
    // 添加新的 prop 用于排除 selection 列
    excludeSelection: {
      type: Boolean,
      default: true
    },
    // 添加新的 prop
    hasSelection: {
      type: Boolean,
      default: true
    },
    // 添加最大选择范围限制
    maxSelectionCells: {
      type: Number,
      default: 1000
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selecting: false,
      selectionStart: null,
      selectionEnd: null,
      selectedCells: [],
      selectionBox: null,
      scrollLeft: 0,
      scrollTop: 0
    }
  },
  watch: {
    '$slots.default': {
      handler() {
        this.$nextTick(() => {
          this.destroySelection()
          setTimeout(() => {
            this.initSelection()
          }, 0)
        })
      },
      immediate: false
    },
    tableData: {
      handler() {
        this.$nextTick(() => {
          this.clearSelectionState()
        })
      },
      deep: true
    }
  },
  methods: {
    destroySelection() {
      const tableBody = this.$el.querySelector(this.tableSelector)
      if (tableBody) {
        tableBody.removeEventListener('mousedown', this.onMouseDown)
      }
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
      document.removeEventListener('keydown', this.onKeyDown)
      this.selectionBox?.remove()
      this.selectionBox = null
      this.selectedCells = []
      this.selecting = false
      this.selectionStart = null
      this.selectionEnd = null
    },

    checkDragSelectEnabled() {
      try {
        const userSettings = JSON.parse(localStorage.getItem('userSettings'))
        return userSettings?.enableDragSelect === true
      } catch (error) {
        return false
      }
    },

    initSelection() {
      if (!this.checkDragSelectEnabled()) {
        return
      }

      const tableBody = this.$el.querySelector(this.tableSelector)
      if (!tableBody) {
        return
      }

      this.selectionBox = document.createElement('div')
      this.selectionBox.className = 'selection-box'
      tableBody.appendChild(this.selectionBox)

      tableBody.addEventListener('mousedown', this.onMouseDown)
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
      document.addEventListener('keydown', this.onKeyDown)
    },

    onMouseDown(e) {
      if (!this.checkDragSelectEnabled()) {
        return
      }
      
      e.preventDefault()

      const cell = e.target.closest('td')
      if (!cell ||
          (this.excludeFixed && (cell.closest('.el-table__fixed') || cell.closest('.el-table__fixed-right'))) ||
          (this.hasSelection && this.excludeSelection && Array.from(cell.parentElement.children).indexOf(cell) === 0)) return

      this.selecting = true
      this.selectionStart = this.getCellPosition(cell)
      this.selectionEnd = this.selectionStart

      this.scrollLeft = this.$el.querySelector(this.tableSelector).scrollLeft
      this.scrollTop = this.$el.querySelector(this.tableSelector).scrollTop

      this.updateSelection()
    },

    onMouseMove(e) {
      if (!this.checkDragSelectEnabled()) {
        return
      }
      
      if (!this.selecting) return

      const tableBody = this.$el.querySelector(this.tableSelector)
      if (!tableBody) return

      const rect = tableBody.getBoundingClientRect()

      if (e.clientY < rect.top) tableBody.scrollTop -= 10
      if (e.clientY > rect.bottom) tableBody.scrollTop += 10
      if (e.clientX < rect.left) tableBody.scrollLeft -= 10
      if (e.clientX > rect.right) tableBody.scrollLeft += 10

      const cell = tableBody.contains(document.elementFromPoint(e.clientX, e.clientY))
        ? document.elementFromPoint(e.clientX, e.clientY)?.closest('td')
        : null

      if (!cell ||
          (this.excludeFixed && (cell.closest('.el-table__fixed') || cell.closest('.el-table__fixed-right'))) ||
          (this.hasSelection && this.excludeSelection && Array.from(cell.parentElement.children).indexOf(cell) === 0)) return

      this.selectionEnd = this.getCellPosition(cell)
      this.updateSelection()

      // 检查选择范围是否超出限制
      const currentSelection = this.calculateSelectionSize()
      if (currentSelection > this.maxSelectionCells) {
        this.selecting = false
        this.$message.warning(`选择范围过大，最多可选择${this.maxSelectionCells}个单元格`)
        return
      }
    },

    onMouseUp() {
      this.selecting = false
    },

    onKeyDown(e) {
      if (!this.checkDragSelectEnabled()) {
        return
      }
      
      if (e.ctrlKey && e.key === 'c') {
        this.copySelectedCells()
      }

    },

    getCellPosition(cell) {
      const row = cell.parentElement
      let colIndex = Array.from(row.children).indexOf(cell)

      if (this.hasSelection && this.excludeSelection && colIndex === 0) {
        return null
      }

      return {
        row: Array.from(row.parentElement.children).indexOf(row),
        col: colIndex
      }
    },

    copySelectedCells() {
      if (this.selectedCells.length === 0) return

      const startRow = Math.min(...this.selectedCells.map(cell => this.getCellPosition(cell).row))
      const endRow = Math.max(...this.selectedCells.map(cell => this.getCellPosition(cell).row))
      const startCol = Math.min(...this.selectedCells.map(cell => this.getCellPosition(cell).col))
      const endCol = Math.max(...this.selectedCells.map(cell => this.getCellPosition(cell).col))

      const data = []
      const table = this.$el.querySelector(this.tableSelector + ' table')

      for (let i = startRow; i <= endRow; i++) {
        const row = table.rows[i]
        if (!row) continue

        const rowData = []
        for (let j = startCol; j <= endCol; j++) {
          const cell = row.cells[j]
          if (!cell || (this.excludeFixed && cell.closest('.el-table__fixed-right'))) continue
          rowData.push(cell.textContent.trim())
        }
        if (rowData.length > 0) {
          data.push(rowData)
        }
      }

      const text = data.map(row => row.join('\t')).join('\n')

      var target = document.createElement('textarea') // 创建input节点
      target.value = text // 给input的value赋值
      document.body.appendChild(target) // 向页面插入input节点
      target.select() // 选中input
      try {
        document.execCommand('Copy') // 执行浏览器复制命令
        document.body.removeChild(target)
        this.$message.success('复制成功')
      } catch {
        this.$message.error('复制失败')
      }
    },

    throttledUpdateSelection: throttle(function() {
      this.updateSelection()
    }, 16),

    updateSelection() {
      const tableBody = this.$el.querySelector(this.tableSelector)
      if (!tableBody) return

      const selectedCells = tableBody.getElementsByClassName('selected-cell')
      while(selectedCells.length > 0) {
        selectedCells[0].classList.remove('selected-cell')
      }

      if (!this.selectionStart || !this.selectionEnd) return

      const startRow = Math.min(this.selectionStart.row, this.selectionEnd.row)
      const endRow = Math.max(this.selectionStart.row, this.selectionEnd.row)
      const startCol = Math.min(this.selectionStart.col, this.selectionEnd.col)
      const endCol = Math.max(this.selectionStart.col, this.selectionEnd.col)

      const table = tableBody.querySelector('table')
      if (!table) return

      this.selectedCells = []

      for (let i = startRow; i <= endRow; i++) {
        const row = table.rows[i]
        if (!row) continue

        for (let j = startCol; j <= endCol; j++) {
          const cell = row.cells[j]
          if (!cell || (this.excludeFixed && cell.closest('.el-table__fixed-right'))) continue

          cell.classList.add('selected-cell')
          this.selectedCells.push(cell)
        }
      }

      this.updateSelectionBox()
    },

    updateSelectionBox() {
      if (this.selectedCells.length === 0) {
        this.selectionBox.style.display = 'none'
        return
      }

      const tableBody = this.$el.querySelector(this.tableSelector)
      const firstCell = this.selectedCells[0]
      const lastCell = this.selectedCells[this.selectedCells.length - 1]
      const firstRect = firstCell.getBoundingClientRect()
      const lastRect = lastCell.getBoundingClientRect()
      const tableRect = tableBody.getBoundingClientRect()

      const left = firstRect.left - tableRect.left + tableBody.scrollLeft
      const top = firstRect.top - tableRect.top + tableBody.scrollTop

      this.selectionBox.style.display = 'block'
      this.selectionBox.style.left = `${left}px`
      this.selectionBox.style.top = `${top}px`
      this.selectionBox.style.width = `${lastRect.right - firstRect.left}px`
      this.selectionBox.style.height = `${lastRect.bottom - firstRect.top}px`
    },

    clearSelection() {
      this.selectedCells = null
      this.selectionBox = null
      this.selectionStart = null
      this.selectionEnd = null
    },

    calculateSelectionSize() {
      return this.selectedCells.length
    },
    clearSelectionState() {
      const tableBody = this.$el.querySelector(this.tableSelector)
      if (!tableBody) return
      const selectedCells = tableBody.getElementsByClassName('selected-cell')
      while(selectedCells.length > 0) {
        selectedCells[0].classList.remove('selected-cell')
      }
      if (this.selectionBox) {
        this.selectionBox.style.display = 'none'
      }
      this.selecting = false
      this.selectionStart = null
      this.selectionEnd = null
      this.selectedCells = []
      this.scrollLeft = 0
      this.scrollTop = 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSelection()
    })
  },
  beforeDestroy() {
    this.destroySelection()
  }
}
</script>

<style lang="scss" scoped>
.table-copy-wrapper {
  position: relative;

  ::v-deep {
    .el-table {
      td, th {
        // 移除这里的 user-select 相关样式
      }

      td {
        cursor: cell;

        &:hover {
          background-color: rgba(64, 158, 255, 0.05);
        }
      }

      .selected-cell {
        background-color: rgba(64, 158, 255, 0.1) !important;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid rgba(64, 158, 255, 0.2);
          pointer-events: none;
        }
      }
    }

    .selection-box {
      position: absolute;
      border: 2px solid #409EFF;
      background-color: rgba(64, 158, 255, 0.1);
      pointer-events: none;
      z-index: 100;
      box-shadow: 0 0 0 1px rgba(64, 158, 255, 0.2);
    }
  }
}

// 修改禁用状态的样式
.table-copy-wrapper[data-drag-disabled="true"] {
  ::v-deep {
    .el-table {
      td {
        cursor: default;
      }
    }
  }
}

// 添加新的样式规则，只在启用拖拽时禁用文本选择
.table-copy-wrapper[data-drag-disabled="false"] {
  ::v-deep {
    .el-table {
      td, th {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }
  }
}
</style>
