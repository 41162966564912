<template>
  <el-dialog top="5vh" append-to-body :close-on-click-modal="false" :before-close="close" :title="tempTxt[dialogStatus]" width="70%" :visible.sync="show">
    <div class="queryItems mb20">
      <div class="u-start-flex mr15">
        <div class="default-font">SKU编码：</div>
        <el-input v-model="query.SKUCode" size="mini" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">商品名称：</div>
        <el-input v-model="query.Name" size="mini" class="mr15" clearable style="width: 20rem" placeholder="商品名称"></el-input>
      </div>
      <el-button type="primary" class="" size="mini" icon="el-icon-search" @click="getData">查询</el-button>
    </div>
    <el-table
        ref="table"
        :key="dialogStatus"
        @row-click="handleRowClick"
        @selection-change="handleSelectionChange"
        size="mini"
        :data="listData"
        v-loading="loading"
        height="400"
        style="width: 100%"
        border>
      <el-table-column
          v-if="dialogStatus === 'add'"
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column v-else label="" width="80">
        <template slot-scope="scope">
          <div class="state-active" v-if="currentState === scope.row.Id">
            <el-image class="confirm-png" :src="require('@/static/images/cod/confirm.png')"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="图片" width="80">
        <template slot-scope="scope">
          <el-image
              v-if="scope.row.ImagePath"
              class="column-img"
              :preview-src-list="[scope.row.ImagePath]"
              :src="scope.row.ImagePath">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="Name" show-overflow-tooltip></el-table-column>
      <el-table-column label="SKU编码" prop="SKUCode" width="120"></el-table-column>
      <el-table-column label="品类" prop="ProductName" width="120"></el-table-column>
      <el-table-column label="库存" prop="Quantity" width="80"></el-table-column>
      <el-table-column label="尺寸" show-overflow-tooltip width="180">
        <template slot-scope="scope">
          <span>{{ `${scope.row.Length}CM*${scope.row.Width}CM*${scope.row.Height}CM` }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="PageSizes"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <span slot="footer">
      <el-button size="small" type="primary" @click="submitState">
        {{ dialogStatus === 'add' ? '确认添加' : '确认绑定' }}
      </el-button>
      <el-button size="small" @click="close">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCodSKU } from "@/api/cod";
import pagination from "@/utils/mixin/pagination";
export default {
  mixins: [ pagination ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    dialogStatus: {
      type: String,
      default: "create"
    },
    SKUId: {
      type: Number,
      default: 0
    },
    SKUIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      query: {},
      listData: [],
      productOption: [],
      currentState: undefined,
      multipleSelection: [],
      tempTxt: {
        create: "绑定SKU",
        update: "换绑SKU",
        add: "添加商品"
      }
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.resetTemp()
        }
      }
    },
    dialogStatus: {
      handler(val) {
        this.multipleSelection = []
        this.currentState = undefined
        if (val === 'update' && this.SKUId) {
          this.currentState = this.SKUId
        }
        if (this.$refs.table) {
          this.$refs.table.clearSelection()
        }
      },
      immediate: true
    },
    SKUId: {
      handler(val) {
        if (this.dialogStatus === 'update' && val) {
          this.currentState = val
        }
      },
      immediate: true
    }
  },
  computed: {
    currentItem() {
      if (!this.currentState) {
        return {}
      } else {
        return this.listData.filter(item => {
          return item.Id === this.currentState
        })[0]
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    submitState() {
      if (this.dialogStatus === 'add') {
        if (!this.multipleSelection.length) {
          this.$message.warning("请选择SKU")
          return
        }
        this.multipleSelection.forEach(item => {
          this.$emit('handle-submit', {
            GoodsName: item.Name,
            Amount: 1,
            Price: 0,
            AllMoney: 0,
            Img: item.ImagePath,
            SkuId: item.Id,
            SkuDetail: {},
            outBoundAmount: 1
          })
        })
      } else {
        if (!this.currentState) {
          this.$message.warning("请选择SKU")
          return
        }
        this.$emit('handle-click-submit', this.currentState)
      }
    },
    handleRowClick(row) {
      if (this.dialogStatus !== 'add') {
        this.currentState = row.Id
      }
    },
    resetTemp() {
      this.PageIndex = 1
      if (this.dialogStatus !== 'update') {
        this.currentState = undefined
      }
      this.getData()
    },
    getData() {
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        QueryCodeType: 14,
        QueryCodes: this.query.SKUCode ? [this.query.SKUCode] : undefined,
        Name: this.query.Name || undefined
      }
      this.loading = true
      getCodSKU(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          if (this.dialogStatus === 'add') {
            this.listData = Data.filter(item => {
              return !this.SKUIds.includes(item.Id)
            })
          } else {
            const updateArray = this.SKUIds.filter(item => {
              return item !== this.SKUId
            })
            this.listData = Data.filter(item => {
              return !updateArray.includes(item.Id)
            })
          }
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    close() {
      this.$emit('close')
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-dialog {

  .el-dialog__body {
    flex: 1;
    overflow: auto;
    padding: 10px 20px;
  }

  .el-dialog__footer {
    padding: 10px 20px 20px;
    text-align: left;
  }
}

.queryItems {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.column-img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
}

.state-active {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  .confirm-png {
    width: 32px;
    height: 22px;
  }
}

::v-deep .el-table {
  .el-table__header-wrapper {
    th {
      background-color: #f5f7fa;
      color: #606266;
    }
  }

  .el-table__row {
    cursor: pointer;

    &:hover {
      background-color: #f5f7fa;
    }
  }
}

.column-img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
  display: block;
  margin: 5px auto;
}

::v-deep .el-table {
  td {
    padding: 5px 0;
  }
}
</style>
