<template>
  <el-dialog top="5vh" :close-on-click-modal="false" :append-to-body="body" :before-close="close" title="冻结明细" width="65%" :visible.sync="show">
    <el-table
        :data="listData"
        border
        size="mini"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="获取数据中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="订单单号" prop="MainBillCode" align="center"></el-table-column>
      <el-table-column label="订单编号" prop="OrderId" align="center"></el-table-column>
      <el-table-column label="货物名称" prop="GoodsName" align="center"></el-table-column>
      <el-table-column label="Sku编号" prop="SkuId" align="center"></el-table-column>
      <el-table-column label="Sku" prop="Sku" align="center"></el-table-column>
      <el-table-column label="货物数量" prop="Amount" align="center"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getOrderItemDetail } from "@/api/cod";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    body: {
      type: Boolean,
      default: false
    },
    ItemId: {
      type: Number,
      default: 0
    },
    WareHouseId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listData: [],
      loading: false
    }
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    init() {
      this.getData()
    },
    getData() {
      const data = {
        OrderItemId: this.ItemId,
        WareHouseId: this.WareHouseId
      }
      this.loading = true
      getOrderItemDetail(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
.queryItems {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
}
</style>
