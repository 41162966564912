<template>
  <el-dialog top="1vh" :close-on-click-modal="false" :before-close="close" title="创建订单" width="70%" :visible.sync="show" class="place-order-dialog">
    <el-form size="mini" ref="ruleForm" label-width="80px" :rules="rules" label-position="left" :model="form">
      <div class="head-title">
        <div class="line"></div>
        <div>SKU信息</div>
      </div>
      <div v-if="!isEmpty">
        <div class="multiple-box">
          <div class="sku-item" v-for="(item, i) in multipleData" :key="item.Id">
            <div class="item">
              <div class="label">SKU编码</div>
              <div class="value">{{ item.SKUCode }}</div>
            </div>
            <div class="item">
              <div class="label">名称</div>
              <div class="value">{{ item.Name }}</div>
            </div>
            <el-form-item label="出货数量" :prop="item.SKUCode">
              <el-input v-model="form.SKUAddOrderInfos[i].Quantity" type="number"></el-input>
            </el-form-item>
            <div style="width: 100%; text-align: center" v-if="!expands[item.SKUCode]">
              <el-button @click="handleChangeExpand(item.SKUCode)" size="small" type="text">
                展开详细信息
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </div>
            <div v-if="expands[item.SKUCode]">
              <div class="item">
                <div class="label">尺寸</div>
                <div class="value">{{ `${item.Length}*${item.Width}*${item.Height}` }}</div>
              </div>
              <div class="item">
                <div class="label">尺码</div>
                <div class="value">{{ item.Size }}</div>
              </div>
              <div class="item">
                <div class="label">规格</div>
                <div class="value">{{ item.Specification }}</div>
              </div>
              <div class="item">
                <div class="label">型号</div>
                <div class="value">{{ item.ModelNumber }}</div>
              </div>
              <div class="item">
                <div class="label">重量</div>
                <div class="value">{{ item.Weight }}</div>
              </div>
              <div class="item" v-if="item.ImagePath">
                <div class="label">图片</div>
                <el-image class="sku-img" :preview-src-list="[item.ImagePath]" :src="item.ImagePath"></el-image>
              </div>
              <div class="item">
                <div class="label rem">备注</div>
                <div class="rem">{{ item.Rem }}</div>
              </div>
            </div>
            <div style="width: 100%; text-align: center" v-if="expands[item.SKUCode]">
              <el-button @click="handleChangeExpand(item.SKUCode)" size="small" type="text">
                收起详细信息
                <i class="el-icon-arrow-up el-icon--right"></i>
              </el-button>
            </div>
          </div>
        </div>
        <div class="u-end-flex mt20 mb20 total-text">
          <div class="mr15">总数量：{{ totalCount.count }}</div>
          <div class="mr15">总重量：{{ totalCount.weight && totalCount.weight.toFixed(2) + 'KG' }}</div>
        </div>
      </div>
      <div v-else>
        <el-empty :image-size="60" description="未选择SKU"></el-empty>
      </div>
      <div class="head-title">
        <div class="line"></div>
        <div>{{ $t('mergerCard.addressInfo') }}</div>
      </div>
      <address-select ref="addr" @refresh="init" @select-address="selectAddress" :options="AddrOptions"></address-select>
      <div class="head-title">
        <div class="line"></div>
        <div>{{ $t('mergerCard.delivery') }}</div>
      </div>
      <div class="u-between-flex">
        <el-form-item label="时效" prop="TimerId" style="flex-basis: 45%">
          <el-select size="small" style="width: 100%;" v-model="form.TimerId" placeholder="请选择时效">
            <el-option
                v-for="item in TimerOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="承运商" prop="CarrierId" style="flex-basis: 45%">
          <el-select size="small" style="width: 100%;" v-model="form.CarrierId" placeholder="请选择承运商">
            <el-option
                v-for="item in CarrierOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="u-between-flex">
        <el-form-item label="门店号码" prop="StoreNumber" style="flex-basis: 45%">
          <el-input size="small" v-model="form.StoreNumber"></el-input>
        </el-form-item>
        <el-form-item label="门店名称" prop="StoreName" style="flex-basis: 45%">
          <el-input size="small" v-model="form.StoreName"></el-input>
        </el-form-item>
      </div>
      <div class="head-title">
        <div class="line"></div>
        <div>货物信息</div>
      </div>
      <div class="u-between-flex">
        <el-form-item label="货物类型" prop="GoodsTypeId" style="flex-basis: 45%">
          <el-select size="small" style="width: 100%;" v-model="form.GoodsTypeId" placeholder="请选择货物类型">
            <el-option
                v-for="item in GoodsTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库" prop="WareHouseId" style="flex-basis: 45%">
          <el-select size="small" style="width: 100%;" v-model="form.WareHouseId" placeholder="请选择仓库">
            <el-option
                v-for="item in WareHouseOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="u-between-flex">
        <el-form-item :label="$t('mergerCard.GoodsName')" prop="GoodsName" style="flex-basis: 45%">
          <el-input size="small" v-model="form.GoodsName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('mergerCard.GoodsName_En')" prop="GoodsName_En" style="flex-basis: 45%">
          <el-input size="small" v-model="form.GoodsName_En"></el-input>
        </el-form-item>
      </div>
      <div class="head-title">
        <div class="line"></div>
        <div>优惠选项</div>
      </div>
      <div class="u-between-flex">
        <el-form-item label="代收货款" prop="CollectionAmount" style="flex-basis: 45%">
          <el-input type="number" size="small" v-model="form.CollectionAmount"></el-input>
        </el-form-item>
        <el-form-item label="是否包税" prop="IsTax" style="flex-basis: 45%">
          <el-select style="width: 100%;" size="small" v-model="form.IsTax" placeholder="请选择是否包税">
            <el-option
                v-for="item in TaxOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button v-loading="loading" type="primary" :disabled="loading" @click="submitForm">
        {{ loading ? '提交中...' : '提交订单' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import AddressSelect from "@/components/AddressSelect.vue";
import {getCarrier, getGoodsType, getTimer} from "@/api/system";
import {placeOrder} from "@/api/cod";

export default {
  components: { AddressSelect },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    multipleData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        SKUAddOrderInfos: []
      },
      expands: {},
      loading: false,
      rules: {
        ClientAddressId: [
          { required: true, message: '请选择收货地址', trigger: 'change' }
        ],
        TimerId: [
          { required: true, message: '请选择时效', trigger: 'change' }
        ],
        CarrierId: [
          { required: true, message: '请选择承运商', trigger: 'change' }
        ],
        GoodsTypeId: [
          { required: true, message: '请选择货物类型', trigger: 'change' }
        ],
        WareHouseId: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        GoodsName: [
          { required: true, message: '请输入货物名称', trigger: 'blur' }
        ],
        IsTax: [
          { required: true, message: '请选择是否包税', trigger: 'change' }
        ]
      },
      CarrierOptions: [],
      TimerOption: [],
      AddrOptions: [],
      GoodsTypeOptions: [],
      WareHouseOptions: []
    }
  },
  computed: {
    TaxOption() {
      return [
        {
          label: this.$t('mergerCard.yes'),
          value: true
        },
        {
          label: this.$t('mergerCard.no'),
          value: false
        }
      ]
    },
    isEmpty() {
      return !this.form.SKUAddOrderInfos.length
    },
    currentDestinationId() {
      if (this.form.ClientAddressId) {
        return this.AddrOptions.filter(item => {
          return item.Id === this.form.ClientAddressId
        })[0].DestinationId
      } else {
        return undefined
      }
    },
    totalCount() {
      let count = 0;
      let weight = 0;
      this.multipleData.forEach(item => {
        count += 1;
        weight += item.Weight;
      })
      return {
        count,
        weight
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.$nextTick(() => {
          let applyArray = []
          this.multipleData.forEach(item => {
            applyArray.push({
              SKUId: item.Id,
              Quantity: 0
            })
            this.rules[item.SKUCode] = [
              {validator: this.checkRange, trigger: ['blur']}
            ];
            this.$set(this.expands, item.SKUCode, false)
          })
          this.$set(this.form, 'SKUAddOrderInfos', applyArray);
          this.init()
          this.getTimer()
          this.getWareHouse()
          this.getGoodsType()
          if (!this.isEmpty) {
            this.form.GoodsName = this.multipleData.map(item => { return item.Name }).join(',')
          }
        })
      }
    }
  },
  methods: {
    checkRange(rule, value, callback) {
      const idx = this.multipleData.findIndex(item => item.SKUCode === rule.field);
      const realValue = this.form.SKUAddOrderInfos[idx].Quantity
      if (!realValue) {
        callback(new Error('请输入出货数量'))
      }
      callback();
    },
    handleChangeExpand(code) {
      this.$set(this.expands, code, !this.expands[code])
      this.$forceUpdate()
    },
    init() {
      return new Promise((rs) => {
        this.$store.dispatch('webSite/getAddress').then(data => {
          this.AddrOptions = data;
          this.AddrOptions.sort((a, b) => {
            if (a.IsDefaultAddress && !b.IsDefaultAddress) return -1;
            if (!a.IsDefaultAddress && b.IsDefaultAddress) return 1;
            return 0;
          });
          const defaults = this.AddrOptions.filter(item => { return item.IsDefaultAddress })
          if (defaults.length) {
            this.$refs.addr.onNav(defaults[0])
          } else if (this.AddrOptions.length) {
            this.$refs.addr.onNav(this.AddrOptions[0])
          } else {
            rs(false)
          }
          rs(true)
        })
      })
    },
    getTimer() {
      return new Promise((rs) => {
        const data = {
          PageIndex: 1,
          PageRows: 100
        }
        getTimer(data).then(response => {
          const { Data } = response
          this.TimerOption = Data.map(item => {
            return { label: item.Name, value: item.Id }
          })
          this.$set(this.form, 'TimerId', Data[0].Id)
          rs(true)
        })
      })

    },
    selectAddress(itemId) {
      this.$set(this.form, 'ClientAddressId', itemId)
      this.resetCarrier()
    },
    resetCarrier() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
        DestinationId: this.currentDestinationId,
      }
      getCarrier(data).then(response => {
        this.$set(this.form, 'CarrierId', undefined)
        this.CarrierOptions = response.Data.map(item => {
          return { label: item.Name, value: item.Id }
        })
      })
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.WareHouseOptions = data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.GoodsTypeOptions = Data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {
            GoodsName: this.form.GoodsName,
            GoodsName_En: this.form.GoodsName_En,
            WareHouseId: this.form.WareHouseId,
            GoodsTypeId: this.form.GoodsTypeId,
            CarrierId: this.form.CarrierId,
            TimerId: this.form.TimerId,
            ClientAddressId: this.form.ClientAddressId,
            CollectionAmount: this.form.CollectionAmount || 0,
            IsTax: this.form.IsTax,
            SKUAddOrderInfos: this.form.SKUAddOrderInfos,
            StoreNumber: this.form.StoreNumber,
            StoreName: this.form.StoreName
          }
          this.loading = true;
          placeOrder(data).then(response => {
            const { Code, Msg } = response
            if (Code === 200) {
              this.$message.success("下单成功")
              this.close()
            } else {
              this.$message.warning(Msg)
            }
          }).finally(() => {
            this.loading = false;
          })
        } else {
          this.$message.warning('请填写必填项')
          return false;
        }
      });
    },
    close() {
      this.$emit('close')
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .el-dialog__header {
    background: #f8f9fa;
    padding: 15px 20px;
    border-bottom: 1px solid #ebeef5;
    margin: 0;

    .el-dialog__title {
      font-size: 16px;
      font-weight: 600;
      color: #303133;
    }
  }

  .el-dialog__body {
    padding: 0 15px;
  }
}

::v-deep .el-form {
  .el-form-item {
    margin-bottom: 20px;

    .el-form-item__label {
      font-size: 14px;
      color: #606266;
      font-weight: 500;
    }

    .el-input__inner {
      border-radius: 4px;
      &:hover, &:focus {
        border-color: #409EFF;
      }
    }
  }
}

.multiple-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;

  .sku-item {
    background: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    &:hover {
      //transform: translateY(-2px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

      .absolute-icon {
        opacity: 1;
      }
    }

    .absolute-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      opacity: 0;
      transition: all 0.3s ease;
      background: rgba(255, 255, 255, 0.9);
      padding: 4px;
      border-radius: 50%;
      color: #f56c6c;

      &:hover {
        background: #f56c6c;
        color: white;
      }
    }

    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 12px;

      .label {
        min-width: 80px;
        color: #909399;
        font-size: 14px;
        line-height: 1.5;
        padding-right: 12px;
      }

      .value {
        flex: 1;
        color: #303133;
        font-size: 14px;
        line-height: 1.5;
        word-break: break-all;
      }

      .sku-img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .add-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    border: 2px dashed #dcdfe6;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: #409EFF;
      background: rgba(64, 158, 255, 0.05);

      .add-item-icon {
        color: #409EFF;
        transform: scale(1.1);
      }
    }

    .add-item-icon {
      font-size: 40px;
      color: #909399;
      transition: all 0.3s ease;
    }
  }
}

.head-title {
  display: flex;
  align-items: center;
  margin: 25px 0 15px;
  position: relative;

  .line {
    width: 4px;
    height: 18px;
    border-radius: 2px;
    background: linear-gradient(to bottom, #E4393C, #ff6b6b);
    margin-right: 10px;
  }

  div:last-child {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
    letter-spacing: 0.5px;
  }
}

::v-deep .el-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

  th {
    background-color: #f8f9fa !important;
    font-weight: 600;
    color: #303133;
  }

  td {
    padding: 8px 0;
  }
}

.sku-img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  object-fit: cover;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.15);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
}

.total-text {
  font-size: 15px;
  font-weight: 600;
  color: #E4393C;
  background: #fff5f5;
  padding: 12px 20px;
  border-radius: 6px;
  margin: 15px 0;

  div {
    position: relative;
    padding-left: 15px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #E4393C;
    }
  }
}

.dialog-footer {
  padding-top: 20px;
  border-top: 1px solid #ebeef5;

  .el-button {
    padding: 10px 35px;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.3s ease;

    &.el-button--primary {
      background: linear-gradient(to right, #E4393C, #ff6b6b);
      border: none;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(228, 57, 60, 0.3);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .u-between-flex {
    flex-direction: column;

    .el-form-item {
      flex-basis: 100% !important;
    }
  }
}

::v-deep .el-empty {
  padding: 20px 0;

  .el-empty__description {
    margin-top: 10px;
    color: #909399;
    font-size: 14px;
  }
}
</style>
