import {loginAPI, getInfo, register, flashToken} from '@/api/user'
import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken, removeRefreshToken } from '@/utils/auth'
import router from '@/router'

const state = {
    token: getToken(),
    refresh_token: getRefreshToken(),
    showDialog: false,
    userInfo: {
        IsMianAccount: false,
        Name: '',
        Email: "",
        Level: "",
        LevelName: "",
        TaobaoCode: "",
        PhoneNumber: "",
        TelNumber: "",
        Sex: "",
        Money: "",
        Id: ""
    },
    Integral: 0,
    CouponCount: 0,
    isAuthenticated: !!getToken(),
    isMainAccount: localStorage.getItem('isMainAccount'),
    avatar: '',
    introduction: '',
    roles: []
}



const mutations = {
    SET_INTERNAL: (state, integral) => {
        state.Integral = integral
    },
    SET_COUPON_COUNT: (state, count) => {
        state.CouponCount = count
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_IS_AUTHENTICATED: (state, flag) => {
        state.isAuthenticated = flag
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_USER_INFO: (state, info) => {
        state.userInfo = info
    },
    SET_REFRESH_TOKEN:(state, token) =>{
        state.refresh_token = token
    },
    SET_SHOW_DIALOG: (state, flag) => {
        state.showDialog = flag
    },
    SET_MAIN_ACCOUNT: (state, bool) => {
        localStorage.setItem('isMainAccount', bool)
        state.isMainAccount = bool
    }
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            // 解构所有可能的参数
            const { username, password, code, ClientId, phone } = userInfo
            
            // 根据是否有 phone 参数来判断是手机验证码登录还是账号密码登录
            const loginData = phone ? {
                // PhoneNumber: phone,
                Code: code,
                ClientId: ClientId,
                LogoType: 2,
                // DestinationId: DestinationId
            } : {
                userName: username?.trim(),
                password: password,
                // Code: code,
                // ClientId: ClientId
            }

            loginAPI(loginData).then(response => {
                const { AccessToken, RefreshToken, IsMianAccount } = response.Data
                commit('SET_MAIN_ACCOUNT', IsMianAccount)
                commit('SET_TOKEN', AccessToken)
                commit('SET_REFRESH_TOKEN', RefreshToken)
                setToken(AccessToken)
                setRefreshToken(RefreshToken)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    backendJump({ commit }, token="") {
        commit('SET_TOKEN', token)
        setToken(token)
    },

    // eslint-disable-next-line no-unused-vars
    register({ commit, rootState }, userInfo) {
        const { username, password, phone, aws, AgentId,Recommender,email,ServicePersonnel, DestinationId  } = userInfo
        return new Promise((resolve, reject) => {
            register({
                UserName: username.trim(),
                Source: 2,
                Name: username.trim(),
                Password: password,
                AgentId: AgentId || undefined,
                Recommender: Recommender || undefined,
                ServicePersonnel: ServicePersonnel || undefined,
                PhoneNumber: phone || undefined,
                DestinationId: phone ? DestinationId : undefined,
                Email: email,
                TaobaoCode: aws,
                TenantId: rootState.webSite.TenantId,
                ShopId: rootState.webSite.shopID }).then(response => {
                const { Code } = response
                if (Code === 200) {
                    resolve()
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo().then(response => {
                const { Data } = response
                const { Name, Email, Level, LevelName, Money, Id, Integral, CouponCount, HostPortrait,
                        TaobaoCode, Sex, PhoneNumber, TelNumber, UserName, WithdrawalMoney, DestinationId,
                        IsFirstLogin
                      } = Data
                commit('SET_USER_INFO', { Name, Email, Level, LevelName, Money,Id, Sex, HostPortrait,
                        TaobaoCode, PhoneNumber, TelNumber, UserName, WithdrawalMoney, DestinationId, IsFirstLogin })
                commit('SET_INTERNAL', Integral)
                commit('SET_COUPON_COUNT', CouponCount)
                commit('SET_IS_AUTHENTICATED', true)
                resolve(true)
            }).catch(error => {
                reject(error)
            })
        })
    },

    redirectLogin() {
        router.push({ path: '/login' })
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            commit('SET_USER_INFO', {})
            commit('SET_INTERNAL', 0)
            commit('SET_COUPON_COUNT', 0)
            commit('SET_IS_AUTHENTICATED', false)
            removeToken()
            removeRefreshToken()
            router.replace('/')
            resolve()
        })
    },

    // remove token
    resetToken({ state, commit }) {
        return new Promise((resolve, reject) => {
            if (!state.token || !state.refresh_token) {
                reject()
            } else {
                const data = {
                    AccessToken: state.token,
                    RefreshToken: state.refresh_token
                }
                flashToken(data).then(response => {
                    const { Code, Msg, Data } = response
                    if (Code === 200) {
                        const { AccessToken, RefreshToken } = Data
                        commit('SET_TOKEN', AccessToken)
                        commit('SET_REFRESH_TOKEN', RefreshToken)
                        setToken(AccessToken)
                        setRefreshToken(RefreshToken)
                        resolve()
                    } else {
                        reject(Msg)
                    }
                })
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
