import * as pdfLib from "pdf-lib";
import printJS from "print-js";

function base64ToArrayBuffer(base64) {
  let binaryString = ''
  if (base64.indexOf('data:') === 0) {
    const arr = base64.split(',')
    binaryString = window.atob(arr[1])
  } else {
    binaryString = window.atob(base64)
  }

  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

// 二进制转base64
function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  // 对二进制字符串进行Base64编码
  return window.btoa(binary)
}
export async function exportOrder(Data) {
  const pdfDocument = await pdfLib.PDFDocument.create()
  for (const data of Data.map(item => { return item.File })) {
    const arrayBuffer = await base64ToArrayBuffer(data)
    const pdfDoc = await pdfLib.PDFDocument.load(arrayBuffer)
    const copyPage = await pdfDocument.copyPages(pdfDoc, pdfDoc.getPageIndices())
    copyPage.forEach(item => {
      pdfDocument.addPage(item)
    })
  }
  const pdf = await pdfDocument.save()
  const pdfFile = arrayBufferToBase64(pdf)
  printJS({
    printable: pdfFile,
    type: 'pdf',
    base64: true
  })
}
