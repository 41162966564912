<template>
  <el-drawer
      title="选择商品SKU"
      :visible.sync="drawer"
      :direction="direction"
      append-to-body
      :before-close="handleClose">
    <div class="drawer-container">
      <div class="query-items">
        <el-input v-model="query.SKUCode" size="mini" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
        <el-input v-model="query.Name" class="mr15" size="mini" clearable style="width: 20rem" placeholder="名称"></el-input>
        <el-button type="primary" class="" size="small" icon="el-icon-search" @click="handleSearch">查询</el-button>
      </div>
      <div class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
        <el-checkbox-group v-model="checkedSKU" @change="handleCheckedSKUChange">
          <el-card class="box-card" v-for="item in listData" :key="item.Id">
            <el-checkbox :label="item.Id">
              <div class="u-start-flex">
                <el-image class="sku-img mr10" :preview-src-list="[item.ImagePath]" :src="item.ImagePath">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div>
                  <div class="item u-start-flex">
                    <div style="width: 30rem">SKU编码：{{ item.SKUCode }}</div>
                    <div>{{ item.Name }}</div>
                  </div>
                  <div class="item u-start-flex">
                    <div style="width: 30rem">尺寸：{{ `${item.Length}cm*${item.Width}cm*${item.Height}cm` }}</div>
                    <div>尺码：{{ item.Size }}</div>
                  </div>
                  <div class="item u-start-flex">
                    <div style="width: 30rem">规格：{{ item.Specification }}</div>
                    <div>型号：{{ item.ModelNumber }}kg</div>
                  </div>
                </div>
              </div>
            </el-checkbox>
          </el-card>
        </el-checkbox-group>
        <div class="load-more" v-if="loading">加载中...</div>
        <div class="load-more" v-if="noMore && listData.length > 0">没有更多数据了</div>
      </div>
      <div class="drawer-footer">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
          全选 已选中：{{ checkedSKU.length }}
        </el-checkbox>
        <el-button @click="submitCheckBox" type="primary" size="small">提交</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {getCODProduct, getCodSKU} from "@/api/cod";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    SKUIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      direction: 'rtl',
      checkedSKU: [],
      listData: [],
      checkAll: false,
      isIndeterminate: true,
      query: {},
      productOption: [],
      pageIndex: 1,
      pageSize: 50,
      loading: false,
      noMore: false
    }
  },
  computed: {
    checkedSKUList() {
      return this.listData.filter(item => {
        return this.checkedSKU.includes(item.Id)
      })
    }
  },
  watch: {
    SKUIds: {
      handler() {
        this.load()
      },
      immediate: true
    }
  },
  mounted() {
    this.getCodProduct()
  },
  methods: {
    handleCheckedSKUChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listData.length;
    },
    handleCheckAllChange(val) {
      this.checkedSKU = val ? this.listData.map(item => { return item.Id }) : [];
      this.isIndeterminate = false
    },
    handleClose() {
      this.$emit('close')
    },
    submitCheckBox() {
      if (!this.checkedSKU.length) {
        this.$message.warning("请选择SKU")
      } else {
        this.$emit("handleCheck", this.checkedSKUList)
      }
    },
    getCodProduct() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
      }
      getCODProduct(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.productOption = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    load() {
      if (this.loading || this.noMore) return

      this.loading = true
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        QueryCodeType: 14,
        QueryCodes: this.query.SKUCode ? [this.query.SKUCode] : undefined,
        Name: this.query.Name || undefined
      }

      getCodSKU(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          const filteredData = Data.filter(item => !this.SKUIds.includes(item.Id))

          if (this.pageIndex === 1) {
            this.listData = filteredData
          } else {
            this.listData = [...this.listData, ...filteredData]
          }

          this.noMore = filteredData.length < this.pageSize

          if (filteredData.length > 0) {
            this.pageIndex++
          }

          if (this.pageIndex === 1) {
            this.checkedSKU = []
            this.checkAll = false
          }
        } else {
          this.$message.warning(Msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSearch() {
      this.pageIndex = 1
      this.noMore = false
      this.listData = []
      this.load()
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
.drawer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.query-items {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
}
.infinite-list {
  width: 95%;
  flex: 1;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  .box-card {
    margin: 10px 0;
    ::v-deep .el-checkbox {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      .el-checkbox__label {
        width: 100%;
      }
    }
    .u-start-flex {
      width: 100%;
      min-width: 0;
    }
    .item {
      font-size: 12px;
      > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .sku-img {
      width: 36px;
      height: 36px;
      border-radius: 5px;
      flex-shrink: 0;
    }
  }
  .load-more {
    text-align: center;
    padding: 10px 0;
    color: #909399;
    font-size: 14px;
  }
}
.drawer-footer {
  width: 95%;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
</style>
