<template>
  <transition name="fade">
    <div v-if="visible" class="copy-tip">
      <div class="tip-content">
        <i class="el-icon-info-circle"></i>
        <span>提示：可以通过拖拽选择表格内容，按 Ctrl+C 复制</span>
      </div>
      <div class="tip-footer">
        <el-checkbox v-model="notShowToday">今天不再提示</el-checkbox>
        <el-button type="text" size="small" @click="handleClose">关闭</el-button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CopyTip',
  data() {
    return {
      visible: true,
      notShowToday: false
    }
  },
  created() {
    // 检查是否应该显示提示
    this.checkTipStatus()
  },
  methods: {
    checkTipStatus() {
      const tipStatus = localStorage.getItem('tableCopyTip')
      if (tipStatus) {
        const { hidden, expireTime } = JSON.parse(tipStatus)
        this.visible = !hidden || Date.now() >= expireTime
      }
    },
    handleClose() {
      if (this.notShowToday) {
        // 存储状态到 localStorage
        const today = new Date().setHours(0, 0, 0, 0)
        localStorage.setItem('tableCopyTip', JSON.stringify({
          hidden: true,
          expireTime: today + 24 * 60 * 60 * 1000 // 明天零点过期
        }))
      }
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-tip {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 2000;
  background: #fff;
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 360px;
  border: 1px solid #ebeef5;

  .tip-content {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: #303133;
    
    i {
      color: #409EFF;
      font-size: 20px;
    }
  }

  .tip-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .el-checkbox {
      font-size: 13px;
      color: #606266;
    }

    .el-button {
      padding: 0 12px;
      font-size: 13px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style> 