<template>
  <div class="cashier-dialog">
    <el-dialog
        :title="$t('cashier.paymentMethod')"
        :visible.sync="show"
        center
        top="5vh"
        @close="clear"
        :show-close="false"
        custom-class="compact-dialog"
        :close-on-click-modal="false">
      <template slot="title">
        <div class="head">
          <div>{{ title }}</div>
          <div class="pointer"><i style="color: #D8D9DB; font-size: 2.2rem" class="el-icon-close" @click="closeDialog"></i></div>
        </div>
      </template>
      <template slot="footer">
        <div class="bottom" v-if="current===1">
          <el-button type="primary" v-loading="loadingBtn" @click="prepay">{{ $t('cashier.payNow') }}</el-button>
          <el-button v-loading="loadingBtn" @click="closeDialog" plain>{{ $t('cashier.cancel') }}</el-button>
        </div>
      </template>
      <div class="body" v-loading="loading">
        <payment-card :order="order" :recharge-number="rechargeNumber" :order-type="orderType" @radio-change="radioChange" v-if="current===1"></payment-card>
        <qrcode-pay ref="child" :pay-type-data="payParams" :methods="method" @close="closeDialog" :order-type="orderType" :order="order" :describe="payParams.payType" :qr-code-url="qrCodeUrl" @back="back" v-if="current===2"></qrcode-pay>
      </div>
      <div v-html="body" v-show="false"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderDetail } from "@/api/member";
import PaymentCard from "@/components/PaymentCard.vue";
import QrcodePay from "@/components/QrcodePay.vue";
import { Payment } from "@/api/system";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderNo: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    rechargeNumber: {
      type: Number,
      default: 0
    },
    orderType: {
      type: String,
      default: "goods"
    }
  },
  components: {
    PaymentCard,
    QrcodePay
  },
  computed: {
  },
  watch: {
    orderNo(newVal) {
      if (newVal) {
        this.getData()
      }
    },
    show(newVal) {
      if (newVal) {
        this.$store.dispatch('webSite/getPayTypes')
        this.method = "onLine"
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingBtn: false,
      order: {},
      current: 1,
      payParams: {},
      qrCodeUrl: "",
      body: "",
      method: "onLine"
    }
  },
  methods: {
    back() {
      this.current = 1;
    },
    clear() {
      if (this.$refs.child && this.$refs.child.interval) {
        this.$refs.child.clearIntervalToParent()
        this.$refs.child.payStateHandle()
      }
      this.current = 1;
    },
    radioChange(params) {
      this.payParams = params
    },
    async prepay() {
      if (!this.payParams.payType) {
        this.$message.warning(this.$t('cashier.warning'))
      } else {
        this.loadingBtn = true
        if (this.payParams.payType === 'Wechat') {
          this.qrCodeUrl = await this.getQrcode(1)
          this.loadingBtn = false
          this.current = 2;
        } else if (this.payParams.payType === 'Alipay') {
          this.body = await this.aliPay()
          this.loadingBtn = false
          this.$nextTick(() => {
            document.forms['submit'].submit();
          })
        } else if (this.payParams.payType === 'Offline') {
          this.loadingBtn = false
          if (this.payParams.url) {
            window.open(this.payParams.url, '_blank');
          }
          this.method = "offline"
          this.payParams['Money'] = this.order.Receivables || this.rechargeNumber
          this.current = 2
        } else {
          this.balancePay()
        }
      }
    },
    aliPay() {
      const data = {
        OutTradeNo: this.order.MainBillCode,
        Description: this.payParams.payType,
        Money: this.order.Receivables || this.rechargeNumber,
        PayTypeId: this.payParams.payTypeId,
        OrderId: this.order.OrderId,
        PayModeType: 6
      }
      return new Promise((resolve, reject) => {
        Payment(data).then(res => {
          const { Code, Msg, Data } = res
          if (Code === 200) {
            resolve(Data.Body)
          } else {
            reject(Msg)
          }
        })
      })
    },
    balancePay() {
      if (this.payParams.payType === 'Balance') {
        this.$confirm(this.$t('cashier.confirmBalance', { Receivables: this.order.Receivables }), this.$t('cashier.prompt'), {
          confirmButtonText: this.$t('cashier.confirm'),
          cancelButtonText: this.$t('cashier.cancel'),
          type: 'warning'
        }).then(() => {
          const data = {
            OutTradeNo: this.order.MainBillCode,
            Description: this.payParams.payType,
            Money: this.order.Receivables,
            PayTypeId: this.payParams.payTypeId,
            OrderId: this.order.OrderId,
            PayModeType: 8
          }
          Payment(data).then(response => {
            this.loadingBtn = false
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message({
                type: 'success',
                message: this.$t('cashier.successPayment')
              });
              if (this.orderType === 'order') {
                this.closeDialog("refresh")
              } else {
                this.closeDialog()
              }
            } else {
              this.$message({
                type: 'warning',
                message: Msg
              });
            }
          }).catch(() => { this.loadingBtn = false })
        }).catch(() => {
          this.loadingBtn = false
          this.$message({
            type: 'info',
            message: this.$t('cashier.cancelDelete')
          });
        });
      } else {
        const data = {
          OutTradeNo: this.order.MainBillCode,
          Description: this.payParams.payType,
          Money: this.order.Receivables,
          PayTypeId: this.payParams.payTypeId,
          OrderId: this.order.OrderId,
          PayModeType: 8
        }
        Payment(data).then(response => {
          this.loadingBtn = false
          const { Code, Msg, Success } = response
          if (Code === 200 && Success) {
            this.$message.success(this.$t('cashier.successPayment'));
            if (this.orderType === 'order') {
              this.closeDialog("refresh")
            } else {
              this.closeDialog()
            }
          } else {
            this.$message.warning(Msg)
          }
        }).catch(() => { this.loadingBtn = false })
      }
    },
    getData() {
      const data = {
        MainBillCode: this.orderNo
      }
      this.loading = true
      getOrderDetail(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.order = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getQrcode(PayModeType) {
      const data = {
        OutTradeNo: this.order.MainBillCode,
        Description: this.payParams.payType,
        Money: this.order.Receivables || this.rechargeNumber,
        PayTypeId: this.payParams.payTypeId,
        OrderId: this.order.OrderId,
        PayModeType: PayModeType
      }
      return new Promise((resolve, reject) => {
        Payment(data).then(res => {
          const { Code, Msg, Data } = res
          if (Code === 200) {
            this.order.Receivables = this.order.Receivables || this.rechargeNumber;
            this.order.MainBillCode = Data.OutTradeNo
            resolve(Data.Qrcode)
          } else {
            reject(Msg)
          }
        })
      })
    },
    closeDialog(flag = "") {
      this.$emit('close', flag)
    }
  }
}

</script>

<style lang="scss" scoped>
.head {
  padding: 1.2rem 1.5rem;
  font-size: 1.6rem;
  color: #454C5B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EAEDF5;
}
.bottom {
  width: 85%;
  margin: 1.5rem auto 0;
  text-align: center;
  :deep(.el-button) {
    min-width: 120px;
    margin: 0 10px;
  }
}
.body {
  width: 100%;
  padding: 1rem 0;
}
.cashier-dialog {
  :deep(.el-dialog) {
    max-width: 580px;
    .el-dialog__body {
      padding: 15px 20px;
    }
    .el-dialog__footer {
      padding: 0 20px 20px;
    }
    .el-dialog__header {
      padding: 0 0 10px 0;
      border-bottom: none;
    }
    .el-dialog__headerbtn {
      top: 16px;
      right: 16px;
    }
  }
}
.pointer {
  i.el-icon-close {
    font-size: 1.8rem;
    color: #909399;
    transition: color 0.3s;
    padding: 5px;
    cursor: pointer;
    &:hover {
      color: #606266;
    }
  }
}
:deep(.el-loading-mask) {
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
