<template>
  <div class="input-with-select">
    <el-select
      v-model="currentType"
      size="mini"
      class="query-type-select"
      @change="handleTypeChange">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>

    <!-- 单行输入框 -->
    <el-input
      v-model="currentValue"
      size="mini"
      clearable
      @clear="handleClear"
      @focus="handleFocus"
      :placeholder="getPlaceholder"
      class="single-input"
      ref="singleInput">
    </el-input>

    <!-- 弹出的多行文本框 -->
    <el-dialog
      :title="getDialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      custom-class="search-dialog"
      :close-on-click-modal="false"
      @closed="handleDialogClose"
      @opened="handleDialogOpened">
      <div class="dialog-content">
        <div class="search-type-select">
          <span class="label">搜索类型：</span>
          <el-select
            v-model="currentType"
            size="small"
            @change="handleTypeChange">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-input
          type="textarea"
          v-model="tempValue"
          :autosize="{ minRows: 10, maxRows: 10 }"
          ref="multilineInput"
          :placeholder="getDialogPlaceholder"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSearch">搜 索</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 3
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentValue: this.value,
      currentType: this.type,
      dialogVisible: false,
      tempValue: '',
    }
  },
  watch: {
    value(val) {
      this.currentValue = val
    },
    type(val) {
      this.currentType = val
    },
    currentValue(val) {
      // 检测是否包含换行符或逗号,如果有则自动展开多行输入
      if((val.includes('\n') || val.includes(',')) && !this.dialogVisible) {
        this.expandToMultiline(val)
      }
      this.$emit('input', val)
    },
    currentType(val) {
      this.$emit('update:type', val)
    }
  },
  computed: {
    // 根据当前选择的类型获取对应的提示文字
    getPlaceholder() {
      if (!this.placeholder) {
        const currentOption = this.options.find(opt => opt.value === this.currentType)
        return currentOption ? `请输入${currentOption.label}` : ''
      }
      return this.placeholder
    },

    // 获取弹窗标题
    getDialogTitle() {
      const currentOption = this.options.find(opt => opt.value === this.currentType)
      return currentOption ? `批量搜索${currentOption.label}` : '批量搜索'
    },

    // 获取弹窗文本框的placeholder
    getDialogPlaceholder() {
      const currentOption = this.options.find(opt => opt.value === this.currentType)
      return currentOption ?
        `请输入多个${currentOption.label}，每行一个或用逗号分隔` :
        '请输入搜索内容，每行一个或用逗号分隔'
    }
  },
  methods: {
    handleClear() {
      this.$emit('clear')
    },
    handleFocus() {
      this.expandToMultiline(this.currentValue)
      this.$emit('focus')
    },
    handleTypeChange(val) {
      this.currentType = val
      this.$emit('update:type', val)
      this.$emit('type-change', val)
    },
    expandToMultiline(value) {
      this.tempValue = value
      this.dialogVisible = true
    },
    confirmMultiInput() {
      // 直接使用用户输入的内容，不做格式转换
      this.currentValue = this.tempValue
      this.dialogVisible = false
      // 直接触发搜索
      this.$emit('search')
    },
    handleDialogClose() {
      this.tempValue = ''
    },
    handleDialogOpened() {
      // 使用 nextTick 确保 DOM 已更新
      this.$nextTick(() => {
        // 获取文本框 DOM 并聚焦
        const textarea = this.$refs.multilineInput.$refs.textarea
        textarea.focus()
        // 可选：将光标移到文本末尾
        textarea.setSelectionRange(textarea.value.length, textarea.value.length)
      })
    },
    handleSearch() {
      // 先更新值
      this.currentValue = this.tempValue
      // 等待父组件数据更新后再触发搜索
      this.$nextTick(() => {
        // 关闭弹窗
        this.dialogVisible = false
        // 触发搜索
        this.$emit('search')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-with-select {
  display: flex;
  align-items: center;
  width: 380px;

  .query-type-select {
    width: 120px; // 稍微加宽一点以适应文字
    margin-right: -1px;

    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .single-input {
    flex: 1;

    ::v-deep .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// 弹窗样式优化
::v-deep .search-dialog {
  .el-dialog__body {
    padding: 20px;
  }

  .dialog-content {
    .search-type-select {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
        color: #606266;
        font-size: 14px;
      }

      .el-select {
        width: 150px;
      }
    }
  }

  .el-textarea__inner {
    font-family: monospace;
    resize: none;
  }
}
</style>
