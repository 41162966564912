<template>
  <basic-order :order-sources="10"></basic-order>
</template>

<script>
import BasicOrder from "@/components/BasicOrder/index.vue";
export default {
  components: {
    BasicOrder
  }
}

</script>

<style scoped lang="scss">

</style>
