export default {
    data() {
        return {
            drawer: false,
            drawerQuery: {
                dateRange: [],
                timeType: 2
            },
            timeTypeOptions: [
                { label: '下单时间', value: 2 },
                { label: '核重时间', value: 3 },
                { label: '发货时间', value: 12 }
            ],
            drawerListData: [],
        }
    },
    computed: {
        badgeNum() {
            let count = 0;
            for (let key in this.drawerQuery) {
                if (key === 'timeType') {
                    continue
                }
                if (Array.isArray(this.drawerQuery[key])) {
                    if (this.drawerQuery[key].length) {
                        count++;
                    }
                } else {
                    if (this.drawerQuery[key] !== undefined && this.drawerQuery[key] !== '') {
                        count++;
                    }
                }
            }
            return count;
        }
    },
    methods: {
        showFilter() {
            this.drawer = true
        },
        handleConfirm(query) {
            this.drawerQuery = query
            this.handleDrawerClose()
            this.PageIndex = 1
            this.getData()
        },
        handleDrawerClose() {
            this.drawer = false
        },
    }
}
