import { Notification } from 'element-ui';
import Router from "@/router";

export default function showEditNotify() {
    Notification({
        title: '提示',
        type: "warning",
        dangerouslyUseHTMLString: true,
        message: `
            <div>
                <div>当前账户为新账号，为保证您的账号安全，请及时修改密码。</div>
                <div style="color: #409EFF; cursor: pointer; text-decoration: underline; font-style: italic">点击前往更改密码</div>
            </div>
        `,
        duration: 0,  // 消息不会自动关闭
        onClick: function () {
            Notification.closeAll();  // 点击时关闭所有通知
            Router.push({
                name: 'account',
                query: {
                    editPwd: true
                }
            })
        }
    })
}