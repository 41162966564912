import Cookies from 'js-cookie'

const TokenKey = 'Zhanbei-Token'
const RefreshKey = "refresh"

export function getToken() {
    return Cookies.get(TokenKey)
}

export function getRefreshToken() {
    return Cookies.get(RefreshKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { expires: 1 })
}

export function setRefreshToken(token) {
    return Cookies.set(RefreshKey, token, { expires: 1 })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function removeRefreshToken() {
    return Cookies.remove(RefreshKey)
}
