<template>
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    :before-close="close"
    title="批量修改SKU图片"
    :visible.sync="show"
    width="500px"
    class="batch-upload-dialog"
  >
    <div class="upload-container">
      <el-upload
        class="upload-demo"
        action="#"
        :auto-upload="false"
        :on-change="handleFileChange"
        :multiple="true"
        accept="image/*"
        :file-list="fileList"
        :show-file-list="true"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">只能上传图片文件,图片名称为SKU编码</div>
      </el-upload>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleUpload" :loading="uploading">
        开始上传
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { batchUpdateSKUImg } from "@/api/cod"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileList: [],
      uploading: false
    }
  },
  methods: {
    close() {
      this.fileList = []
      this.$emit('close')
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList
    },
    async handleUpload() {
      if (this.fileList.length === 0) {
        this.$message.warning('请选择要上传的图片')
        return
      }

      this.uploading = true
      try {
        const uploadData = await Promise.all(
          this.fileList.map(async file => {
            const base64 = await this.getBase64(file.raw)
            const fileName = file.name.substring(0, file.name.lastIndexOf('.'))
            return {
              SKUCode: fileName,
              ImageBase64: base64
            }
          })
        )

        await batchUpdateSKUImg(uploadData)
        this.$message.success('上传成功')
        this.fileList = []
        this.$emit("refresh")
      } catch (error) {
        console.error('上传失败:', error)
        this.$message.error('上传失败，请重试')
      } finally {
        this.uploading = false
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          // 移除 Base64 字符串中的 data:image/jpeg;base64, 前缀
          const base64String = reader.result.split(',')[1]
          resolve(base64String)
        }
        reader.onerror = error => reject(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.batch-upload-dialog {
  :deep(.el-dialog__body) {
    padding: 20px 20px 0;
  }

  :deep(.el-upload-list) {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 10px;
  }
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100%;
  }
}

.dialog-footer {
  padding: 20px;
  text-align: right;

  .el-button + .el-button {
    margin-left: 12px;
  }
}

.el-upload__tip {
  margin-top: 10px;
  text-align: center;
}
</style>
