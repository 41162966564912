export default {
    data() {
        return {
            allColumns: [
                {
                    label: "订单号",
                    prop: "MainBillCode",
                    disabled: false,
                    sort: 1,
                    width: 180
                },
                {
                    label: "承运单号",
                    prop: "CarrierBillCode",
                    disabled: false,
                    sort: 2,
                    width: '160'
                },
                {
                    label: "发货单号",
                    prop: "CheckCodes",
                    disabled: false,
                    sort: 3,
                    width: '120'
                },
                {
                    label: "店铺名称",
                    prop: "ShopName",
                    disabled: false,
                    sort: 3,
                    width: '120'
                },
                {
                    label: "仓库名称",
                    prop: "WareHouseName",
                    disabled: false,
                    sort: 4,
                    width: '120'
                },
                {
                    label: "客户名称",
                    prop: "ClientName",
                    disabled: false,
                    sort: 5,
                    width: '120'
                },
                {
                    label: "订单状态",
                    prop: "OrderStatu",
                    disabled: false,
                    sort: 6,
                    width: '120'
                },
                {
                    label: "客户等级",
                    prop: "LevelName",
                    disabled: false,
                    sort: 7,
                    width: '120'
                },
                {
                    label: "客户分组",
                    prop: "GroupName",
                    disabled: false,
                    sort: 8,
                    width: '120'
                },
                {
                    label: "货物类型",
                    prop: "GoodsTypeName",
                    disabled: false,
                    sort: 9,
                    width: '120'
                },
                {
                    label: "时效名称",
                    prop: "TimerName",
                    disabled: false,
                    sort: 10,
                    width: '120'
                },
                {
                    label: "承运商",
                    prop: "CarrierName",
                    disabled: false,
                    sort: 11,
                    width: '120'
                },
                {
                    label: "目的地",
                    prop: "DestinationName",
                    disabled: false,
                    sort: 12,
                    width: '120'
                },
                {
                    label: "省份",
                    prop: "ProvinceName",
                    disabled: false,
                    sort: 13,
                    width: '120'
                },
                {
                    label: "城市",
                    prop: "CityName",
                    disabled: false,
                    sort: 14,
                    width: '120'
                },
                {
                    label: "区县",
                    prop: "AreaName",
                    disabled: false,
                    sort: 15,
                    width: '120'
                },
                {
                    label: "派件人",
                    prop: "DeliveryName",
                    disabled: false,
                    sort: 16,
                    width: '120'
                },
                {
                    label: "支付方式",
                    prop: "PayTypeName",
                    disabled: false,
                    sort: 17,
                    width: '120'
                },
                {
                    label: "付款状态",
                    prop: "PayState",
                    disabled: false,
                    sort: 18,
                    width: '96'
                },
                {
                    label: "支付时间",
                    prop: "PayTime",
                    disabled: false,
                    sort: 19,
                    width: '180'
                },
                {
                    label: "收件人",
                    prop: "ReceiveName",
                    disabled: false,
                    sort: 20,
                    width: '120'
                },
                {
                    label: "收件电话",
                    prop: "ReceivePhone",
                    disabled: false,
                    sort: 21,
                    width: '120'
                },
                {
                    label: "收件地址",
                    prop: "ReceiveAddress",
                    disabled: false,
                    sort: 22,
                    width: '180'
                },
                {
                    label: "邮箱",
                    prop: "ReceiveEmail",
                    disabled: false,
                    sort: 22,
                    width: '120'
                },
                {
                    label: "邮政编码",
                    prop: "ReceivePostCode",
                    disabled: false,
                    sort: 22,
                    width: '120'
                },
                {
                    label: "统编",
                    prop: "ReceiveIdCard",
                    disabled: false,
                    sort: 22,
                    width: '120'
                },
                {
                    label: "电话号码",
                    prop: "ReceiveTelephone",
                    disabled: false,
                    sort: 22,
                    width: '120'
                },
                {
                    label: "品名",
                    prop: "GoodsName",
                    disabled: false,
                    sort: 23,
                    width: '120'
                },
                {
                    label: "英文品名",
                    prop: "GoodsName_En",
                    disabled: false,
                    sort: 23,
                    width: '120'
                },
                {
                    label: "申报人名",
                    prop: "DeclareName",
                    disabled: false,
                    sort: 24,
                    width: '120'
                },
                {
                    label: "申报人手机",
                    prop: "DeclarePhone",
                    disabled: false,
                    sort: 24,
                    width: '140'
                },
                {
                    label: "申报人电话号码",
                    prop: "DeclareTelephone",
                    disabled: false,
                    sort: 24,
                    width: '160'
                },
                {
                    label: "申报人地址",
                    prop: "DeclareAddress",
                    disabled: false,
                    sort: 24,
                    width: '160'
                },
                {
                    label: "申报人统编",
                    prop: "DeclareIdCard",
                    disabled: false,
                    sort: 24,
                    width: '160'
                },
                {
                    label: "申报人邮政编码",
                    prop: "DeclarePostCode",
                    disabled: false,
                    sort: 24,
                    width: '180'
                },
                {
                    label: "申报人邮箱",
                    prop: "DeclareEmail",
                    disabled: false,
                    sort: 24,
                    width: '160'
                },
                {
                    label: "申报金额",
                    prop: "DeclareMoney",
                    disabled: false,
                    sort: 24,
                    width: '120'
                },
                {
                    label: "申报单价",
                    prop: "DeclarePrice",
                    disabled: false,
                    sort: 24,
                    width: '120'
                },
                {
                    label: "申报数量",
                    prop: "DeclareAccount",
                    disabled: false,
                    sort: 24,
                    width: '120'
                },
                {
                    label: "订单来源",
                    prop: "OrderSourceName",
                    disabled: false,
                    sort: 24,
                    width: '120'
                },
                {
                    label: "实际重量",
                    prop: "Weight",
                    disabled: false,
                    sort: 25,
                    width: '120'
                },
                {
                    label: "体积重量",
                    prop: "VolumeWeight",
                    disabled: false,
                    sort: 26,
                    width: '120'
                },
                {
                    label: "计费重量",
                    prop: "FareWeight",
                    disabled: false,
                    sort: 27,
                    width: '120'
                },
                {
                    label: "复核重量",
                    prop: "CheckWeight",
                    disabled: false,
                    sort: 28,
                    width: '120'
                },
                {
                    label: "运费",
                    prop: "Freight",
                    disabled: false,
                    sort: 29,
                    width: '120'
                },
                {
                    label: "应收费用",
                    prop: "Receivables",
                    disabled: false,
                    sort: 30,
                    width: '120'
                },
                {
                    label: "物流状态",
                    prop: "TrackStateName",
                    disabled: false,
                    sort: 31,
                    width: '120'
                },
                {
                    label: "审核状态",
                    prop: "AuditStateName",
                    disabled: false,
                    sort: 32,
                    width: '120'
                },
                {
                    label: "拣货状态",
                    prop: "PickingStateName",
                    disabled: false,
                    sort: 33,
                    width: '120'
                },
                {
                    label: "订单备注",
                    prop: "Rem",
                    disabled: false,
                    sort: 34,
                    width: '120'
                },
                {
                    label: "客户备注",
                    prop: "ClientRem",
                    disabled: false,
                    sort: 35,
                    width: '120'
                },
                {
                    label: "创建时间",
                    prop: "CreateTime",
                    disabled: false,
                    sort: 36,
                    width: 180
                },
                {
                    label: "门店名称",
                    prop: "StoreName",
                    disabled: false,
                    sort: 37,
                    width: '120'
                },
                {
                    label: "门店号",
                    prop: "StoreNumber",
                    disabled: false,
                    sort: 38,
                    width: '120'
                },
                {
                    label: "汇率",
                    prop: "ExchangeRate",
                    disabled: false,
                    sort: 39,
                    width: '120'
                },
                {
                    label: "派送方式",
                    prop: "DeliveryMethod",
                    disabled: false,
                    sort: 40,
                    width: '120'
                },
                {
                    label: "核重总体积",
                    prop: "CheckBulkVolume",
                    disabled: false,
                    sort: 41,
                    width: '120'
                },
                {
                    label: "包裹总体积",
                    prop: "ParcelBulkVolume",
                    disabled: false,
                    sort: 42,
                    width: '120'
                },
                {
                    label: "包裹数量",
                    prop: "ParcelCount",
                    disabled: false,
                    sort: 43,
                    width: '120'
                },
                {
                    label: "包裹编号",
                    prop: "ParcelIds",
                    disabled: false,
                    sort: 44,
                    width: '120'
                },
                {
                    label: "收货时间",
                    prop: "ReceiveGoodsTime",
                    disabled: false,
                    sort: 45,
                    width: '180'
                },
                {
                    label: "开始拣货时间",
                    prop: "PickingTime",
                    disabled: false,
                    sort: 46,
                    width: '180'
                },
                {
                    label: "拣货人",
                    prop: "PickingPersonnel",
                    disabled: false,
                    sort: 47,
                    width: '120'
                },
                {
                    label: "审核时间",
                    prop: "AuditTime",
                    disabled: false,
                    sort: 48,
                    width: '180'
                },
                {
                    label: "审核人",
                    prop: "AuditPersonnel",
                    disabled: false,
                    sort: 49,
                    width: '120'
                },
                {
                    label: "核重时间",
                    prop: "CheckTime",
                    disabled: false,
                    sort: 50,
                    width: '180'
                },
                {
                    label: "物流描述",
                    prop: "TrackContent",
                    disabled: false,
                    sort: 50,
                    width: '160'
                },
                {
                    label: "最新物流节点时间",
                    prop: "NewTasckNodeTime",
                    disabled: false,
                    sort: 51,
                    width: '180'
                },
                {
                    label: "最后物流获取时间",
                    prop: "LastGetTasckTime",
                    disabled: false,
                    sort: 52,
                    width: '180'
                },
                {
                    label: "金额备注",
                    prop: "AmountRem",
                    disabled: false,
                    sort: 53,
                    width: '120'
                },
                {
                    label: "变动金额",
                    prop: "VariableAmount",
                    disabled: false,
                    sort: 54,
                    width: '120'
                },
                {
                    label: "地址附加费",
                    prop: "AddressSurcharge",
                    disabled: false,
                    sort: 54,
                    width: '120'
                },
                {
                    label: "到付手续费",
                    prop: "ToPayAmount",
                    disabled: false,
                    sort: 55,
                    width: '120'
                },
                {
                    label: "是否到付",
                    prop: "IsToPay",
                    disabled: false,
                    sort: 56,
                    width: '120'
                },
                {
                    label: "折扣率",
                    prop: "Discount",
                    disabled: false,
                    sort: 57,
                    width: '120'
                },
                {
                    label: "增值服务费用",
                    prop: "AddedServiceFee",
                    disabled: false,
                    sort: 58,
                    width: '120'
                },
                {
                    label: "报关费",
                    prop: "BgFee",
                    disabled: false,
                    sort: 59,
                    width: '120'
                },
                {
                    label: "保险费",
                    prop: "BxFee",
                    disabled: false,
                    sort: 60,
                    width: '120'
                },
                {
                    label: "仓库租费",
                    prop: "StorageCharges",
                    disabled: false,
                    sort: 61,
                    width: '120'
                },
                {
                    label: "超重费",
                    prop: "WeightCharges",
                    disabled: false,
                    sort: 62,
                    width: '120'
                },
                {
                    label: "超长费",
                    prop: "LengthCharges",
                    disabled: false,
                    sort: 63,
                    width: '120'
                },
                {
                    label: "保价手续费",
                    prop: "InsuredCommission",
                    disabled: false,
                    sort: 64,
                    width: '140'
                },
                {
                    label: "保价金额",
                    prop: "InsuredAmount",
                    disabled: false,
                    sort: 65,
                    width: '120'
                },
                {
                    label: "包税手续费",
                    prop: "TaxCommissionInfo",
                    disabled: false,
                    sort: 66,
                    width: '140'
                },
                {
                    label: "包裹是否到齐",
                    prop: "IsComplete",
                    disabled: false,
                    sort: 67,
                    width: '160'
                },
                {
                    label: "是否报关",
                    prop: "IsCustomsClearance",
                    disabled: false,
                    sort: 68,
                    width: '120'
                },
                {
                    label: "打印时间",
                    prop: "PrintTime",
                    disabled: false,
                    sort: 69,
                    width: '180'
                },
                {
                    label: "打印状态",
                    prop: "PrintState",
                    disabled: false,
                    sort: 70,
                    width: '120'
                },
                {
                    label: "是否包税",
                    prop: "IsTaxName",
                    disabled: false,
                    sort: 71,
                    width: '140'
                },
                {
                    label: "代收货款",
                    prop: "CollectionAmount",
                    disabled: false,
                    sort: 72,
                    width: '120'
                },
                {
                    label: "代收货款手续费",
                    prop: "CollectionCommission",
                    disabled: false,
                    sort: 73,
                    width: '160'
                },
                {
                    label: "包裹附加费",
                    prop: "Surcharge",
                    disabled: false,
                    sort: 75,
                    width: '140'
                },
                {
                    label: "目的地费用",
                    prop: "DestinationCharges",
                    disabled: false,
                    sort: 76,
                    width: '140'
                },
                {
                    label: "核重状态",
                    prop: "CheckStatusName",
                    disabled: false,
                    sort: 77,
                    width: '120'
                },
                {
                    label: "备注1",
                    prop: "Rem1",
                    disabled: false,
                    sort: 78,
                    width: '200'
                },
                {
                    label: "备注2",
                    prop: "Rem2",
                    disabled: false,
                    sort: 78,
                    width: '200'
                },
                {
                    label: "备注3",
                    prop: "Rem3",
                    disabled: false,
                    sort: 78,
                    width: '200'
                },
                {
                    label: "备注4",
                    prop: "Rem4",
                    disabled: false,
                    sort: 78,
                    width: '200'
                }




            ],
            defaultColumns: [
                {
                    label: "订单号",
                    prop: "MainBillCode",
                    disabled: false,
                    sort: 1,
                    width: 180
                },
                {
                    label: "订单来源",
                    prop: "OrderSourceName",
                    disabled: false,
                    sort: 2,
                    width: '120'
                },
                {
                    label: "品名",
                    prop: "GoodsName",
                    disabled: false,
                    sort: 3,
                    width: '120'
                },
                {
                    label: "付款状态",
                    prop: "PayState",
                    disabled: false,
                    sort: 4,
                    width: '96',
                },
                {
                    label: "发货单号",
                    prop: "CheckCodes",
                    disabled: false,
                    sort: 5,
                    width: '120'
                },
                {
                    label: "承运单号",
                    prop: "CarrierBillCode",
                    disabled: false,
                    sort: 5,
                    width: '160'
                },
                {
                    label: "仓库",
                    prop: "WareHouseName",
                    disabled: false,
                    sort: 6,
                    width: '120'
                },
                {
                    label: "承运商",
                    prop: "CarrierName",
                    disabled: false,
                    sort: 7,
                    width: '120'
                },
                {
                    label: "时效",
                    prop: "TimerName",
                    disabled: false,
                    sort: 8,
                    width: '120'
                },
                {
                    label: "货物类型",
                    prop: "GoodsTypeName",
                    disabled: false,
                    sort: 9,
                    width: '120'
                },
                {
                    label: "重量",
                    prop: "Weight",
                    disabled: false,
                    sort: 10,
                    width: '120'
                },
                {
                    label: "创建时间",
                    prop: "CreateTime",
                    disabled: false,
                    sort: 11,
                    width: 180
                },
                {
                    label: "收件人",
                    prop: "ReceiveName",
                    disabled: false,
                    sort: 20,
                    width: '140'
                },
                {
                    label: "收件电话",
                    prop: "ReceivePhone",
                    disabled: false,
                    sort: 21,
                    width: '140'
                },
                {
                    label: "收件地址",
                    prop: "ReceiveAddress",
                    disabled: false,
                    sort: 22,
                    width: '180'
                },
                {
                    label: "物流状态",
                    prop: "TrackStateName",
                    disabled: false,
                    sort: 23,
                    width: '140'
                },
                {
                    label: "物流描述",
                    prop: "TrackContent",
                    disabled: false,
                    sort: 23,
                    width: '140'
                },
                {
                    label: "门店名称",
                    prop: "StoreName",
                    disabled: false,
                    sort: 24,
                    width: '140'
                },
                {
                    label: "门店号",
                    prop: "StoreNumber",
                    disabled: false,
                    sort: 25,
                    width: '140'
                },
            ],
            storageVersion: 0,
            tableKey: 0,
            columnKey: "consolidation",
            colDrawer: false,
            reload: false,
        }
    },
    computed: {
        tableColumns() {
            // eslint-disable-next-line no-unused-vars
            const version = this.storageVersion

            const storageColumns = localStorage.getItem(this.columnKey)
            let columns = this.defaultColumns
            if (storageColumns) {
                try {
                    columns = JSON.parse(storageColumns)
                } catch (e) {
                    console.error('Parse storage columns error:', e)
                }
            }
            return columns.map(col => {
                const newCol = { ...col }
                switch(col.prop) {
                    case 'PayState':
                        newCol.type = 'status'
                        break
                    case 'CarrierBillCode':
                        newCol.type = 'link'
                        break
                    case 'CheckCodes':
                        newCol.type = 'linkGroup'
                        break
                    case 'CreateTime':
                        newCol.type = 'datetime'
                        break
                    case 'CheckTime':
                        newCol.type = 'datetime'
                        break
                    case 'OrderExpiredTime':
                        newCol.type = 'datetime'
                        break
                    case 'PickingTime':
                        newCol.type = 'datetime'
                        break
                    case 'AuditTime':
                        newCol.type = 'datetime'
                        break
                    case 'NewTasckNodeTime':
                        newCol.type = 'datetime'
                        break
                    case 'LastGetTasckTime':
                        newCol.type = 'datetime'
                        break
                    case 'PrintTime':
                        newCol.type = 'datetime'
                        break
                    case 'PayTime':
                        newCol.type = 'datetime'
                        break
                    case 'ReceiveGoodsTime':
                        newCol.type = 'datetime'
                        break
                    case 'OrderStatu':
                        newCol.type = 'shopeeStatus'
                        break
                    case 'PrintState':
                        newCol.type = 'printState'
                        break
                    case 'MainBillCode':
                        newCol.type = 'inventoryStatus'
                        break
                    case 'IsToPay':
                        newCol.type = 'formatBool'
                        break
                    case 'IsCustomsClearance':
                        newCol.type = 'formatBool'
                        break
                    case 'IsComplete':
                        newCol.type = 'formatBool'
                        break
                }
                return newCol
            })
        }
    },
    methods: {
        handleColumnClose() {
            this.colDrawer = false
        },
        reloadTableHeight() {
            this.reload = true
            setTimeout(() => {
                this.reload = false
            }, 200)
        },
        handleColumnSave() {
            this.tableKey += 1;
            this.storageVersion += 1;

            // 等待 DOM 更新完成后再设置固定高度
            this.$nextTick(() => {
                this.reloadTableHeight()
            });

            this.$forceUpdate();
        },
    }
}
